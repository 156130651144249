import { filter, find } from 'es-toolkit/compat';
import { HomeStatus, UnitStatus, Milestone } from 'models/enums';
import { propertyStaticMapImageUrl } from 'utils/staticMapUrlGenerator';
import Address from '../common/Address';
import CoverageRegion from '../common/CoverageRegion';
import PropertyBasicInfo from '../common/PropertyBasicInfo';
import HomeownerAccountUnit from './HomeownerAccountUnit';

export default class HomeownerAccountProperty {
  constructor(data = {}) {
    this.basicInfo = new PropertyBasicInfo(data.basicInfo);
    this.address = new Address(data.address);
    this.units = data.units ? data.units.map((unit) => new HomeownerAccountUnit(unit)) : [];
    this.coverageRegion = data.coverageRegion ? new CoverageRegion(data.coverageRegion) : null;
    this.staticMapImageUrl = propertyStaticMapImageUrl({
      streetAddress: this.address.streetAddress,
      city: this.address.city,
      state: this.address.state,
    });
  }

  availableUnits() {
    return filter(this.units, (unit) => unit.residents?.length && unit.isPublished);
  }

  emptyAvailableUnits() {
    return filter(this.units, (unit) => !unit.residents?.length && unit.isPublished);
  }

  totalResidents() {
    const residents = this.availableUnits().map((unit) => unit.residents.length);
    return residents.reduce((a, b) => a + b, 0);
  }

  getFullAddressForUnit(unitId) {
    const unit = find(this.units, (u) => u.basicInfo?.unitId === unitId);
    // const { basicInfo: { unitNumber } } = unit;
    const getUnit = unit?.basicInfo?.unitNumber || '';
    return this.address.getFormattedAddress(getUnit);
  }

  getStreetAddress(unitNumber) {
    return this.address.getStreetAddress(unitNumber);
  }

  getUnitsByStatus(status, isPublished = false) {
    const filteredUnits = filter(this.units, (unit) => {
      const { inspectionScheduledOn } = unit;
      const { isOnboardingComplete, currentMilestone, isInspectionCompleted } = unit.basicInfo;

      let statusCondition;

      const isApplicationPending = !isOnboardingComplete;
      switch (status) {
        case UnitStatus.ApplicationPending:
          statusCondition = isApplicationPending;
          break;
        case UnitStatus.InspectionPending:
          statusCondition =
            isOnboardingComplete &&
            !isInspectionCompleted &&
            currentMilestone !== Milestone.InspectionScheduled &&
            !inspectionScheduledOn;

          break;
        case UnitStatus.InspectionScheduled:
          statusCondition =
            isOnboardingComplete &&
            !isInspectionCompleted &&
            (currentMilestone === Milestone.InspectionScheduled ||
              (currentMilestone !== Milestone.InspectionScheduled && inspectionScheduledOn));

          break;
        case UnitStatus.InspectionCompleted:
        default:
          statusCondition = isInspectionCompleted;
          break;
      }
      return statusCondition && unit.basicInfo.isPublished === isPublished;
    });

    return filteredUnits;
  }

  getPublishedUnits() {
    return filter(this.units, (unit) => unit.isPublished);
  }

  getUnitsWithAgreements() {
    return filter(this.units, (unit) => unit.homeStatus !== HomeStatus.Potential);
  }

  getPrimaryOwnerUnits() {
    return filter(this.units, (unit) => unit.isPrimaryOwner);
  }

  getUnpublishedUnitsWithAgreements() {
    return filter(this.units, (unit) => !unit.isPublished && unit.homeStatus !== HomeStatus.Potential);
  }
}
