import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { MILESTONE_MAP, NEXT_REQUIRED_EVENTS_BLACK_LIST, TAB_ITEMS } from 'accounts/constants/my-home-timeline';
import { useTimeline } from 'accounts/hooks/homeowners/use-timeline';
import classNames from 'classnames/bind';
import AccountTimeline from 'components/AccountTimeline/AccountTimeline';
import { Loading } from 'components/Loading/Loading';
import TabBar from 'components/TabBar/TabBar';
import ResidentListContainer from 'containers/ResidentListContainer/ResidentListContainer';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import { flatten, isNil } from 'es-toolkit';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
// import CurrentLease from 'pages/Accounts/Pages/YourPortfolio/Properties/CurrentLease/CurrentLease';
import DisplayEvent from 'pages/Accounts/Pages/YourPortfolio/Properties/DisplayEvent/DisplayEvent';
import NextLease from 'pages/Accounts/Pages/YourPortfolio/Properties/NextLease/NextLease';
import NextRequiredEvents from 'pages/Accounts/Pages/YourPortfolio/Properties/NextRequiredEvents/NextRequiredEvents';
import { selectRawTimelines } from 'store/redux/homeowner-accounts/selectors';
import styles from './my-home-timeline.module.css';

const cx = classNames.bind(styles);

export function MyHomeTimeline() {
  const { selectedUnit: unit, loading } = useTimeline();
  const timelines = useSelector(selectRawTimelines);

  const [selectedTab, setSelectedTab] = useState(TAB_ITEMS[0]);

  const { currentLease, nextLease } = unit || {};
  const { residents = [], kids = [], pets = [] } = currentLease || nextLease || {};
  const allResidents = {
    residents,
    kids,
    pets,
  };
  const allResidentsLength = [...residents, ...kids, ...pets].length;

  const timeline = timelines?.find((t) =>
    t.milestones.some((milestone) => milestone.homeId === unit?.basicInfo.unitId)
  );

  // We need the index of the first index that is not internal to show the pulse animation.
  const firstNonInternalIndex = timeline?.timelineEvents.findIndex((event) => event.name);
  const timelineItems = [
    ...flatten(
      timeline?.timelineEvents.map((event, index) =>
        DisplayEvent(event, index === firstNonInternalIndex, unit?.basicInfo?.timeZone)
      )
    ),
  ].filter((item) => !isNil(item));

  const nextRequiredEventsArray = Object.values(timeline?.requiredEventsForNextMilestone || {}).filter(
    (event: any) => !NEXT_REQUIRED_EVENTS_BLACK_LIST[event.timelineEventType]
  );

  if (loading && !timeline) {
    return (
      <div className="flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={cx('flex mb-xs overflow-x-scroll', 'tabs')}>
        <TabBar
          name="unitTabBar"
          // Show only first item if no lease.
          items={currentLease || nextLease ? TAB_ITEMS : [TAB_ITEMS[0]]}
          onSelect={setSelectedTab}
          selected={selectedTab.key}
        />
      </div>
      {selectedTab.key === 'timeline' && (
        <Fragment>
          {/* If there are no events in nextRequiredEventsArray, show next milestone */}
          {(nextRequiredEventsArray.length > 0 || MILESTONE_MAP[timeline?.nextMilestone]) && (
            <>
              <Text fontWeight="semibold" color="dark-gray">
                What’s next?
              </Text>
              <Space value={SPACE_TYPES.XS} />
              <NextRequiredEvents
                nextRequiredEvents={
                  nextRequiredEventsArray.length > 0
                    ? nextRequiredEventsArray
                    : [{ name: MILESTONE_MAP[timeline?.nextMilestone] }]
                }
              />
              <Space value={SPACE_TYPES.SM} />
            </>
          )}
          <AccountTimeline className="pt-xs" items={timelineItems} includesEndTail={false} />
        </Fragment>
      )}
      {selectedTab.key === 'residents' && (
        <Fragment>
          {/*
            We are hidding this section from consumer.
            This will be displayed again once Deposit Free Fee and Renter Insurance costs are corrected.
          */}
          {/* <CurrentLease currentLease={currentLease} /> */}
          <NextLease nextLease={nextLease} />
          {allResidentsLength > 0 && (
            <AccountPageSection title="Belong Members" content={<ResidentListContainer residents={allResidents} />} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
