import React, { Component } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { isEqual } from 'es-toolkit';
import { find } from 'es-toolkit/compat';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';
import PropTypes from 'prop-types';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { required } from 'utils/validation';

const { FormLayoutHeader } = FormLayout;
const HOB = HOMEOWNER_ONBOARDING_STRINGS.HomeownerMaintenancePreferences;
const options = [
  {
    label: 'Definitely',
    enum: 'Definitely',
  },
  {
    label: 'Sometimes',
    enum: 'Sometimes',
  },
  {
    label: 'No Way',
    enum: 'NoWay',
  },
];

class MaintenancePreferences extends Component {
  static propTypes = {
    currentStepData: PropTypes.object.isRequired,
    onSaveAndNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  onSubmit = async (values) => {
    const { onSaveAndNext } = this.props;

    const data = {
      maintenancePrefences: {
        maintenancePreferences: values.maintenanceDone.map((value) => ({
          maintenanceType: value.maintenanceType,
          preference: value.preference,
        })),
      },
    };

    onSaveAndNext(data);
  };

  getInitialValues() {
    const { currentStepData } = this.props;

    const { maintenancePrefences: { maintenancePreferences } = {} } = currentStepData;

    return {
      maintenanceDone: HOB.items.map((item) => {
        const maintenancePreference = find(maintenancePreferences, {
          maintenanceType: item.maintenanceType,
        });
        return {
          maintenanceType: item.maintenanceType,
          preference: maintenancePreference ? maintenancePreference.preference : undefined,
        };
      }),
    };
  }

  render() {
    const { onBack } = this.props;

    return (
      <>
        <FormLayout>
          <FormLayoutHeader title={HOB.title} pretitle={HOB.subtitle} />
          <Form
            initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
            mutators={{
              ...arrayMutators,
            }}
            getFormBottomBar={(formProps, nextButtonProps) => (
              <FormLayout.BottomBar
                ctaProps={{
                  label: 'Next',
                  type: 'submit',
                }}
                nextButtonWrapperProps={nextButtonProps}
                previousStepProps={{
                  onClick: onBack,
                  to: null,
                }}
              />
            )}
            initialValues={this.getInitialValues()}
            onSubmit={this.onSubmit}
            getForm={() => (
              <FieldArray name="maintenanceDone">
                {({ fields }) =>
                  fields.map((info, index) => [
                    <FormLayout.Section firstSection={index === 0} sectionTitle={HOB.items[index].title} key={info}>
                      <Field
                        name={`${info}.preference`}
                        component={SelectorFinalFormAdapter}
                        validate={required}
                        buttons={[
                          {
                            label: options[0].label,
                            key: options[0].enum,
                          },
                          {
                            label: options[1].label,
                            key: options[1].enum,
                          },
                          {
                            label: options[2].label,
                            key: options[2].enum,
                          },
                        ]}
                        type={SELECTOR_TYPES.SMALLTEXTBUTTON}
                        fluid
                      />
                    </FormLayout.Section>,
                  ])
                }
              </FieldArray>
            )}
          />
        </FormLayout>
      </>
    );
  }
}

export default MaintenancePreferences;
