import { BASE_PATHS, PATHS } from '@belong/common';
import { Link } from '@belong/ui';
import { AvailabilityWithUtc, TourRequestModel, UnitListingDetailsModel } from 'api/models';
import Label from 'components/Label/Label';
import { format, getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { groupBy } from 'es-toolkit';
import Address from 'models/common/Address';
import Unit from 'models/common/Unit';
import { TourCard, TourCardActions, TourCardTitleWithDetails } from './TourCard';

const transform24HourTo12Hour = (hour: number, includeAmPm = false) => {
  const suffix = includeAmPm ? (hour >= 12 ? 'PM' : 'AM') : '';

  if (hour > 12) {
    return `${hour - 12}${suffix}`;
  }
  return `${hour}${suffix}`;
};

const AvailabilityDatesTooltip = ({
  availability,
  timeZone,
  homeUniqueId,
}: {
  availability: TourRequestModel['availability'];
  timeZone: string;
  homeUniqueId: string;
}) => {
  const timeSlotsByDate = Object.values(
    groupBy(
      // sort by asc date
      availability?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) ?? [],
      (a) => a.date
    )
  ).map((slots) => slots.sort((a, b) => new Date(a.startOn).getTime() - new Date(b.startOn).getTime()));

  const availabilityDates = Object.keys(timeSlotsByDate);

  const timeSlotsList = availabilityDates.map((availabilityDate) => {
    const dateString = format(utcToZonedTime(timeSlotsByDate[availabilityDate][0].startOn, timeZone), 'MMM d, yyyy');

    const hourSlots = timeSlotsByDate[availabilityDate]
      // join adjacent time slots to prevent rendering too many items
      // e.g. instead of rendering 9AM-10AM, 10AM-11AM, 11AM-12PM render 9AM-12PM
      .reduce((slots, timeSlot) => {
        if (slots.length === 0) {
          return [timeSlot];
        }

        const currentSlotStartHour = getHours(utcToZonedTime(timeSlot.startOn, timeZone));
        const lastSlotEndHour = getHours(utcToZonedTime(slots.at(-1).endOn, timeZone));

        // if slots are adjacent join them
        if (lastSlotEndHour === currentSlotStartHour) {
          const slotsWithoutLast = slots.slice(0, -1);
          return [
            ...slotsWithoutLast,
            {
              ...slots.at(-1),
              endOn: timeSlot.endOn,
            },
          ];
        }

        return [...slots, timeSlot];
      }, [] as AvailabilityWithUtc[])
      .map((timeSlot: AvailabilityWithUtc) => {
        const startHour = getHours(utcToZonedTime(timeSlot.startOn, timeZone));
        const endHour = getHours(utcToZonedTime(timeSlot.endOn, timeZone));

        return `${transform24HourTo12Hour(
          startHour,
          // we only show the am/pm suffix for the first hour of the range if
          // first hour is AM and second one is PM
          startHour < 12 && endHour >= 12
        )}-${transform24HourTo12Hour(endHour, true)}`;
      })
      .join(', ');

    return <span key={availabilityDate}>{`${dateString} : ${hourSlots}`}</span>;
  });

  return (
    <div
      className="flex flex-col gap-xs items-start bg-white px-sm py-2sm rounded border border-solid border-gray"
      style={{ maxWidth: 380 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {timeSlotsList}
      <Link variant="text" href={`${BASE_PATHS.HOME}/${homeUniqueId}?view=tours`} className="self-end">
        Change times
      </Link>
    </div>
  );
};

export const TourRequestCard = ({
  tourRequest,
}: {
  tourRequest: TourRequestModel & { homeDetails: UnitListingDetailsModel };
}) => {
  const homeAddress = new Address(tourRequest.homeDetails.address);
  const homeTimeZone = tourRequest.homeDetails.basicInfo.timeZone;
  const unit = new Unit(tourRequest.homeDetails);

  const { isDemoUnit, isAvailableForRent } = tourRequest.homeDetails.listingInfo || {};
  const { isPublished } = tourRequest.homeDetails.basicInfo;
  const isApplyDisabled = isDemoUnit || !isAvailableForRent || !isPublished;

  return (
    <TourCard
      homeUniqueId={tourRequest.homeUniqueId}
      imageUrl={tourRequest.homeDetails.basicInfo.bannerImageUrl}
      topContent={
        <>
          <div className="flex gap-xs mb-xs">
            <Label text="Requested Tour" />
          </div>
          <TourCardTitleWithDetails
            title={homeAddress.getAddressAndUnitNumber(unit.basicInfo.unitNumber)}
            basicInfo={unit.basicInfo}
            rentAmount={tourRequest.homeDetails.listingInfo.rentAmount}
          />
        </>
      }
      bottomContent={
        <TourCardActions
          actions={[
            {
              label: 'Requested tour times',
              tooltipContent: (
                <AvailabilityDatesTooltip
                  availability={tourRequest.availability}
                  timeZone={homeTimeZone}
                  homeUniqueId={tourRequest.homeUniqueId}
                />
              ),
            },
            ...(isApplyDisabled
              ? []
              : [
                  {
                    label: 'Apply',
                    href: PATHS.RESIDENT_APPLICATION_GET_STARTED.concat(`?homeId=${tourRequest.homeUniqueId || ''}`),
                  },
                ]),
          ]}
        />
      }
    />
  );
};
