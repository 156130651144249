import React from 'react';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { NewMediaUploadAdapter, MEDIA_LABEL } from 'components/NewMediaUploader/NewMediaUploader';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import String from 'components/String/String';
import { urgency } from 'containercomponents/Modals/ModalListOfItems/ApplianceList/listOfFormObjects';
import { get } from 'es-toolkit/compat';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { MissingKeyTypes } from 'models/enums/index';
import PropTypes from 'prop-types';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { required } from 'utils/validation';

const KeysForm = ({ form }) => (
  <>
    <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.keys_what_to_do}>
      <Field
        name="requestTypeDetails.reason"
        component={SelectorFinalFormAdapter}
        onChangeCustom={() => {
          let valuesToChange = get(form.getState().values, 'requestTypeDetails.reason');
          if (valuesToChange === MissingKeyTypes.MissingKey) {
            valuesToChange = 'Missing key';
          } else if (valuesToChange === MissingKeyTypes.Rekey) {
            valuesToChange = 'Rekeying';
          } else if (valuesToChange === MissingKeyTypes.LockedOut) {
            valuesToChange = 'Lockout';
          } else {
            valuesToChange = 'Keys';
          }

          form.change('summary', valuesToChange);
        }}
        buttons={[
          {
            key: MissingKeyTypes.MissingKey,
            label: 'Missing key',
          },
          // {
          //   key: MissingKeyTypes.Rekey,
          //   label: 'Rekey my home',
          // },
          {
            key: MissingKeyTypes.LockedOut,
            label: "I'm locked out!",
          },
          {
            key: MissingKeyTypes.Other,
            label: 'Something else',
          },
        ]}
        type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
        validate={required}
      />
    </FormLayout.Section>

    <Condition when="requestTypeDetails.reason" hasValue>
      <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.key_description}>
        <Field
          name="description"
          component={InputFinalFormAdapter}
          placeholder={
            get(form.getState().values, 'requestTypeDetails.reason') === 'Other'
              ? ADD_TASK_MODAL_STRINGS.key_description_placeholder_required
              : ADD_TASK_MODAL_STRINGS.key_description_placeholder
          }
          validate={get(form.getState().values, 'requestTypeDetails.reason') === 'Other' ? required : () => {}}
          key={get(form.getState().values, 'requestTypeDetails.reason') === 'Other' ? `description0` : `description1`}
          textarea
        />
      </FormLayout.Section>

      <FormLayout.Section
        sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}
        sectionSubTitle={<String string={ADD_TASK_MODAL_STRINGS['upload.subtitle.recurring']} />}
      >
        <Field
          name="attachments"
          component={NewMediaUploadAdapter}
          mediaLabel={MEDIA_LABEL.PHOTO_OR_VIDEO}
          wrapperSize="large"
        />
      </FormLayout.Section>

      <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS.urgency}>
        <Field
          name="urgency"
          component={SelectorFinalFormAdapter}
          placeholder={ADD_TASK_MODAL_STRINGS.urgency_placeholder}
          buttons={urgency}
          fluid
          validate={required}
          type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
        />
      </FormLayout.Section>
    </Condition>
  </>
);

KeysForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default KeysForm;
