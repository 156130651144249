import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { AutoCompleteFinalFormAdapter } from 'components/AutoComplete/AutoComplete';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import { SelectorFinalFormAdapter } from 'components/Selector/Selector';
import String from 'components/String/String';
import MaintenanceModalAttachmentFields from 'containercomponents/Modals/AddTaskModal/MaintenanceModalAttachmentFields';
import { filter } from 'es-toolkit/compat';
import arrayMutators from 'final-form-arrays';
import Condition from 'formcomponents/Condition/Condition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import { RequestType } from 'models/enums/index';
import ResidentAvailabilityForm from 'pages/Accounts/FormLayouts/ResidentAvailabilityForm/ResidentAvailabilityForm';
import PropTypes from 'prop-types';
import { updateMaintenanceTask, createMaintenanceTask } from 'store/redux/maintenance/actions';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { required } from 'utils/validation';
import { PRO_SERVICE_TASKS } from '../AddTaskModal/AddTaskModal';
import { listOfAppliances } from '../ModalListOfItems/ApplianceList/listOfFormObjects';
import styles from './RenterRequestUpgradeModal.module.css';

const cx = classNames.bind(styles);

class RenterRequestUpgradeModal extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateMaintenanceTask: PropTypes.func.isRequired,
    createMaintenanceTask: PropTypes.func.isRequired,
    task: PropTypes.object,
    onEdit: PropTypes.bool,
  };

  static defaultProps = {
    task: {},
    onEdit: false,
  };

  state = {
    loading: false,
  };

  componentWillUnmount() {
    // need to unsubscribe states
  }

  getInitialValues() {
    const { task, onEdit } = this.props;

    const { media } = task;
    const filteredMedia = filter(media, (m) => m.stage === 'Before' && !m.subOwner);

    this.initValue = {
      ...task,
      attachments: {
        availableMedia: filteredMedia,
        mediaToDisplay: filteredMedia,
        deletedMedia: [],
        later: onEdit ? !filteredMedia?.length : false,
      },
      requestorAvailability: task.requestorAvailability || [],
      requestorAvailabilityxx: task.requestorAvailability || [],
      doesResidentClaimResponsibility: task?.isResidentFault?.toString() || null,
    };
    return this.initValue;
  }

  handleRegisterSubmit = async (values) => {
    const { task, onEdit, unit, closeModal } = this.props;

    const optionalObj = {};

    if (unit?.unitId) {
      optionalObj.homeUniqueId = unit.unitId;
    }

    if (onEdit) {
      optionalObj.auditInfo = {
        dataVersion: task.dataVersion,
      };
    }

    const getOnlyValidDates = values.requestorAvailability.filter((date) => {
      if (date.times?.length) {
        return true;
      }
      return false;
    });

    const validDates = getOnlyValidDates.length ? getOnlyValidDates : null;
    const sendObjectToBackend = {
      ...values,
      ...optionalObj,
      requestorAvailability: values?.isRequestorPresent ? validDates : null,
    };

    this.setState({ loading: true });

    try {
      if (onEdit) {
        await this.props.updateMaintenanceTask(sendObjectToBackend, task.uniqueId);
      } else {
        await this.props.createMaintenanceTask(sendObjectToBackend);
      }
    } catch (err) {
      console.error(err);
    }

    this.setState({ loading: false });
    try {
      await closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { show, closeModal } = this.props;
    const { loading } = this.state;

    return (
      <ModalV2
        show={show}
        onHide={closeModal}
        showSpinner={loading}
        type={MODAL_TYPES.MAINTENANCE_MODAL}
        ignoreModalExitAndEnterFunctions
        noPadding
        backdrop="static"
      >
        <div className={cx('maintenanceStatusModal')}>
          <ModalLayout fixedBottom>
            <ModalLayout.Title>
              <String string={ADD_TASK_MODAL_STRINGS.title} />
            </ModalLayout.Title>
            <ModalLayout.Content>
              <ModalLayout.Section>
                <Form
                  onSubmit={this.handleRegisterSubmit}
                  initialValues={this.getInitialValues()}
                  mutators={{ ...arrayMutators }}
                  getFormBottomBar={(formProps, nextButtonProps) => {
                    return (
                      <ModalLayout.BottomBar
                        ctaProps={{
                          label: 'Submit',
                        }}
                        nextButtonWrapperProps={nextButtonProps}
                        fixedBottom
                      />
                    );
                  }}
                  errorProps={{
                    errorClassNames: cx('transform-error-position'),
                    inline: false,
                  }}
                  bottomPosition="25px"
                  getForm={({ form, values }) => {
                    return (
                      <FormLayout>
                        <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS['renter_upgrade.title']}>
                          <Field
                            name="requestType"
                            component={SelectorFinalFormAdapter}
                            placeholder="Task Type"
                            validate={required}
                            buttons={[
                              { ...PRO_SERVICE_TASKS.ADDITION, label: RequestType.Addition },
                              { ...PRO_SERVICE_TASKS.UPGRADE, label: RequestType.Upgrade },
                            ]}
                          />
                        </FormLayout.Section>
                        <Condition when="requestType" hasValue>
                          <FormLayout.Section
                            sectionTitle={
                              values.requestType === RequestType.Addition
                                ? ADD_TASK_MODAL_STRINGS['renter_addition_modal.title']
                                : ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.title']
                            }
                          >
                            <Field
                              name="summary"
                              allowUserInput
                              hideAutoCompleteIconOnMobile
                              component={AutoCompleteFinalFormAdapter}
                              items={listOfAppliances}
                              validate={required}
                              placeholder={ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.appliance_placeholder']}
                            />
                          </FormLayout.Section>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.improvement']}>
                            <Field
                              name="description"
                              component={InputFinalFormAdapter}
                              placeholder={ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.describe_issue_placeholder']}
                              validate={required}
                              textarea
                            />
                          </FormLayout.Section>
                          <FormLayout.Section sectionTitle={ADD_TASK_MODAL_STRINGS['upload.title']}>
                            <MaintenanceModalAttachmentFields form={form} />
                          </FormLayout.Section>

                          <ResidentAvailabilityForm isRequestorPresent={values.isRequestorPresent} />
                        </Condition>
                      </FormLayout>
                    );
                  }}
                />
              </ModalLayout.Section>
            </ModalLayout.Content>
          </ModalLayout>
        </div>
      </ModalV2>
    );
  }
}

const mapDispatchToProps = {
  updateMaintenanceTask,
  createMaintenanceTask,
};

export default connect(null, mapDispatchToProps)(RenterRequestUpgradeModal);
