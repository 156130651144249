import { Component } from 'react';
import { DATE_FORMATS, formatDateTime } from '@belong/common';
import Form from 'components/Form/Form';
import { isEqual } from 'es-toolkit';
import arrayMutators from 'final-form-arrays';
import RentalStatusInfo from 'formcomponents/RentalStatusInfo/RentalStatusInfo';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { AvailableForRentOptions, MoveOutOptions, RentalStatus, NextHomePlans } from 'models/enums';
import PropertyRentalLabel from 'pages/HomeOwnerOnboarding/Steps/components/PropertyRentalLabel/PropertyRentalLabel';
import PropTypes from 'prop-types';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { parseMMYYDateToFirstDayOfMonth, parseDateTimeInputStringMMDDYY } from 'utils/dateTimeUtils';

const HOB = HOMEOWNER_ONBOARDING_STRINGS.UnitOccupancy;

const { FormLayoutHeader } = FormLayout;

class IsUnitCurrentlyRented extends Component {
  static propTypes = {
    groups: PropTypes.object.isRequired,
    currentStepData: PropTypes.object.isRequired,
    currentStepResources: PropTypes.object.isRequired,
    onSaveAndNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
  };

  onSubmit = async (values) => {
    const { onSaveAndNext } = this.props;
    const currentDate = formatDateTime({ dateTime: new Date(), format: DATE_FORMATS.STRIPPED_ISO });

    const { unitOccupancy } = values;

    let availableForRentOn = null;
    let availableForRentOption = null;

    if (unitOccupancy.availableToRent) {
      availableForRentOn = currentDate;
      availableForRentOption = AvailableForRentOptions.OnSpecificDate;
    } else {
      if (unitOccupancy.availableForRentOption === AvailableForRentOptions.InCertainMonth) {
        availableForRentOn = parseMMYYDateToFirstDayOfMonth(unitOccupancy.availableInAMonth);
      }

      if (unitOccupancy.availableForRentOption === AvailableForRentOptions.OnSpecificDate) {
        availableForRentOn = parseDateTimeInputStringMMDDYY(unitOccupancy.availableForRentOn);
      }

      availableForRentOption = unitOccupancy.availableForRentOption; // eslint-disable-line prefer-destructuring
    }

    const data = {
      unitOccupancy: {
        ...unitOccupancy,
        currentLeaseEndOn: unitOccupancy.currentLeaseEndOn?.inputValue
          ? parseDateTimeInputStringMMDDYY(unitOccupancy.currentLeaseEndOn?.inputValue)
          : '',
        isLeaseMonthToMonth: unitOccupancy.currentLeaseEndOn?.checkboxValue,
        previousLeaseEndOn: parseDateTimeInputStringMMDDYY(unitOccupancy?.previousLeaseEndOn),
        moveinOn: parseDateTimeInputStringMMDDYY(unitOccupancy.moveinOn),
        moveoutOn: parseDateTimeInputStringMMDDYY(unitOccupancy.moveoutOn),
        moveoutStartOn: parseDateTimeInputStringMMDDYY(unitOccupancy.moveoutStartOn),
        moveoutEndOn: parseDateTimeInputStringMMDDYY(unitOccupancy.moveoutEndOn),
        moveoutPlan: unitOccupancy.moveoutPlan?.checkboxValue
          ? NextHomePlans.NotSure
          : unitOccupancy.moveoutPlan?.inputValue,
        moveoutDestination: unitOccupancy.moveoutDestination?.inputValue,
        moveoutDestinationNotSure: unitOccupancy.moveoutDestination?.checkboxValue,
        availableForRentOn,
        availableForRentOption,
      },
    };

    onSaveAndNext(data);
  };

  getInitialValues() {
    const { currentStepData, currentStepResources } = this.props;

    if (!currentStepData || !currentStepResources) {
      return null;
    }

    const { unitOccupancy = {} } = currentStepData;
    const ifDateWasInPast = new Date(unitOccupancy.availableForRentOn) < new Date();

    let moveoutPlan = null;
    let moveoutDestination = null;

    if (unitOccupancy.rentalStatus === RentalStatus.OwnerOccupied) {
      if (unitOccupancy.moveoutPlan === NextHomePlans.NotSure) {
        moveoutPlan = {
          checkboxValue: true,
        };
      } else {
        moveoutPlan = {
          inputValue: unitOccupancy.moveoutPlan,
        };
      }

      if (unitOccupancy.moveoutDestinationNotSure) {
        moveoutDestination = {
          checkboxValue: true,
        };
      } else {
        moveoutDestination = {
          inputValue: unitOccupancy.moveoutDestination,
        };
      }
    }

    let moveOutInAMonth = null;

    if ([RentalStatus.FamilyOccupied, RentalStatus.OwnerOccupied].includes(unitOccupancy.rentalStatus)) {
      if (unitOccupancy.moveoutOption === MoveOutOptions.InDateRange) {
        moveOutInAMonth = formatDateTime({ dateTime: unitOccupancy.moveoutStartOn, format: DATE_FORMATS.SHORT });
      }
    }

    let availableForRentOn = null;
    let availableInAMonth = null;

    if (unitOccupancy.rentalStatus === RentalStatus.Vacant) {
      if (unitOccupancy.availableForRentOption === AvailableForRentOptions.InCertainMonth) {
        availableInAMonth = formatDateTime({ dateTime: unitOccupancy.availableForRentOn, format: DATE_FORMATS.SHORT });
      }

      if (unitOccupancy.availableForRentOption === AvailableForRentOptions.OnSpecificDate) {
        availableForRentOn = unitOccupancy.availableForRentOn; // eslint-disable-line prefer-destructuring
      }
    }

    return {
      unitOccupancy: {
        ...unitOccupancy,
        currentLeaseEndOn: {
          inputValue: unitOccupancy.currentLeaseEndOn,
          checkboxValue: unitOccupancy.isLeaseMonthToMonth,
        },
        moveoutPlan,
        moveoutDestination,
        moveOutInAMonth,
        availableForRentOn,
        availableInAMonth,
        rentedBefore: !!unitOccupancy.previousRentAmount,
        availableToRent: !!(unitOccupancy.rentalStatus === RentalStatus.Vacant && ifDateWasInPast),
      },
      streetAddress: currentStepResources?.property?.address?.streetAddress,
    };
  }

  render() {
    const { groups, currentStepResources, onBack } = this.props;

    if (!groups || !currentStepResources || !onBack) {
      return null;
    }

    const { homes } = groups;
    const { property, home } = currentStepResources;

    return (
      <FormLayout>
        {homes?.length > 1 && <PropertyRentalLabel property={property} home={home} />}
        <FormLayoutHeader pretitle={HOB.subtitle} title={HOB.title} />
        <Form
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: 'Next',
                type: 'submit',
              }}
              nextButtonWrapperProps={nextButtonProps}
              previousStepProps={{
                onClick: onBack,
                to: null,
              }}
            />
          )}
          mutators={{
            ...arrayMutators,
          }}
          initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
          initialValues={this.getInitialValues()}
          onSubmit={this.onSubmit}
          getForm={({ form }) => <RentalStatusInfo first name="unitOccupancy" form={form} />}
        />
      </FormLayout>
    );
  }
}

export default IsUnitCurrentlyRented;
