/* eslint-disable react/prop-types */
import { MediaGallery } from '@belong/ui';
import Collapsible from 'components/Collapsible/Collapsible';
import UserActionMaintenance from 'containercomponents/MaintenanceStatus/UserActionMaintenance/UserActionMaintenance';
import Space from 'corecomponents/Space/Space';
import { filter } from 'es-toolkit/compat';
import { getReportedByUser } from 'utils/findUserBasedOnParams';
import { fixUserTypeEnums } from 'utils/formatName';

export function ModalMaintenanceSummary({ hideDetails = false, task, collapsed = false }) {
  const user = getReportedByUser(task);
  const { media } = task;

  const taskImageUrls = filter(media, (m) => m.stage === 'Before' && !m.subOwner);
  const areAllBeforeImages = media.every((m) => m.stage === 'Before');

  return (
    <Collapsible
      collapsed={collapsed}
      disabled
      title="Summary"
      inner={
        <div className="pb-sm">
          <UserActionMaintenance
            userId={user?.userId}
            leftPadding
            requestHauling={{
              haulType: task.requestTypeDetails?.type,
              items: task.requestTypeDetails?.items,
            }}
            time={task.createdOn}
            name={`${user?.firstName} ${user?.lastName}`}
            description={!hideDetails && task.description}
            withSpace={!hideDetails}
            reportedBy={fixUserTypeEnums(task.requestedBy)}
            profileImage={user?.profileImageUrl}
          />
          {!!taskImageUrls.length && !hideDetails && areAllBeforeImages && (
            <>
              <Space />
              <MediaGallery media={taskImageUrls} />
            </>
          )}
        </div>
      }
    />
  );
}
