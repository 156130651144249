import { useState } from 'react';
import { PET_TYPES, CAT_BREEDS, DOG_BREEDS, OTHER_SPECIES } from 'accounts/constants/home-pets';
import { HOME_PETS_STRINGS } from 'accounts/constants/strings/home-pets.strings';
import { uniqBy } from 'es-toolkit/compat';

export function useHomePets({ form, initialValues }) {
  // used to get the initial unique breeds to display and set the breed tags to show.
  // This is different than seletectedBreeds which is using a
  // controlled component that only accepts strings in downshift.
  const startingRestrictedBreedsArray = initialValues.restrictedBreeds?.map((value) => ({ value, key: value })) || [];

  const AllCats = structuredClone(CAT_BREEDS);
  const AllDogs = structuredClone(DOG_BREEDS);
  const OtherSpecies = structuredClone(OTHER_SPECIES);
  const allPetsToUnique = [...AllDogs, ...AllCats, ...OtherSpecies];
  const allPetsUnique = uniqBy(allPetsToUnique, (pet) => pet.value);
  let initialPetTypeStrings = HOME_PETS_STRINGS.petTypes.ALL;
  let uniqueBreeds = uniqBy(
    [...startingRestrictedBreedsArray, ...DOG_BREEDS, ...CAT_BREEDS, ...OTHER_SPECIES],
    (breed) => breed.value
  );

  if (initialValues?.petsAllowed === PET_TYPES.CATS) {
    initialPetTypeStrings = HOME_PETS_STRINGS.petTypes.CATS;
    uniqueBreeds = uniqBy([...startingRestrictedBreedsArray, ...CAT_BREEDS], (breed) => breed.value);
  }

  if (initialValues?.petsAllowed === PET_TYPES.DOGS) {
    initialPetTypeStrings = HOME_PETS_STRINGS.petTypes.DOGS;
    uniqueBreeds = uniqBy([...startingRestrictedBreedsArray, ...DOG_BREEDS], (breed) => breed.value);
  }

  const [breedOptions, setBreed] = useState(uniqueBreeds);
  const [hideGreenInfoBox, setHideGreenInfoBox] = useState(false);
  const [hideAnimationGreenInfoBox, setHideAnimateGreenInfoBox] = useState(false);
  const [petTypeStrings, setPetTypeStrings] = useState(initialPetTypeStrings);
  const [selectedBreeds, setSelectedBreeds] = useState(initialValues.restrictedBreeds || []);
  const [breedTagsToShow, setBreedTagsToShow] = useState(startingRestrictedBreedsArray);
  const [inputValue, setInputValue] = useState('');

  // TODO: refactor this into MultiSelectComponent
  function handleChangeInput(inputVal) {
    const t = inputVal.split(',');

    if (JSON.stringify(t) !== JSON.stringify(selectedBreeds)) {
      setInputValue(inputVal);
    }
  }

  // TODO: refactor this into MultiSelectComponent
  function handleRemoveTag(item, input: any = {}) {
    let itemToRemove;
    const removeItems = breedTagsToShow.filter((i) => {
      if (i.value === item) {
        itemToRemove = i;
        return false;
      }

      return item;
    });

    const newSelectedItem = selectedBreeds.filter((i) => item !== i);

    // This is to sort adding back items to the array. Otherwise they are put to the bottom of the array.
    const alphabetizeArrayToSet = uniqBy([...breedOptions, itemToRemove], 'value').sort((a, b) => {
      if (a.value > b.value) {
        return 1;
      }

      if (a.value < b.value) {
        return -1;
      }

      return 0;
    });

    setBreed(alphabetizeArrayToSet);
    setBreedTagsToShow(removeItems);
    setSelectedBreeds(newSelectedItem);
    setInputValue('');

    if (input.onChange) {
      input.onChange(newSelectedItem);
    }
  }

  // TODO: refactor this into MultiSelectComponent
  function addSelectedItem(item, input: any = {}) {
    let itemToAdd;
    const newItems = breedOptions.filter((i) => {
      if (i.value === item) {
        itemToAdd = i;
        return false;
      }

      return i;
    });

    setSelectedBreeds([...selectedBreeds, itemToAdd.value]);
    setBreedTagsToShow([...breedTagsToShow, itemToAdd]);
    setBreed(newItems);
    setInputValue('');

    if (input.onChange) {
      input.onChange([...selectedBreeds, itemToAdd.value]);
    }
  }

  // TODO: refactor this into MultiSelectComponent
  const handleChange = (selectedItem, input) => {
    if (selectedBreeds.includes(selectedItem)) {
      handleRemoveTag(selectedItem, input);
    } else {
      addSelectedItem(selectedItem, input);
    }
  };

  function closeGreenBoxMenu() {
    setHideAnimateGreenInfoBox(true);
  }

  function changeFormValue(value) {
    const { restrictedBreeds, hasWeightRestriction, hasRestrictedBreeds } = value;

    form.batch(() => {
      form.change(hasWeightRestriction, false);
      form.change(hasRestrictedBreeds, false);
      form.change(restrictedBreeds, []);
    });
  }

  function handleChangePetsAllowed(e: any) {
    if (e === PET_TYPES.ALL) {
      setBreedTagsToShow([]);
      setSelectedBreeds([]);
      setBreed(allPetsUnique);
      setPetTypeStrings(HOME_PETS_STRINGS.petTypes.ALL);
      changeFormValue({
        restrictedBreeds: 'restrictedBreeds',
        hasWeightRestriction: 'hasWeightRestriction',
        hasRestrictedBreeds: 'hasRestrictedBreeds',
      });
    } else if (e === PET_TYPES.DOGS) {
      setBreedTagsToShow([]);
      setSelectedBreeds([]);
      setBreed(AllDogs);
      setPetTypeStrings(HOME_PETS_STRINGS.petTypes.DOGS);
      changeFormValue({
        restrictedBreeds: 'restrictedBreeds',
        hasWeightRestriction: 'hasWeightRestriction',
        hasRestrictedBreeds: 'hasRestrictedBreeds',
      });
    } else if (e === PET_TYPES.CATS) {
      setBreed(AllCats);
      setSelectedBreeds([]);
      setBreedTagsToShow([]);
      setPetTypeStrings(HOME_PETS_STRINGS.petTypes.CATS);
      changeFormValue({
        restrictedBreeds: 'restrictedBreeds',
        hasWeightRestriction: 'hasWeightRestriction',
        hasRestrictedBreeds: 'hasRestrictedBreeds',
      });
    } else if (e === PET_TYPES.NONE) {
      setBreed([]);
    }
  }

  return {
    inputValue,
    petTypeStrings,
    breedOptions,
    selectedBreeds,
    breedTagsToShow,
    hideGreenInfoBox,
    hideAnimationGreenInfoBox,
    handleRemoveTag,
    setHideAnimateGreenInfoBox,
    setHideGreenInfoBox,
    handleChangeInput,
    handleChange,
    closeGreenBoxMenu,
    changeFormValue,
    handleChangePetsAllowed,
  };
}
