import React, { Component } from 'react';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import TakePicture, { PICTURE_SIZE, PICTURE_TYPES } from 'components/TakePicture/TakePicture';
import { isNil } from 'es-toolkit';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { CAMERA_STRINGS } from 'strings/camera.strings';

// To-Do: Try to use DocumentUploadWizard component here. Modify the component
// to receive a flag to toggle between is embedded in modal mode.
// Flag should place the submit (save) button inside a <ModalLayout.BottomBar>
// wrapper.
export default class TakePictureModal extends Component {
  static propTypes = {
    onCtaClick: PropTypes.func.isRequired,
    subTitle: PropTypes.string,
    ctaLabel: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    pictureType: PropTypes.string,
    pictureSize: PropTypes.string,
  };

  static defaultProps = {
    subTitle: '',
    ctaLabel: 'Save',
    pictureType: PICTURE_TYPES.SELFIE,
    pictureSize: PICTURE_SIZE.MEDIUM,
  };

  constructor(props) {
    super(props);

    this.state = {
      file: null,
    };
  }

  handleCtaClick = () => {
    const { onCtaClick } = this.props;
    const { file } = this.state;

    onCtaClick(file);
  };

  handleClickCamera = (imageSource) => {
    this.setState({
      file: imageSource,
    });
  };

  enableDisableSubmitButton() {
    const { file } = this.state;

    if (isNil(file)) {
      return true;
    }

    return false;
  }

  render() {
    const { onHide, show, subTitle, ctaLabel, pictureType, pictureSize } = this.props;

    return (
      <ModalV2 onHide={onHide} show={show} closeButton noPadding>
        <ModalLayout fixedBottom>
          <ModalLayout.Title>{CAMERA_STRINGS[pictureType]}</ModalLayout.Title>
          <ModalLayout.SubTitle>{subTitle}</ModalLayout.SubTitle>
          <ModalLayout.Content>
            <TakePicture type={pictureType} videSize={pictureSize} onClickCamera={this.handleClickCamera} />
            <ModalLayout.BottomBar
              ctaProps={{
                label: ctaLabel,
                onClick: this.handleCtaClick,
                disabled: this.enableDisableSubmitButton(),
              }}
              fixedBottom
            />
          </ModalLayout.Content>
        </ModalLayout>
      </ModalV2>
    );
  }
}
