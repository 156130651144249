import { find, findIndex, includes } from 'es-toolkit/compat';
import { FlowStepDataType, HomeownerSignUpFlowStepType } from 'models/enums';
import queryString from 'query-string';
import RouteParser from 'route-parser';

const getSignUpFlowPathname = (group, key) => {
  return `/homeowner/onboard/${group}/${key}/:stepId`;
};

export const STEPS_CONFIG = {
  PROPERTY_ADDRESS: {
    name: 'property_address', // Not part of flow
    path: '/homeowner/onboard/property-address',
    group: 'details',
    key: 'property-address',
    state: {
      step: 1,
    },
  },
  MULTIPLE_PROPERTIES: {
    name: 'multiple_properties', // Not part of flow
    path: '/homeowner/onboard/dashboard',
    group: 'details',
    key: 'dashboard',
    state: {
      step: 2,
    },
  },
  PROPERTY_BASICS: {
    name: HomeownerSignUpFlowStepType.PropertyBasics,
    path: getSignUpFlowPathname(FlowStepDataType.Property, 'basics'),
    group: 'property',
    key: 'basics',
    state: {
      step: 3,
    },
  },
  UNIT_BASICS: {
    name: HomeownerSignUpFlowStepType.UnitBasics,
    path: getSignUpFlowPathname('unit', 'basics'),
    group: 'unit',
    key: 'basics',
    state: {
      step: 3,
    },
  },
  UNIT_CONDITION: {
    name: HomeownerSignUpFlowStepType.UnitConditions,
    path: getSignUpFlowPathname('unit', 'condition'),
    group: 'unit',
    key: 'condition',
    state: {
      step: 4,
    },
  },
  IS_UNIT_CURRENTLY_RENTED: {
    name: HomeownerSignUpFlowStepType.UnitOccupancy,
    path: getSignUpFlowPathname('unit', 'occupancy'),
    group: 'unit',
    key: 'occupancy',
    state: {
      step: 5,
    },
  },
  SPENDING_PREFERENCE: {
    name: HomeownerSignUpFlowStepType.UnitMaintenanceSpend,
    path: getSignUpFlowPathname('unit', 'maintenance-spend'),
    group: 'unit',
    key: 'maintenance-spend',
    state: {
      step: 6,
    },
  },
  MAINTENANCE_PREFERENCES: {
    name: HomeownerSignUpFlowStepType.HomeownerMaintenancePreferences,
    path: getSignUpFlowPathname('user', 'maintenance-preferences'),
    group: 'user',
    key: 'maintenance-preferences',
    state: {
      step: 7,
    },
  },
  MAINTENANCE_INVOLVEMENT: {
    name: HomeownerSignUpFlowStepType.HomeownerMaintenanceInvolvement,
    path: getSignUpFlowPathname('user', 'maintenance-involvement'),
    group: 'user',
    key: 'maintenance-involvement',
    state: {
      step: 8,
    },
  },
  SCHEDULE_CHAT: {
    name: 'schedule_chat', // Not part of flow
    path: '/homeowner/onboard/schedule-chat',
    group: 'details',
    key: 'schedule-chat',
    state: {
      step: 9,
    },
  },
  SUCCESS_PAGE: {
    name: 'success_page', // Not part of flow
    path: '/homeowner/onboard/success',
    group: 'details',
    key: 'success',
    state: {
      step: 10,
    },
  },
};

export const getStepConfigByName = (name) => find(STEPS_CONFIG, { name });

export const getSignUpFlowRoute = (step = {}) => {
  if (step.path) {
    return step.path;
  }

  const stepConfig = getStepConfigByName(step.stepName);
  if (stepConfig) {
    return `/homeowner/onboard/${stepConfig.group}/${stepConfig.key}/${step.id}`;
  }
};

export const STEPS = [
  STEPS_CONFIG.PROPERTY_ADDRESS,
  STEPS_CONFIG.MULTIPLE_PROPERTIES,
  STEPS_CONFIG.PROPERTY_BASICS,
  STEPS_CONFIG.UNIT_BASICS,
  STEPS_CONFIG.UNIT_CONDITION,
  STEPS_CONFIG.IS_UNIT_CURRENTLY_RENTED,
  STEPS_CONFIG.SPENDING_PREFERENCE,
  STEPS_CONFIG.MAINTENANCE_PREFERENCES,
  STEPS_CONFIG.MAINTENANCE_INVOLVEMENT,
  STEPS_CONFIG.SCHEDULE_CHAT,
  STEPS_CONFIG.SUCCESS_PAGE,
];

export const getHomeownerOnboardingStepPath = (step, params, search) => {
  const route = new RouteParser(step.path);
  let path = route.reverse(params);
  path = path.replace('?', '');
  return {
    pathname: path,
    state: step.state,
    search: typeof search === 'string' ? search : queryString.stringify(search),
  };
};

export const getNextHomeownerOnboardingStep = (name) => {
  const stepIndex = findIndex(STEPS, { name });
  return STEPS[stepIndex + 1];
};

export const getEntryPoint = (properties, onboardingState, nextStepDetails = {}) => {
  const coverageStats = properties.map((p) => p.basicInfo.inCoverageArea);
  const { nextStep, nextStepConfig } = nextStepDetails;

  if (nextStep) {
    return getHomeownerOnboardingStepPath(nextStep, {}, nextStepConfig);
  }
  /* Mixed Coverage */
  if (includes(coverageStats, true) && includes(coverageStats, false)) {
    return getHomeownerOnboardingStepPath(STEPS_CONFIG.SCHEDULE_CHAT, {}, { mixedProperties: true });
  }

  /* Multiple Properties */
  if (properties.length > 1) {
    return getHomeownerOnboardingStepPath(STEPS_CONFIG.MULTIPLE_PROPERTIES);
  }

  /* Single Property */
  const {
    basicInfo: { propertyId },
  } = properties[0];
  const propertyState = onboardingState.properties[propertyId];

  if (propertyState) {
    const { step, completed } = propertyState;

    if (completed) {
      return getHomeownerOnboardingStepPath(STEPS_CONFIG.MAINTENANCE_PREFERENCES);
    }

    return getHomeownerOnboardingStepPath(getNextHomeownerOnboardingStep(step), { propertyId });
  }

  return getHomeownerOnboardingStepPath(STEPS_CONFIG.PROPERTY_BASICS, { propertyId });
};

const getStepIndexByName = (name) => findIndex(STEPS, { name });

export const sortStepsByType = (steps = []) =>
  steps.sort((a, b) => {
    const firstItem = getStepIndexByName(a.stepName);
    const secondItem = getStepIndexByName(b.stepName);
    return firstItem - secondItem;
  });
