import { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@belong/ui';
import { HomeListingBannerStep } from 'accounts/components/home-listing-banner-step';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import {
  BANNER_IMAGE_OPTIONS,
  HOME_EXAMPLES_AVAILABLE_MEDIA,
  LEASE_DURATION_OPTIONS,
} from 'accounts/constants/home-listing';
import { STEP_GATE_NAMES, STEP_NAMES } from 'accounts/constants/steps';
import { HOME_LISTING_STRINGS } from 'accounts/constants/strings/home-listing.strings';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { useUpcomingAppointments } from 'accounts/hooks/homeowners/use-upcoming-appointments';
import { isStepCompleted, isStepUnHidden } from 'accounts/utils/flow-steps';
import classNames from 'classnames/bind';
// Final Form imports
import Field from 'components/Field/Field';
import { COLORS } from 'components/GeneralIcon/constants';
import Image from 'components/Image/Image';
import { InputFinalFormAdapter } from 'components/Input/Input';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import { MEDIA_LABEL, SIZE, NewMediaUploadAdapter, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import Carousel from 'components/v2/Carousel/Carousel';
import { SliderFinalFormAdapter } from 'components/v2/Slider/Slider';
import { isEmpty } from 'es-toolkit/compat';
import arrayMutators from 'final-form-arrays';
import Condition from 'formcomponents/Condition/Condition';
// Component imports
import FormLayout from 'layouts/FormLayout/FormLayout';
import { StepStatus } from 'models/enums';
import { selectUpcomingAppointments } from 'store/redux/homeowner-accounts/selectors';
import { uploadBannerImage } from 'store/redux/homeowner-set-up-flow/actions';
import { getString } from 'strings';
import { THEME_CONSTS } from 'themes/themes';
// Util, String and Const imports
import { required } from 'utils/validation';
import styles from './home-listing.module.css';

const cx = classNames.bind(styles);

export function HomeListing() {
  const dispatch = useDispatch();
  const { currentStep, currentStepFormData, setLoading, stepsByGate, selectedUnit, handleSaveAndNext } =
    useSetupFlowStep();
  const { loading } = useUpcomingAppointments(selectedUnit?.basicInfo.unitId);
  const upcomingAppointments = useSelector(selectUpcomingAppointments);
  const [showModal, setShowModal] = useState(false);
  const modalImageIndex = useRef(0);

  const { leaseMaxAllowedMonths } = currentStepFormData || {};

  const isAdoptedResident = selectedUnit?.isAdoptedAgreement;
  const title = isAdoptedResident ? HOME_LISTING_STRINGS['title.adopted_resident'] : HOME_LISTING_STRINGS.title;
  const showBannerImageSection = !currentStep?.unit?.isPublished && !isAdoptedResident;

  const leaseDurationOptions = [...LEASE_DURATION_OPTIONS];

  if (leaseMaxAllowedMonths === 24) {
    // If the lease max months is 24, remove the 36 months option
    delete leaseDurationOptions[2];
    leaseDurationOptions[1].tag = 'Most Popular';
  }

  async function handleSubmit(values) {
    try {
      setLoading(true);

      const submitValues = structuredClone(values);
      delete submitValues.bannerImage;
      let bannerImage;

      try {
        bannerImage = await dispatch(uploadBannerImage(values.bannerImage, currentStep.flowId, currentStep.uniqueId));
      } catch (err) {
        console.error(err);
      }

      if (bannerImage && !isEmpty(bannerImage)) {
        submitValues.banner = { ...bannerImage };
        delete submitValues.banner.url;
      }

      if (submitValues.leaseLength.leaseMaxMonths === 'custom') {
        submitValues.leaseLength.leaseMaxMonths = submitValues.leaseLength.leaseDuration;
      }

      if (leaseMaxAllowedMonths === 12) {
        submitValues.leaseLength = {
          leaseMaxMonths: 12,
        };
      }

      handleSaveAndNext(submitValues);
    } catch (e) {
      console.error(e);
    }
  }

  function getInitialValues() {
    if (!currentStepFormData) {
      return {};
    }

    const initialValues = {
      ...currentStepFormData,
    };

    if (initialValues?.banner?.url) {
      initialValues.bannerImage = {
        availableMedia: [{ ...initialValues.banner }],
        mediaToDisplay: [{ ...initialValues.banner }],
        later: false,
      };

      delete initialValues.banner;
    } else if (!initialValues?.banner?.url && showBannerImageSection && currentStep?.status !== StepStatus.Created) {
      initialValues.bannerImage = {
        later: true,
      };
    }

    if (initialValues.leaseLength?.leaseMaxMonths && ![12, 24, 36].includes(initialValues.leaseLength.leaseMaxMonths)) {
      initialValues.leaseLength = {
        leaseDuration: currentStepFormData.leaseLength?.leaseMaxMonths,
        leaseMaxMonths: 'custom',
      };
    }

    return { ...initialValues };
  }

  function handleShowModal(index?) {
    return () => {
      if (index) {
        modalImageIndex.current = index;
      }

      setShowModal((prevState) => !prevState);
    };
  }

  function getBannerStep() {
    const inspectionScheduleIsCompleted = stepsByGate?.[STEP_GATE_NAMES.InspectionSchedule]
      ?.filter(isStepUnHidden)
      ?.every(isStepCompleted);

    if (loading) {
      return undefined;
    }

    if (inspectionScheduleIsCompleted) {
      const unitInspectionScheduleStep = stepsByGate?.[STEP_GATE_NAMES.InspectionSchedule].find(
        (step) => step.stepName === STEP_NAMES.UnitInspectionSchedule
      );

      const appointment = upcomingAppointments[selectedUnit?.basicInfo.unitId]?.[0];

      const inspectionScheduledOn = appointment?.isSelfScheduled ? appointment?.startDate : '';
      const inspectionFinishOn = appointment?.isSelfScheduled ? appointment?.endDate : '';

      return (
        <HomeListingBannerStep
          inspectionScheduledOn={inspectionScheduledOn}
          inspectionFinishOn={inspectionFinishOn}
          isAdoptedResident={isAdoptedResident}
          timeZone={unitInspectionScheduleStep?.unit?.basicInfo?.timeZone}
        />
      );
    }

    return undefined;
  }

  return (
    <StepFormLayout
      onSubmit={handleSubmit}
      title={title}
      initialValues={getInitialValues()}
      bannerStep={getBannerStep()}
      mutators={{
        ...arrayMutators,
      }}
      getForm={() => (
        <Fragment>
          <FormLayout.Section firstSection sectionTitle={HOME_LISTING_STRINGS['love_about_home.section_title']}>
            <Field
              name="listingDetails.loveAbout"
              placeholder={HOME_LISTING_STRINGS['love_about_home.placeholder']}
              component={InputFinalFormAdapter}
              validate={required}
              textarea
              multiLineLabel
            />
          </FormLayout.Section>
          {leaseMaxAllowedMonths > 12 && (
            <div className="mb-2xl">
              <FormLayout.Section
                firstSection
                sectionTitle={HOME_LISTING_STRINGS['how_long.title']}
                sectionSubTitle={HOME_LISTING_STRINGS['how_long.subtitle']}
              >
                <Field
                  name="leaseLength.leaseMaxMonths"
                  component={SelectorFinalFormAdapter}
                  validate={required}
                  buttons={leaseDurationOptions}
                  tagClassName="left-0 right-0 w-fit -top-2sm mx-auto my-0"
                  type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                  buttonClassName="w-full"
                  className="grid grid-cols-2 md:grid-cols-4 gap-y-sm gap-x-xs md:gap-x-sm"
                />
              </FormLayout.Section>
              <Condition when="leaseLength.leaseMaxMonths" is="custom">
                <FormLayout.Section sectionTitle={HOME_LISTING_STRINGS.lease_duration} sectionTitleClassName="mb-xl">
                  <Field
                    component={SliderFinalFormAdapter}
                    name="leaseLength.leaseDuration"
                    min={12}
                    max={leaseMaxAllowedMonths}
                    defaultValue={leaseMaxAllowedMonths}
                    renderLeft={() => {
                      return (
                        <Text variant="p1" className="mr-sm">
                          {HOME_LISTING_STRINGS['how_long.12_months']}
                        </Text>
                      );
                    }}
                    renderRight={() => {
                      return (
                        <Text variant="p1" className="ml-sm">
                          {getString(HOME_LISTING_STRINGS['how_long.max_months'], { max: leaseMaxAllowedMonths })}
                        </Text>
                      );
                    }}
                    renderThumbDetail={(val) => (
                      <Text className="whitespace-nowrap text-h3-sm" fontWeight="semibold">{`${val} Months`}</Text>
                    )}
                    thumbContainerClassName="-top-xl text-navy"
                  />
                </FormLayout.Section>
              </Condition>
              <Condition when="leaseLength.leaseMaxMonths" hasValue>
                <Condition when="leaseLength.leaseMaxMonths" isNot={leaseMaxAllowedMonths}>
                  <div className="bg-green-translucent-light border-2 border-solid border-green rounded p-sm -mt-sm">
                    <Text>{HOME_LISTING_STRINGS['lease_duration.description']}</Text>
                  </div>
                </Condition>
              </Condition>
            </div>
          )}
          {showBannerImageSection && (
            <Fragment>
              <FormLayout.Section
                sectionTitle={HOME_LISTING_STRINGS['banner_image_later.section_title']}
                sectionSubTitle={HOME_LISTING_STRINGS['banner_image_later.section_subtitle']}
              >
                <Field
                  name="bannerImage.later"
                  component={SelectorFinalFormAdapter}
                  validate={required}
                  buttons={BANNER_IMAGE_OPTIONS}
                  type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                />
              </FormLayout.Section>
              <Condition when="bannerImage.later" is={false}>
                <FormLayout.Section
                  sectionTitle={HOME_LISTING_STRINGS['banner_image.section_title']}
                  sectionSubTitle={HOME_LISTING_STRINGS['banner_image.section_subtitle']}
                >
                  <Field
                    name="bannerImage"
                    validate={validateMedia}
                    component={NewMediaUploadAdapter}
                    mediaLabel={MEDIA_LABEL.HOME_PHOTO}
                    size={SIZE.FLUID}
                    isSingleMedia
                    disableWebcam
                  />
                </FormLayout.Section>
                <Text fontWeight="semibold" variant="body" className="flex mb-2sm">
                  {HOME_LISTING_STRINGS['carousel.title']}
                </Text>
                <div className={cx('carousel', 'flex justify-between items-center overflow-x-scroll')}>
                  {HOME_EXAMPLES_AVAILABLE_MEDIA.map((image, index) => (
                    <Image
                      key={`thumbnail-${index}`}
                      src={image}
                      className="rounded w-[167px] h-[100px] mr-sm md:mr-0 lg:mr-sm xl:mr-0 cursor-pointer"
                      onClick={handleShowModal(index)}
                    />
                  ))}
                </div>

                <ModalV2
                  show={showModal}
                  galleryModalCustomStyling
                  mobileTopImportant={false}
                  carouselV2
                  type={MODAL_TYPES.LARGE}
                  noFullPageHeader
                  backdropTheme={THEME_CONSTS.DARK}
                  contentClassName={cx('modal-content')}
                  closeButtonClassName="bg-transparent"
                  onHide={handleShowModal()}
                >
                  <div className="px-xl py-sm">
                    <Carousel
                      pagingDotsClassName="hide"
                      hideOnMobile={false}
                      leftControlPosition={{ left: -50 }}
                      rightControlPosition={{ right: -50 }}
                      controlsColor={COLORS.WHITE}
                      slideIndex={modalImageIndex.current}
                    >
                      {HOME_EXAMPLES_AVAILABLE_MEDIA.map((image, index) => (
                        <div key={index}>
                          <Image src={image} alt="Preview" />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </ModalV2>
              </Condition>
            </Fragment>
          )}
        </Fragment>
      )}
    />
  );
}
