import { Component } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { Image, Toast } from '@belong/ui';
import { animationsCache } from 'animations/animationsCache';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import Icon, { ICONS } from 'components/Icon/Icon';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCurrency, unmaskNumber } from 'components/Input/masks';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import { QUERY_PARAMS, STRINGS } from 'consts/in-person-visits';
import { isEqual } from 'es-toolkit';
import arrayMutators from 'final-form-arrays';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import FormCondition, { FIELD_CONDITION_COMPARISON_TYPES } from 'formcomponents/FormCondition/FormCondition';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { parseCalendlyDateFromURL } from 'pages/HomeOwnerOnboarding/utils';
import PropTypes from 'prop-types';
import { getString } from 'strings';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { required } from 'utils/validation';
import { PROPERTY_TYPES } from '../../constants';
import PropertyRentalLabel from '../components/PropertyRentalLabel/PropertyRentalLabel';

const { FormLayoutHeader } = FormLayout;
const HOB = HOMEOWNER_ONBOARDING_STRINGS.PropertyBasics;

const Conditions = {
  IfMultiUnit: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: PROPERTY_TYPES.MULTI_UNIT,
  },
  IfOtherUnit: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: PROPERTY_TYPES.OTHER,
  },
  IfSingleFamily: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: PROPERTY_TYPES.SINGLE_FAMILY,
  },
  IfSingleUnit: {
    fieldName: `unitsCount`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: 1,
  },
  IfNotSingleUnit: {
    fieldName: `unitsCount`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.NOT_EQUALS,
    fieldValue: 1,
  },
  IfNotSingleFamily: {
    fieldName: `propertyBasics.propertyType`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.NOT_EQUALS,
    fieldValue: PROPERTY_TYPES.SINGLE_FAMILY,
  },
  IfEntirePropertyOwned: {
    fieldName: `propertyBasics.isEntirePropertyOwnedBySingleOwner`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: true,
  },
  IfNotEntirePropertyOwned: {
    fieldName: `propertyBasics.isEntirePropertyOwnedBySingleOwner`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: false,
  },
  HasHOAFee: {
    fieldName: `propertyBasics.hasHoaFee`,
    compare: FIELD_CONDITION_COMPARISON_TYPES.EQUALS,
    fieldValue: true,
  },
};

const changeFormValue = (values, form) => {
  form.batch(() => {
    Object.values(values).forEach((item) => {
      if (typeof item === 'object') {
        form.change(item.name, item.defaultValue);
      } else {
        form.change(item, null);
      }
    });
  });
};

const getFlag = (value) => {
  if (value === 0) {
    return false;
  }
  if (value) {
    return true;
  }
};

class PropertyBasics extends Component {
  static propTypes = {
    history: PropTypes.shape({
      replace: PropTypes.func,
      push: PropTypes.func,
    }).isRequired,
    groups: PropTypes.object.isRequired,
    currentStepData: PropTypes.object.isRequired,
    currentStepResources: PropTypes.object.isRequired,
    onSaveAndNext: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isInPersonVisitsToastVisible: false,
      calendlySuccess: null,
      calendlyDate: null,
    };
  }

  componentDidMount() {
    animationsCache.preload('success_page_animation');

    const parsedURL = new URLSearchParams(this.props.location.search);
    const calendlySuccessURLParam = parsedURL.get(QUERY_PARAMS.CALENDLY_SUCCESS);
    const calendlyDate = parseCalendlyDateFromURL(this.props.location);

    this.setState({
      isInPersonVisitsToastVisible: calendlySuccessURLParam !== null,
      calendlySuccess: calendlySuccessURLParam === 'true',
      calendlyDate,
    });
  }

  handleInPersonVisitsToastClose = () => {
    this.setState({
      isInPersonVisitsToastVisible: false,
    });
  };

  onSubmit = async (values) => {
    const { onSaveAndNext } = this.props;

    if (values.propertyOwner.hasMortgage === false) {
      values.propertyOwner.mortgage = 0;
    }

    onSaveAndNext(values);
  };

  getInitialValues() {
    return {
      ...this.props.currentStepData,
      unitsCount: this.props?.currentStepResources?.property?.units?.length,
      propertyOwner: {
        ...this.props.currentStepData?.propertyOwner,
        hasMortgage: getFlag(this.props.currentStepData?.propertyOwner?.mortgage),
      },
    };
  }

  render() {
    const { groups, currentStepResources } = this.props;
    const { calendlyDate, calendlySuccess, isInPersonVisitsToastVisible } = this.state;

    if (!groups || !currentStepResources) {
      return null;
    }

    const { homes } = groups;
    const { property, user, home } = currentStepResources;

    return (
      <>
        {homes?.length > 1 && <PropertyRentalLabel property={property} home={home} />}
        <FormLayoutHeader
          pretitle={getString(HOB.subtitle, { firstName: user?.firstName })}
          title={getString(HOB.title, { streetAddress: property?.address?.streetAddress })}
        />
        <Form
          mutators={{
            ...arrayMutators,
          }}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: 'Next',
                type: 'submit',
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
          initialValues={this.getInitialValues()}
          onSubmit={this.onSubmit}
          getForm={({ form }) => {
            const formValues = form.getState().values;
            return (
              <>
                <FormLayout.Section firstSection>
                  <Field
                    name="propertyBasics.propertyType"
                    component={SelectorFinalFormAdapter}
                    validate={required}
                    buttons={[
                      {
                        label: 'Single Family',
                        icon: <Icon icon={ICONS.SINGLE_PROPERTY.DEFAULT} responsive />,
                        iconSelected: <Icon icon={ICONS.SINGLE_PROPERTY.INVERSE} responsive />,
                        key: PROPERTY_TYPES.SINGLE_FAMILY,
                        disabled: property?.units?.length > 1,
                      },
                      {
                        label: 'Multi-Unit',
                        icon: <Icon icon={ICONS.MULTI_UNIT.DEFAULT} responsive />,
                        iconSelected: <Icon icon={ICONS.MULTI_UNIT.INVERSE} responsive />,
                        key: PROPERTY_TYPES.MULTI_UNIT,
                      },
                      {
                        label: 'Other',
                        icon: <Icon icon={ICONS.TREEHOUSE.DEFAULT} responsive />,
                        iconSelected: <Icon icon={ICONS.TREEHOUSE.INVERSE} responsive />,
                        key: PROPERTY_TYPES.OTHER,
                      },
                    ]}
                    type={SELECTOR_TYPES.SELECTOR}
                  />
                </FormLayout.Section>

                <OnChange name="propertyBasics.propertyType">
                  {(val, previous) => {
                    if (previous) {
                      const objectToChange = {
                        'propertyBasics.isEntirePropertyOwnedBySingleOwner': {
                          name: 'basicInfo.isEntirePropertyOwnedBySingleOwner',
                          defaultValue: val === PROPERTY_TYPES.SINGLE_FAMILY ? true : null,
                        },
                        'propertyOwner.hasMortgage': 'propertyOwner.hasMortgage',
                        'propertyOwner.mortgage': 'propertyOwner.mortgage',
                        'propertyBasics.hasHoa': 'propertyBasics.hasHoa',
                      };

                      changeFormValue(objectToChange, form);
                    }
                  }}
                </OnChange>

                <FormCondition
                  conditions={[
                    [
                      {
                        fieldName: `propertyBasics.propertyType`,
                        compare: FIELD_CONDITION_COMPARISON_TYPES.NOT_NULL,
                      },
                    ],
                  ]}
                  formValues={formValues}
                >
                  {property?.units?.length > 1 && (
                    <FormLayout.Section sectionTitle={HOB.own_whole_building_section_title}>
                      <Field
                        validate={required}
                        name="propertyBasics.isEntirePropertyOwnedBySingleOwner"
                        component={YesNoFinalFormAdapter}
                      />
                    </FormLayout.Section>
                  )}
                  <FormCondition
                    conditions={[[Conditions.IfEntirePropertyOwned, Conditions.IfNotSingleFamily]]}
                    formValues={formValues}
                  >
                    <FormLayout.Section sectionTitle={HOB.has_mortgage_section_title}>
                      <Field validate={required} name="propertyOwner.hasMortgage" component={YesNoFinalFormAdapter} />
                      <Condition when="propertyOwner.hasMortgage" is>
                        <Row>
                          <Col md={6}>
                            <Field
                              name="propertyOwner.mortgage"
                              component={InputFinalFormAdapter}
                              placeholder={HOB.mortgage_fee_placeholder}
                              validate={required}
                              mask={maskCurrency}
                              unmask={unmaskNumber}
                            />
                          </Col>
                        </Row>
                      </Condition>
                    </FormLayout.Section>
                  </FormCondition>
                  <FormCondition
                    conditions={[
                      [Conditions.IfNotEntirePropertyOwned, Conditions.IfNotSingleFamily, Conditions.IfNotSingleUnit],
                    ]}
                    formValues={formValues}
                  >
                    <FormLayout.Section
                      sectionTitle={HOB.has_hoa_section_title_multi_units}
                      sectionSubTitle={HOB.has_hoa_section_subtitle}
                    >
                      <Field name="propertyBasics.hasHoa" component={YesNoFinalFormAdapter} validate={required} />
                    </FormLayout.Section>
                  </FormCondition>
                </FormCondition>
              </>
            );
          }}
        />
        <Toast
          icon={
            <Image
              alt=""
              className={calendlySuccess ? '' : 'object-scale-down'}
              src={calendlySuccess ? 'icons/confetti.svg' : 'icons/alert-white.svg'}
            />
          }
          isVisible={isInPersonVisitsToastVisible}
          onClose={this.handleInPersonVisitsToastClose}
          variant={calendlySuccess ? 'default' : 'danger'}
        >
          {calendlySuccess ? (
            <>
              {getString(STRINGS.SUCCESS_TOAST, {
                datetime: calendlyDate,
              })}
            </>
          ) : (
            STRINGS.FAILURE_TOAST
          )}
        </Toast>
      </>
    );
  }
}

export default PropertyBasics;
