import { useState, useEffect } from 'react';
import { AddressAutocompleteFinalFormAdapter } from 'components/AddressAutocomplete/AddressAutocomplete';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { TaggableInputFinalFormAdapter } from 'components/TaggableInput/TaggableInput';
import { INVALID_ZIPCODE } from 'consts/address';
import { get } from 'es-toolkit/compat';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { validateAddress } from 'utils/validation';
import { SingleLineLayout } from './SingleLineLayout';

const LayoutTypes = {
  Default: 'Default',
  SingleLine: 'SingleLine',
};

const AddressField = ({
  name,
  form,
  outsideUsCurrentAddress,
  handleOutsideUsAddressCallback,
  outsideUs,
  outsideUsAddressCallback,
  notRequired,
  onSelectCallback,
  multipleUnits,
  customAddressLayout, // kill
  layout,
  fullWidth,
  disabled,
  supportOutsideUsAllFields,
  ...props
}) => {
  const [addressOutsideUs, setAddressOutsideUs] = useState(!!outsideUs);
  const [zipcodeDisabled, setZipcodeDisabled] = useState(true);

  const UnitName = multipleUnits ? `${name}.units` : `${name}.unitNumber`;
  const UnitNumberInput = multipleUnits ? TaggableInputFinalFormAdapter : InputFinalFormAdapter;
  const UnitPlaceHolder = multipleUnits ? 'Unit(s)' : 'Unit';

  useEffect(() => {
    setAddressOutsideUs(!!outsideUs);
  }, [outsideUs]);

  const handleOutsideUS = () => {
    setAddressOutsideUs(!addressOutsideUs);
    outsideUsAddressCallback(!addressOutsideUs);
  };

  const handleUnitChange = (value) => {
    form.change(UnitName, value);

    if (onSelectCallback) {
      onSelectCallback(get(form.getState().values, name));
    }
  };

  const onSelect = (parsedAddress) => {
    form.batch(() => {
      Object.keys(parsedAddress).forEach((addressItem) => {
        form.change(`${name}.${addressItem}`, parsedAddress[addressItem]);
      });
    });

    if (onSelectCallback) {
      onSelectCallback(get(form.getState().values, name));
    }

    if (parsedAddress.zipcode === INVALID_ZIPCODE) {
      setZipcodeDisabled(false);
    }
  };

  const onChange = (string) => {
    form.batch(() => {
      form.change(`${name}.longitude`, null);
      form.change(`${name}.latitude`, null);
      form.change(`${name}.city`, null);
      form.change(`${name}.state`, null);
      form.change(`${name}.zipcode`, null);
      form.change(`${name}.externalId`, null);
      form.change(`${name}.externalProviderData`, null);
      form.change(`${name}.country`, null);
      form.change(`${name}.fullCountry`, null);
      form.change(`${name}.unitNumber`, null);
      form.change(`${name}.units`, null);
    });

    if (supportOutsideUsAllFields && addressOutsideUs) {
      form.change(`${name}.completeAddress`, string);
    } else if (!addressOutsideUs) {
      form.change(`${name}.completeAddress`, null);
    } else {
      form.change(`${name}.completeAddress`, string);
      form.change(`${name}.streetAddress`, null);
    }
  };

  const UnitNumber = () => {
    return (
      <Row>
        <Col md={addressOutsideUs && !supportOutsideUsAllFields ? 6 : 12}>
          <Field
            name={UnitName}
            onChangeCustom={handleUnitChange}
            component={UnitNumberInput}
            placeholder={UnitPlaceHolder}
            // only effects TaggableInputFinalFormAdapter. Maybe prop name should change to addButtonLabel
            ctaLabel="ADD"
          />
        </Col>
      </Row>
    );
  };

  const CityStateZip = () => {
    if (addressOutsideUs && !supportOutsideUsAllFields) {
      return null;
    }

    return (
      <Row>
        <Col md={6}>
          <Field
            name={`${name}.city`}
            component={InputFinalFormAdapter}
            placeholder="City"
            disabled
            applyDisabledStyle={disabled}
          />
        </Col>
        <Col md={3}>
          <Field
            name={`${name}.state`}
            component={InputFinalFormAdapter}
            placeholder="State"
            disabled
            applyDisabledStyle={disabled}
          />
        </Col>
        <Col md={3}>
          <Field
            name={`${name}.zipcode`}
            component={InputFinalFormAdapter}
            placeholder="Zipcode"
            disabled={zipcodeDisabled}
            applyDisabledStyle={disabled}
            validate={(value) => {
              if ((!value || value === INVALID_ZIPCODE) && !notRequired) {
                return 'Error';
              }
            }}
          />
        </Col>
      </Row>
    );
  };

  const hiddenFields = () => (
    <div>
      <Field hidden name={`${name}.externalId`} component={InputFinalFormAdapter} withMarginTop={false} />
      <Field hidden name={`${name}.externalProviderData`} component={InputFinalFormAdapter} withMarginTop={false} />
      <Field hidden name={`${name}.latitude`} component={InputFinalFormAdapter} withMarginTop={false} />
      <Field hidden name={`${name}.longitude`} component={InputFinalFormAdapter} withMarginTop={false} />
    </div>
  );

  if (layout === LayoutTypes.SingleLine) {
    const layoutProps = {
      form,
      name,
      UnitName,
      handleUnitChange,
      onSelect,
      onChangeCustom: onChange,
      UnitNumber,
      UnitNumberInput,
      UnitPlaceHolder,
      placeholder: notRequired ? 'Address (Optional)' : 'Address',
      fullWidth,
      validate: () => {
        if (notRequired) {
          return;
        }

        const values = get(form.getState().values, name);

        if (!values?.zipcode) {
          setZipcodeDisabled(false);
        }

        return validateAddress({
          ...values,
          addressOutsideUs,
        });
      },
    };

    return <SingleLineLayout disabled={disabled} {...layoutProps} />;
  }

  if (customAddressLayout) {
    return React.cloneElement(customAddressLayout, {
      name,
      UnitName,
      handleUnitChange,
      onSelect,
      onChange,
      UnitNumber,
      UnitNumberInput,
      UnitPlaceHolder,
    });
  }

  if (addressOutsideUs && supportOutsideUsAllFields) {
    return (
      <div>
        <Field
          form={form}
          name={`${name}.streetAddress`}
          component={AddressAutocompleteFinalFormAdapter}
          supportOutsideUsAllFields
          placeholder="Street Address"
          onSelect={onSelect}
          addressComponentObj
          onChangeCustom={onChange}
          outsideUsAddressCallback={handleOutsideUS}
          outsideUs={addressOutsideUs}
          {...props}
          validate={() => {
            if (notRequired) {
              return;
            }

            const values = get(form.getState().values, name);

            if (!values?.zipcode) {
              setZipcodeDisabled(false);
            }

            return validateAddress({ ...values });
          }}
        />
        {UnitNumber()}
        {CityStateZip()}
        {form?.getState()?.values[name]?.fullCountry && (
          <Field name={`${name}.fullCountry`} placeholder="Country" disabled component={InputFinalFormAdapter} />
        )}
        {hiddenFields()}
      </div>
    );
  }

  /**
   * @todo Remove this once all foreign addresses in different flows have all the fields.
   */
  if (addressOutsideUs) {
    return (
      <div>
        <Field
          form={form}
          name={`${name}.completeAddress`}
          component={AddressAutocompleteFinalFormAdapter}
          placeholder="Address"
          onSelect={onSelect}
          addressComponentObj
          onChangeCustom={onChange}
          outsideUsAddressCallback={handleOutsideUS}
          outsideUs={addressOutsideUs}
          {...props}
          validate={() => {
            if (notRequired) {
              return;
            }

            const values = get(form.getState().values, name);

            if (!values?.zipcode) {
              setZipcodeDisabled(false);
            }

            return validateAddress({
              ...values,
              addressOutsideUs,
            });
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <Field
        form={form}
        name={`${name}.streetAddress`}
        component={AddressAutocompleteFinalFormAdapter}
        placeholder="Street Address"
        addressComponentObj
        onSelect={onSelect}
        onChangeCustom={onChange}
        outsideUsAddressCallback={handleOutsideUS}
        outsideUs={addressOutsideUs}
        {...props}
        validate={() => {
          if (notRequired) {
            return;
          }

          const values = get(form.getState().values, name);

          if (!values?.zipcode) {
            setZipcodeDisabled(false);
          }

          return validateAddress({
            ...values,
            addressOutsideUs,
          });
        }}
      />
      {UnitNumber()}
      {CityStateZip()}
      {hiddenFields()}
    </div>
  );
};

AddressField.propTypes = {
  form: PropTypes.object,
  outsideUsCurrentAddress: PropTypes.bool,
  handleOutsideUsAddressCallback: PropTypes.func,
  outsideUs: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  outsideUsAddressCallback: PropTypes.func,
  notRequired: PropTypes.bool,
  onSelectCallback: PropTypes.func,
  multipleUnits: PropTypes.bool,
  customAddressLayout: PropTypes.node,
  layout: PropTypes.oneOf(Object.values(LayoutTypes)),
  fullWidth: PropTypes.bool,
  supportOutsideUsAllFields: PropTypes.bool,
};

AddressField.defaultProps = {
  outsideUsCurrentAddress: false,
  handleOutsideUsAddressCallback: null,
  form: null,
  name: 'address',
  multipleUnits: false,
  outsideUs: false,
  disabled: false,
  outsideUsAddressCallback: () => {},
  notRequired: false,
  onSelectCallback: null,
  customAddressLayout: null,
  layout: LayoutTypes.Default,
};

AddressField.LayoutTypes = LayoutTypes;

export default AddressField;
