import React, { useState } from 'react';
import classNames from 'classnames/bind';
import DownshiftButton from 'components/DownshiftComponents/DownshiftButton/DownshiftButton';
import DownshiftItem from 'components/DownshiftComponents/DownshiftItem/DownshiftItem';
import DownshiftMenu from 'components/DownshiftComponents/DownshiftMenu/DownshiftMenu';
import { downShiftItemProptypes } from 'components/DownshiftComponents/downShiftItemProptypes';
import GeneralIcon, { GENERAL_ICONS, COLORS } from 'components/GeneralIcon/GeneralIcon';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { useSelect } from 'downshift';
import { findIndex } from 'es-toolkit/compat';
import PropTypes from 'prop-types';
import styles from './DropdownComponent.module.css';

const cx = classNames.bind(styles);

const itemToString = (i) => (i ? i.value : '');

const DropdownComponent = ({
  placeholder,
  error,
  items,
  defaultHighlightedIndex,
  displayIcon,
  disabled,
  onSelectedItemChange,
  defaultSelectedItem,
  downshiftMenuStyle,
  itemClassname = '',
}) => {
  const [highlight, setHighlight] = useState(false);
  const [menuBlurCleanUp, setMenuBlurCleanUp] = useState(false);
  // const [useIndex, setUseIndex] = useState(defaultHighlightedIndex);

  // There is a weird bug in downshift where useSelect.stateChangeTypes.MenuBlur is always called even though you are clicking on the button state and have the refs pointed there.
  // This is to allow you to focus again after 25 ms.
  const removeMenuBlur = () =>
    setTimeout(() => {
      setMenuBlurCleanUp(false);
    }, 0);

  const { reset, isOpen, selectedItem, highlightedIndex, getToggleButtonProps, getMenuProps, getItemProps } = useSelect(
    {
      items,
      onSelectedItemChange,
      itemToString,
      defaultSelectedItem,
      defaultHighlightedIndex,
      stateReducer: (state, actionAndChanges) => {
        const { type, changes } = actionAndChanges;

        if (type === useSelect.stateChangeTypes.MenuBlur) {
          setMenuBlurCleanUp(true);
        }

        const index = findIndex(items, (e) => {
          return e.key === state.selectedItem?.key;
        });
        switch (type) {
          case useSelect.stateChangeTypes.ToggleButtonClick:
            // https://github.com/downshift-js/downshift/issues/645
            // This is an incredibly dumb bug
            if (state.previousType === useSelect.stateChangeTypes.MenuBlur && menuBlurCleanUp) {
              return { ...changes, isOpen: false, previousType: type, highlightedIndex: null };
            }

            // From official docs to handle changes.
            // eslint-disable-next-line no-prototype-builtins
            if (changes.hasOwnProperty('isOpen') && changes.isOpen)
              return {
                ...changes,
                previousType: type,
                highlightedIndex: index,
              };
            return { ...changes, previousType: type, highlightedIndex: null };

          case useSelect.stateChangeTypes.FunctionReset:
            return { ...changes, previousType: type, highlightedIndex: null };
          default:
            return { ...changes, previousType: type };
        }
      },
    }
  );

  // sets clean up for MenuBlur
  React.useEffect(() => {
    if (menuBlurCleanUp) {
      removeMenuBlur();
    }
  }, [menuBlurCleanUp]);

  // https://github.com/downshift-js/downshift/issues/977
  React.useEffect(() => {
    reset();
  }, [defaultSelectedItem?.key]);

  return (
    <div className={cx('dropdown-wrapper')}>
      <div>
        <DownshiftButton
          {...getToggleButtonProps({
            placeholder,
            onBlur: () => {
              setHighlight(false);
            },
            onFocus: () => {
              setHighlight(true);
            },
          })}
          highlight={highlight || isOpen}
          error={error}
          disabled={disabled}
          value={selectedItem?.value}
        >
          {itemToString(selectedItem)}
        </DownshiftButton>
        {displayIcon && (
          <ButtonBase
            className={cx('icon-button-wrapper')}
            tabIndex="-1"
            {...getToggleButtonProps({})}
            disabled={disabled}
          >
            {displayIcon(isOpen)}
          </ButtonBase>
        )}
      </div>
      <DownshiftMenu isOpen={isOpen} {...getMenuProps()} style={downshiftMenuStyle}>
        {isOpen &&
          items.map((item, index) => (
            <DownshiftItem
              isActive={highlightedIndex === index}
              key={`${item.value}${index}`}
              className={itemClassname}
              {...getItemProps({
                item,
                index,
              })}
            >
              {item.content ? (
                <div className={cx(selectedItem?.key === item.key && 'selectedCustomItem')}>{item.content}</div>
              ) : (
                itemToString(item)
              )}
              {selectedItem?.key === item.key && (
                <GeneralIcon scale={0.75} color={COLORS.GREEN} icon={GENERAL_ICONS.CHECKMARK} />
              )}
            </DownshiftItem>
          ))}
      </DownshiftMenu>
    </div>
  );
};

DropdownComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(downShiftItemProptypes)).isRequired,
  defaultHighlightedIndex: PropTypes.number.isRequired,
  displayIcon: PropTypes.func.isRequired,
  onSelectedItemChange: PropTypes.func.isRequired,
  defaultSelectedItem: PropTypes.shape(downShiftItemProptypes),
  disabled: PropTypes.bool.isRequired,
  downshiftMenuStyle: PropTypes.object,
  itemClassname: PropTypes.string,
};

DropdownComponent.defaultProps = {
  defaultSelectedItem: null,
  downshiftMenuStyle: {},
};

export default DropdownComponent;
