/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { formatUtcDateTimeToTimeZone } from '@belong/common';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import TimeList from 'components/DateSelector/TimeList/TimeList';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Tooltip from 'components/Tooltip/Tooltip';
import Carousel from 'components/v2/Carousel/Carousel';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { addDays, isTomorrow, isToday } from 'date-fns';
import { Text, Flex } from 'design-system';
import { isNil, chunk, isEqual, isString } from 'es-toolkit';
import { find } from 'es-toolkit/compat';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import { useWindowSize } from 'hooks/useWindowSize';
import { AvailiabilityTimes } from 'models/enums/index';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { ADD_TASK_MODAL_STRINGS } from 'strings/add-task-modal';
import { ConditionalWrapper } from 'utils/ConditionalWrapper';
import styles from './DateSelector.module.css';

export const DATE_SELECTOR_MODE = {
  SINGULAR: 'SINGULAR',
  MULTIPLE: 'MULTIPLE',
};

const cx = classNames.bind(styles);

const defaultListOfTimes = [
  {
    label: formatString('Morning{br}{smallText}', {
      br: <br />,
      smallText: <div className={cx('small-text')}>(9AM - Noon)</div>,
    }),
    value: AvailiabilityTimes.Morning,
  },
  {
    label: formatString('Early Afternoon{br}{smallText}', {
      br: <br />,
      smallText: <div className={cx('small-text')}>(Noon - 3PM)</div>,
    }),
    value: AvailiabilityTimes.Afternoon,
  },
  {
    label: formatString('Late Afternoon{br}{smallText}', {
      br: <br />,
      smallText: <div className={cx('small-text')}>(3PM - 6PM)</div>,
    }),
    value: AvailiabilityTimes.Evening,
  },
  {
    label: formatString('All Day{br}{smallText}', {
      br: <br />,
      smallText: <div className={cx('small-text')}>(9AM - 6PM)</div>,
    }),
    value: AvailiabilityTimes.AllDay,
  },
];

const DateBox = ({
  mode,
  listOfTimes,
  index,
  dateValues,
  setSelectedIndex,
  customCallBack,
  selectedIndex,
  showTooltip,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { width } = useWindowSize();
  const isSmallScreen = width <= BREAKPOINTS_WIDTHS.SM || width <= BREAKPOINTS_WIDTHS.MD;
  const { date } = dateValues;
  const getDate = () => {
    if (isTomorrow(legacyParse(date))) {
      return 'Tomorrow';
    }
    if (isToday(legacyParse(date))) {
      return 'Today';
    }
    return <BDate time={date} formatType={DATE_TYPES.DAY} />;
  };
  function isWeekend() {
    const formattedDay = formatUtcDateTimeToTimeZone({ dateTime: date, format: DATE_TYPES.DAY });
    return formattedDay === 'Saturday' || formattedDay === 'Sunday';
  }
  const daysPerSlide = 5;
  const slideMiddle = 3;
  // Manually handle tooltip position because of carousel overflow.
  const tooltipPosition = index % daysPerSlide < slideMiddle ? 'right center' : 'left center';

  useEffect(() => {
    if (tooltipOpen) {
      setTooltipOpen(false);
    } else if ((isToday(legacyParse(date)) || isWeekend()) && selectedIndex === index) {
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, [5000]);
    }
  }, [selectedIndex]);

  const DateButton = React.forwardRef((_props, ref) => (
    <Button
      ref={ref}
      buttonType={BUTTON_TYPES.NOSTYLE}
      className={cx('selector-boxes', { selected: selectedIndex === index })}
      onClick={() => {
        setSelectedIndex((prevState) => {
          if (prevState === index) {
            return null;
          }
          return index;
        });
      }}
    >
      <div className={cx('title', { selected: selectedIndex === index })}>{getDate(dateValues)}</div>
      <div className={cx('subtitle', { selected: selectedIndex === index })}>
        <BDate time={dateValues.date} formatType={DATE_TYPES.DAY_MONTH} />
      </div>
      <div className={cx('rectangle-container', { displaynone: mode === DATE_SELECTOR_MODE.SINGULAR })}>
        {!!dateValues.times?.length &&
          // If is default implementation
          (isEqual(listOfTimes, defaultListOfTimes) && dateValues.times.includes(AvailiabilityTimes.AllDay)
            ? [1, 2, 3].map((_, rectangleIndex) => <div key={rectangleIndex} className={cx('rectangle')} />)
            : dateValues.times.map((_, rectangleIndex) => <div key={rectangleIndex} className={cx('rectangle')} />))}
      </div>
    </Button>
  ));

  return (
    <>
      <div className={cx('selector', { selected: selectedIndex === index })}>
        {/* Having defaultOpen={tooltipOpen} does not work, can't figure out why  */}
        {showTooltip && tooltipOpen ? (
          <Tooltip
            defaultOpen
            position={isSmallScreen ? 'bottom center' : tooltipPosition}
            contentStyle={{ fontSize: '1.6rem', width: '270px', borderRadius: '8px', padding: '4px 8px' }}
            on={[]}
            trigger={<DateButton />}
          >
            <Flex alignItems="center">
              <GeneralIcon className={cx('date-tooltip-icon')} scale={2} icon={GENERAL_ICONS.WARNING_LARGE} />
              <Text ml="xs" py="2xs">
                {isWeekend()
                  ? ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.availability_weekend']
                  : ADD_TASK_MODAL_STRINGS['renter_upgrade_modal.availability_today']}
              </Text>
            </Flex>
          </Tooltip>
        ) : (
          <DateButton />
        )}
        <div className={cx('display-on-mobile')}>
          <TimeList
            listOfTimes={listOfTimes}
            customCallBack={customCallBack}
            show={selectedIndex === index}
            highlightArray={dateValues?.times}
            mode={mode}
            disabledTime={(time) => time.value === AvailiabilityTimes.Morning && isToday(legacyParse(date))}
          />
        </div>
      </div>
    </>
  );
};

const DateSelector = ({
  amountOfDays,
  standardSelector,
  onSelect,
  value,
  useDatesFromValues,
  listOfTimes,
  mode,
  error,
  showTooltip,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const now = Date.now();
  const todaysDate = formatUtcDateTimeToTimeZone({ dateTime: now, format: DATE_TYPES.STANDARD });

  function isBeforeNoon() {
    const today = formatUtcDateTimeToTimeZone({ dateTime: now, format: DATE_TYPES.TIME_WITHOUT_MINUTES });
    const [hours, ampm] = today.split(' ');

    return hours < 12 && ampm.toLowerCase() === 'am';
  }

  function calculateInitialValues() {
    // This is because, in some cases, we always want to show the next five days.
    if (!useDatesFromValues) {
      const newDates = new Array(amountOfDays).fill('');
      const offset = isBeforeNoon() ? 0 : 1;
      const newToggleDates = newDates.map((e, index) => {
        const date = formatUtcDateTimeToTimeZone({
          dateTime: addDays(legacyParse(todaysDate), index + offset),
          format: DATE_TYPES.STANDARD,
        });

        const removedItems = value.filter(
          (hasDate) => formatUtcDateTimeToTimeZone({ dateTime: hasDate.date, format: DATE_TYPES.STANDARD }) === date
        );

        const formattedRemovedItems = removedItems.map((item) => ({
          ...item,
          date: formatUtcDateTimeToTimeZone({ dateTime: item.date, format: DATE_TYPES.STANDARD }),
        }));

        let times = [...listOfTimes];

        if (isEqual(listOfTimes, defaultListOfTimes) && isToday(legacyParse(date))) {
          const morning = { ...times.shift(), disabled: true };
          times = isToday(legacyParse(date)) ? [morning, ...times] : times;
        }

        return {
          date,
          availableTimes: times,
          ...formattedRemovedItems[0],
        };
      });

      // For some reason not re-rendering correctly without this.
      setTimeout(() => {
        onSelect(newToggleDates);
      });
    }
    // Finds the only selected value and highlights it.
    if (mode === DATE_SELECTOR_MODE.SINGULAR) {
      find(value, (val, index) => {
        if (val.times?.length) {
          setSelectedIndex(index);
        }
      });
    }
  }

  useEffect(() => {
    // If not standard selector only run on value change
    if (!standardSelector) {
      calculateInitialValues();
    }
  }, [value]);

  useEffect(() => {
    // If standard selector only run in first time
    if (standardSelector) {
      calculateInitialValues();
    }
  }, []);

  const customCallBack = (changedValue) => {
    const customValue = cloneDeep(value);

    if (mode === DATE_SELECTOR_MODE.SINGULAR) {
      const resetTimesCustomValue = customValue.map((timeObject) => {
        return {
          availableTimes: timeObject.availableTimes,
          date: timeObject.date,
        };
      });
      const valueToEdit = resetTimesCustomValue[selectedIndex];
      valueToEdit.times = changedValue;
      onSelect(resetTimesCustomValue);
    } else {
      const valueToEdit = customValue[selectedIndex];

      // If using the default list of times
      if (isEqual(listOfTimes, defaultListOfTimes)) {
        if (valueToEdit?.times?.includes?.(AvailiabilityTimes.AllDay)) {
          changedValue = changedValue.filter((val) => val !== AvailiabilityTimes.AllDay);
        } else if (changedValue?.includes?.(AvailiabilityTimes.AllDay)) {
          changedValue = changedValue.filter((val) => val === AvailiabilityTimes.AllDay);
        }
      }

      valueToEdit.times = changedValue;
      onSelect(customValue);
    }
  };

  const daysPerSlide = 5;
  const chunkedValue = chunk(value, daysPerSlide);

  return (
    <>
      <ConditionalWrapper
        // Only show as carousel if the amountOfDays is bigger than the daysPerSlide.
        condition={amountOfDays > daysPerSlide}
        wrapper={(children) => (
          <Carousel
            pagingDotsClassName="green"
            className={cx('carousel-container', { open: !isNil(selectedIndex) })}
            dragging={false}
            defaultBottomPosition={false}
            leftControlPosition={{ left: -20 }}
            rightControlPosition={{ right: -20 }}
          >
            {children}
          </Carousel>
        )}
      >
        {chunkedValue.map((_values, outerIndex) => {
          return (
            <div key={outerIndex}>
              <div
                key={outerIndex}
                className={cx('selector-wrapper', { marginX: amountOfDays > daysPerSlide }, { error: !!error })}
              >
                {_values?.map((dateValues, innerIndex) => {
                  const index = daysPerSlide * outerIndex + innerIndex;
                  if (index < amountOfDays) {
                    if (standardSelector) {
                      return (
                        <DateBox
                          key={index}
                          index={index}
                          setSelectedIndex={setSelectedIndex}
                          selectedIndex={selectedIndex}
                          dateValues={dateValues}
                          customCallBack={customCallBack}
                          listOfTimes={dateValues.availableTimes}
                          mode={mode}
                          showTooltip={showTooltip}
                        />
                      );
                    }
                    const hasAvailableTime = dateValues.availableTimes?.filter((time) => !time.hidden).length;

                    return hasAvailableTime ? (
                      <DateBox
                        key={index}
                        index={index}
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                        dateValues={dateValues}
                        customCallBack={customCallBack}
                        listOfTimes={dateValues.availableTimes}
                        mode={mode}
                        showTooltip={showTooltip}
                      />
                    ) : null;
                  }
                  return null;
                })}
              </div>
            </div>
          );
        })}
      </ConditionalWrapper>
      <div className={cx('displayOnTime')}>
        <TimeList
          error={error}
          listOfTimes={value[selectedIndex]?.availableTimes}
          show={!isNil(selectedIndex)}
          selectedIndex={selectedIndex}
          customCallBack={customCallBack}
          mode={mode}
          highlightArray={value[selectedIndex]?.times}
          smallButtons={!showTooltip}
        />
      </div>
      {isString(error) && (
        <Text pl="2xs" pt="2xs" color="red">
          {error}
        </Text>
      )}
    </>
  );
};

export default DateSelector;

export const DateSelectorFinalFormAdapter = ({ input, meta, ignoreError, onChangeCustom, customCallback, ...rest }) => {
  const controlledProps = {};

  if (!ignoreError) {
    controlledProps.error = meta.error;
  }

  return (
    <DateSelector
      {...input}
      {...rest}
      {...controlledProps}
      onSelect={(value) => {
        input.onChange(value);
        if (onChangeCustom) {
          onChangeCustom(value);
        }
      }}
    />
  );
};

DateSelector.propTypes = {
  amountOfDays: PropTypes.number,
  onSelect: PropTypes.func,
  value: PropTypes.array,
  listOfTimes: PropTypes.array,
  mode: PropTypes.oneOf(Object.values(DATE_SELECTOR_MODE)),
  error: PropTypes.bool.isRequired,
  useDatesFromValues: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

DateSelector.defaultProps = {
  amountOfDays: 5,
  onSelect: () => {},
  value: [],
  listOfTimes: defaultListOfTimes,
  mode: DATE_SELECTOR_MODE.MULTIPLE,
  useDatesFromValues: false,
  showTooltip: false,
};

DateBox.propTypes = {
  selectedIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  dateValues: PropTypes.object.isRequired,
  customCallBack: PropTypes.func.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
};

DateBox.defaultProps = {
  selectedIndex: null,
};
