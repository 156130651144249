import { COOKIES_CONFIG } from '@belong/common';
import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import { setEnhancedConvertionData } from 'analytics/ga.utils';
import { isNil } from 'es-toolkit';
import { resetAppModal } from 'lease-signing-flow/store/app';
import { IdVerificationDocuments, IMAGE_FILE_TYPE, PaymentMethod, VerificationMethod } from 'models/enums';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { fetchFavoriteHomes } from 'store/redux/renter-accounts/actions';
import { parseDateTimeInputString } from 'utils/dateTimeUtils';
import { getMediaTypeFromFile } from 'utils/file';
import { uploadDocumentToS3 } from '../common/uploadDocumentToS3';
import { uploadFiles } from '../images/actions';
import { getApiActionTypesValues } from '../redux_utils';
import { fetchRegions } from '../settings';
import { ACTIONS } from './constants';

const { HAS_ACCOUNT, JWT_TOKEN, REFRESH_TOKEN, REFERRAL_ID, REFERRAL_USER, PARTNER_KEY } = COOKIES_CONFIG;

export const fetchUserClaims = () => ({
  types: getApiActionTypesValues('FETCH_USER_CLAIMS'),
  promise: ({ client }) => client.get('/users/claims'),
  auth: true,
  redirectToHomeOnHide: true,
});

export const setUserSession = (data) => ({
  type: ACTIONS.USER_SET_SESSION,
  result: data,
});

export const sessionRegister = (user) => async (dispatch) => {
  const data = await dispatch({
    types: getApiActionTypesValues('USER_SESSION_REGISTER'),
    promise: async ({ client, octaneClient }) => {
      const cookies = parseCookies();
      const partnerKey = cookies[PARTNER_KEY.name];
      const response = await client.post('/users/register', { ...user, encodedPartnerKey: partnerKey });

      setEnhancedConvertionData(user);

      client.setJwtToken(response.token);
      octaneClient.setJwtToken(response.token);
      dispatch(setUserSession({ user }));
      return response;
    },
  });

  return data;
};

export const register = (user) => ({
  types: [ACTIONS.USER_REGISTER_REQUEST, ACTIONS.USER_REGISTER_SUCCESS, ACTIONS.USER_REGISTER_FAILURE],
  promise: ({ client }) => {
    const cookies = parseCookies();
    const partnerKey = cookies[PARTNER_KEY.name];
    return client.post('/users/register', { ...user, encodedPartnerKey: partnerKey });
  },
});

export const login = (user) => async (dispatch) => {
  const data = await dispatch({
    promise: async ({ client, octaneClient }) => {
      const response = await client.post('/users/login', { ...user });

      setEnhancedConvertionData(user);

      client.setJwtToken(response.token);
      octaneClient.setJwtToken(response.token);
      setCookie(null, JWT_TOKEN.name, response.token, JWT_TOKEN.options);
      setCookie(null, REFRESH_TOKEN.name, response.refreshToken, REFRESH_TOKEN.options);

      return response;
    },
  });

  return data;
};

export const loginExternal = (loginObject) => async (dispatch) => {
  const data = await dispatch({
    promise: async ({ client, octaneClient }) => {
      const response = await client.post('/users/login-external', { ...loginObject });

      setEnhancedConvertionData(loginObject);

      client.setJwtToken(response.token);
      octaneClient.setJwtToken(response.token);
      setCookie(null, JWT_TOKEN.name, response.token, JWT_TOKEN.options);
      setCookie(null, REFRESH_TOKEN.name, response.refreshToken, REFRESH_TOKEN.options);

      return response;
    },
  });

  return data;
};

export const refreshToken = (refreshTokenData) => ({
  types: getApiActionTypesValues('REFRESH_TOKEN'),
  promise: ({ client }) => client.post('/users/refreshToken', refreshTokenData),
});

export const fetchUser = () => async (dispatch) => {
  try {
    const userObject = await dispatch({
      types: [ACTIONS.USER_FETCH_REQUEST, ACTIONS.USER_FETCH_SUCCESS, ACTIONS.USER_FETCH_FAILURE],
      promise: ({ client }) => client.get('/users'),
    });

    const accessibilityPreferences = {
      themePreference: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
      languagePreference: window.navigator.language,
      reducedMotionPreference: window.matchMedia('(prefers-reduced-motion: reduce)').matches,
      isHighContrastModePreference: window.matchMedia('(forced-colors: active)').matches,
    };

    setCookie(null, HAS_ACCOUNT.name, true, HAS_ACCOUNT.options);

    if (userObject?.userId) {
      window.mixpanel?.identify?.(userObject.userId);
      window.mixpanel?.register?.({
        email: userObject.email,
        ...accessibilityPreferences,
      });

      FullStory.identify(userObject.userId, {
        email: userObject.email,
        ...accessibilityPreferences,
      });

      datadogRum.setUser({
        id: userObject?.userId,
        email: userObject.email,
        ...accessibilityPreferences,
      });
    }

    await Promise.all([dispatch(fetchUserClaims()), dispatch(fetchFavoriteHomes()), dispatch(fetchRegions())]);

    return userObject;
  } catch (e) {
    throw e;
  }
};

// PUT : /users/phones
export const updateUserPhone = (userPhoneObject) => ({
  types: getApiActionTypesValues('UPDATE_USER_PHONE'),
  promise: ({ client }) => client.put('/users/phones', userPhoneObject),
});

export const forgotPassword = (userEmail) => ({
  types: [
    ACTIONS.USER_FORGOT_PASSWORD_REQUEST,
    ACTIONS.USER_FORGOT_PASSWORD_SUCCESS,
    ACTIONS.USER_FORGOT_PASSWORD_FAILURE,
  ],
  promise: ({ client }) => client.post('/users/forgot-password', { ...userEmail }),
});

export const resetPassword = (resetPasswordObject) => ({
  types: [
    ACTIONS.USER_RESET_PASSWORD_REQUEST,
    ACTIONS.USER_RESET_PASSWORD_SUCCESS,
    ACTIONS.USER_RESET_PASSWORD_FAILURE,
  ],
  promise: ({ client }) => client.post('/users/reset-password', { ...resetPasswordObject }),
});

export const setPassword = (setPasswordObject) => ({
  types: [ACTIONS.USER_SET_PASSWORD_REQUEST, ACTIONS.USER_SET_PASSWORD_SUCCESS, ACTIONS.USER_SET_PASSWORD_FAILURE],
  promise: ({ client }) => client.post('/users/set-password', { ...setPasswordObject }),
});

export const fetchUserToDos = () => ({
  types: getApiActionTypesValues('FETCH_USER_TODOS'),
  promise: ({ client }) => client.get('/users/todo'),
});

export const fetchDwollaToken = (paymentMethod) => ({
  types: getApiActionTypesValues('USER_FETCH_DWOLLA_TOKEN'),
  promise: ({ client }) =>
    client.post('/users/payment-methods/token', {
      paymentMethod,
    }),
  auth: true,
});

export const fetchPlaidToken = (products, redirectUrl) => ({
  types: getApiActionTypesValues('USER_FETCH_PLAID_TOKEN'),
  promise: ({ client }) =>
    client.post('/users/payment-methods/link-token', {
      redirectUrl,
      products,
    }),
  auth: true,
});

// Note: this endpoint returns a sorted array of payment methods.
// The sorting is based on the last-added-first order.
// Eg: response[0] is the last added payment account (ach/cc) for this user
export const fetchPaymentMethods = () => ({
  types: getApiActionTypesValues('USER_FETCH_PAYMENT_METHODS'),
  promise: ({ client }) => client.get('/users/payment-methods'),
  auth: true,
});

export const addAchInstantPaymentMethod =
  (token, metadata, defaultCard, doNotFetchPaymentMethodsOnSuccess) => async (dispatch) => {
    const [lastAddedPaymentMethod] = await dispatch({
      types: getApiActionTypesValues('USER_ADD_PAYMENT_METHOD'),
      promise: ({ client }) =>
        client.post('/users/payment-methods', {
          paymentMethod: PaymentMethod.Ach,
          setAsDefault: defaultCard,
          achAccountInfo: {
            verificationMethod: VerificationMethod.Instant,
            instantAchAccount: {
              accountToken: token,
              verifiedAccountData: metadata,
            },
          },
        }),
      auth: true,
    });

    if (doNotFetchPaymentMethodsOnSuccess) {
      return lastAddedPaymentMethod;
    }

    await dispatch(fetchPaymentMethods());
    return lastAddedPaymentMethod;
  };

export const addCreditCardPaymentMethod = (creditCardDetails) => async (dispatch) => {
  const addedCard = await dispatch({
    types: getApiActionTypesValues('USER_ADD_PAYMENT_METHOD_CC'),
    promise: ({ client }) =>
      client.post('/users/payment-methods', {
        paymentMethod: PaymentMethod.CreditCard,
        creditCardInfo: {
          cardNumber: creditCardDetails.creditCardNumber,
          expirationMonth: Number(creditCardDetails.expirationDate.split('/')[0]),
          expirationYear: Number(creditCardDetails.expirationDate.split('/')[1]),
          cvc: creditCardDetails.cvc,
          zipcode: creditCardDetails.billingZipCode,
        },
      }),
    auth: true,
  });
  await dispatch(fetchPaymentMethods());
  return addedCard;
};

export const addAchMicrodepositPaymentMethod = (metadata) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('USER_ADD_PAYMENT_METHOD'),
    promise: ({ client }) =>
      client.post('/users/payment-methods', {
        paymentMethod: PaymentMethod.Ach,
        achAccountInfo: {
          verificationMethod: VerificationMethod.MicroDeposit,
          manualAchAccount: metadata,
        },
      }),
    auth: true,
  });

  const addedPaymentAccounts = await dispatch(fetchPaymentMethods());
  return addedPaymentAccounts;
};

export const verifyAchMicrodepositPaymentMethod = (accountId, deposits) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('USER_VERIFY_PAYMENT_METHOD'),
    promise: ({ client }) =>
      client.put(`/users/payment-methods/${accountId}/ach-verify`, {
        ...deposits,
      }),
    auth: true,
  });

  const paymentMethods = await dispatch(fetchPaymentMethods());
  return paymentMethods;
};

export const updatePaymentMethod = (id, paymentType, priority) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('UPDATE_DEFAULT_PAYMENT'),
    promise: ({ client }) =>
      client.put(`/users/payment-methods/${id}`, {
        paymentType,
        priority,
      }),
    auth: true,
  });

  await Promise.all([dispatch(fetchPaymentMethods()), dispatch(fetchUserToDos())]);
};

export const updateLegalIdentityInformation = (legalIdentityInformation) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('UPDATE_USER_LEGAL_IDENTITY_INFO'),
    promise: ({ client }) => client.put('/users/legal-identity', legalIdentityInformation),
    auth: true,
  });

export const fetchIdenityVerificationInfo = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_IDENTITY_VERIFICATION_INFO'),
    promise: ({ client }) => client.get('/users/verifications/id'),
    auth: true,
  });

export const fetchUserVerifications = (userId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_USER_VERIFICATIONS'),
    promise: ({ octaneClient }) =>
      octaneClient
        .get(`/v1/verification?userId=${userId}`)
        .then(([userVerifications]) => userVerifications?.verifications),
    auth: true,
  });

export const fetchUserIdentityVerification = (verificationId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_USER_IDENTITY_VERIFICATION'),
    promise: ({ octaneClient }) => octaneClient.get(`/v1/verification/identity/${verificationId}`),
    auth: true,
  });

export const createUserIdentityVerification = (userId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('CREATE_USER_IDENTITY_VERIFICATION'),
    promise: ({ octaneClient }) => octaneClient.post(`/v1/verification/identity/${userId}`),
    auth: true,
  });

export const fetchVerificationFor = (type) => (dispatch) =>
  dispatch({
    promise: ({ client }) => client.get(`/users/verifications/${type}/isvalid`),
    auth: true,
  });

export const fetchUserVerification = (params) => ({
  types: getApiActionTypesValues('FETCH_ONE_VERIFICATION'),
  promise: ({ client }) =>
    client.get(`/users/verifications`, {
      params,
    }),
});

// POST : /users/verifications/{verificationType}
export const createVerification = (verificationType) => ({
  types: getApiActionTypesValues('CREATE_VERIFICATION'),
  promise: ({ client }) => client.post(`/users/verifications/${verificationType}`),
});

export const createAllVerificationsWithoutIdentityAndReference = () => async (dispatch) => {
  const routes = ['Credit/Microbilt', 'Criminal/Microbilt', 'Eviction/Microbilt'];

  const verificationsPromises = routes.map((verificationType) => dispatch(createVerification(verificationType)));

  await Promise.all(verificationsPromises);
};

// POST : /users/verifications/id/run
export const createRunIdentityVerification = () => ({
  promise: ({ client }) => client.post('/users/verifications/id/run'),
  auth: true,
});

export const uploadIdVerificationDocuments = (data) => async (dispatch) => {
  const { type, imagePassport, imageIdFront, imageIdBack, imageSelfie, dateOfBirth } = data;
  const serverObject = {
    user: {
      idType: type,
    },
  };

  if (type === IdVerificationDocuments.PASS) {
    const [passport] = await dispatch(uploadFiles(IMAGE_FILE_TYPE.IdFront, imagePassport));
    serverObject.idFront = passport;
  } else {
    const [idFront] = await dispatch(uploadFiles(IMAGE_FILE_TYPE.IdFront, imageIdFront));
    serverObject.idFront = idFront;

    const [idBack] = await dispatch(uploadFiles(IMAGE_FILE_TYPE.IdBack, imageIdBack));
    serverObject.idBack = idBack;
  }

  const [selfie] = await dispatch(uploadFiles(IMAGE_FILE_TYPE.Selfie, imageSelfie));
  serverObject.selfie = selfie;

  if (dateOfBirth) serverObject.user.dateOfBirth = parseDateTimeInputString(data?.dateOfBirth);

  return serverObject;
};

export const createIdentityVerificationInfo = (data) => async (dispatch) => {
  const serverObject = await dispatch(uploadIdVerificationDocuments(data));

  await dispatch({
    types: getApiActionTypesValues('CREATE_IDENTITY_VERIFICATION_INFO'),
    promise: ({ client }) => client.put('/users/verifications/id', serverObject),
    auth: true,
  });
  await dispatch(fetchIdenityVerificationInfo());
};

export const fetchResidentLeases = () => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_LEASE'),
    promise: ({ client }) => client.get('/residents/summary'),
    auth: true,
  });

export const fetchUserDetailsById = (id) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_USER_DETAILS_BY_ID'),
    promise: ({ client }) => client.get(`/users/profile/${id}`),
    auth: true,
  });

export const updateUserProfile = (userProfile) => async (dispatch) => {
  const response = await dispatch({
    types: getApiActionTypesValues('UPDATE_USER_PROFILE'),
    promise: ({ client }) => client.put('/users/profile', userProfile),
    auth: true,
  });
  return response;
};

export const fetchCurrentUserDetails = () => async (dispatch) => {
  const userProfile = await dispatch({
    types: getApiActionTypesValues('FETCH_CURRENT_USER_DETAILS'),
    promise: ({ client }) => client.get('/users/profile'),
  });

  setEnhancedConvertionData(userProfile);

  await dispatch(fetchUserClaims());

  return userProfile;
};

export const getDocumentUploadUrl = () => ({
  promise: ({ client }) => client.get('/users/profile-image/upload-url'),
  auth: true,
});

export const createProfileImage = (uniqueId) => {
  if (uniqueId) {
    return {
      promise: ({ client }) => client.post('/users/profile-image', uniqueId),
      auth: true,
    };
  }
};

export const deleteProfileImage = () => ({
  promise: ({ client }) => client.delete('/users/profile-image'),
  auth: true,
});

export const uploadProfilePhoto =
  ({ stagingMedia, deletedMedia }) =>
  async (dispatch) => {
    if (isNil(stagingMedia) && isNil(deletedMedia)) return null;
    if (deletedMedia.length) await dispatch(deleteProfileImage());
    if (stagingMedia.length) {
      const file = stagingMedia[0];
      const mediaType = await getMediaTypeFromFile(file);
      const response = mediaType ? await dispatch(getDocumentUploadUrl(mediaType)) : null;
      const { headers, uploadUrl, uniqueId } = response;
      await uploadDocumentToS3(dispatch, file, headers, uploadUrl);
      await dispatch(createProfileImage({ mediaId: uniqueId }));
      return uniqueId;
    }
  };

export const userDataClear = () => ({
  type: ACTIONS.USER_DATA_CLEAR,
});

export const createUserLogoutBackend = () => ({
  promise: ({ client }) => client.post('/users/logout'),
});

export const logOut = () => async (dispatch) => {
  await dispatch(createUserLogoutBackend());
  await dispatch(resetAppModal());
  await dispatch({
    promise: ({ client, octaneClient }) => {
      client.clearJwtToken();
      octaneClient.clearJwtToken();
      destroyCookie(null, JWT_TOKEN.name);
      destroyCookie(null, REFRESH_TOKEN.name);
      destroyCookie(null, REFERRAL_USER.name, { path: '/' });
      destroyCookie(null, REFERRAL_ID.name, { path: '/' });
      window.Intercom?.('shutdown');
      dispatch(userDataClear());
      return Promise.resolve();
    },
  });
};

export const fetchBookedTours = (homeId) => ({
  promise: async ({ client }) => {
    try {
      const data = await client.get(`/homes/${homeId}/tours/booked`);
      return data;
    } catch (err) {
      return [];
    }
  },
});

export const fetchAccessInstructions = (tourId, serverObject = {}) => ({
  promise: async ({ client }) => {
    try {
      const data = await client.post(`/residents/tours/${tourId}/access-instructions`, serverObject);
      return [data, null];
    } catch (err) {
      const error = err.response.data;
      let code;
      if (Array.isArray(error)) {
        code = error[0]?.errorCode;
      }
      return [null, code || 'SERVER'];
    }
  },
});

export const validateUserInterestSurveyEntry = (interestUpdateToken) => ({
  promise: ({ client }) => client.head(`/residents/leads/interest/${interestUpdateToken}/valid`),
});

export const updateUserInterestSurvey = (interestUpdateToken, surveyInfo) => ({
  promise: ({ client }) => client.put(`/residents/leads/interest/${interestUpdateToken}`, surveyInfo),
});

export const fetchApplicationStatus = (interestUpdateToken) => ({
  promise: ({ client }) => client.get(`/residents/leads/interest/${interestUpdateToken}/application-status`),
});

export const updateTourAttendance = (interestUpdateToken, surveyInfo) => ({
  promise: ({ client }) => client.put(`/residents/leads/interest/${interestUpdateToken}/attendance`, surveyInfo),
});

export const fetchUserBookedTours = () => ({
  types: getApiActionTypesValues('FETCH_BOOKED_TOURS'),
  promise: ({ client }) => client.get('/residents/tours/booked'),
  auth: true,
});

export const fetchReports = () => ({
  types: getApiActionTypesValues('FETCH_REPORTS'),
  promise: ({ client }) => client.get('/homeowners/accounts/reports'),
  auth: true,
});

export const fetchUserSetPasswordStatus = (uniqueId, setToken = true) => ({
  promise: async ({ client }) => {
    const response = await client.get(`/users/login-status/${uniqueId}`);
    if (setToken && response.passwordSet === false) {
      client.setJwtToken(response?.token?.token);

      setCookie(null, JWT_TOKEN.name, response?.token?.token, JWT_TOKEN.options);
      setCookie(null, REFRESH_TOKEN.name, response?.token?.refreshToken, REFRESH_TOKEN.options);
    }
    return response;
  },
});

export const privateFileDownload = (fileType, ownerId) => ({
  promise: async ({ client }) => {
    let url;
    if (fileType === 'adverse-action-document') {
      const response = await client({
        method: 'get',
        url: `/residents/applications/${fileType}/${ownerId}`,
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response], { type: 'application/pdf' });
      url = URL.createObjectURL(blob);
    }
    return url;
  },
  auth: true,
});

export const fetchAllFlows = () => ({
  types: getApiActionTypesValues('FETCH_FLOWS'),
  promise: ({ client }) => client.get('/homeowners/flows'),
  auth: true,
});

export const getUserDevices = () => ({
  promise: ({ client }) => client.get('/users/devices'),
  auth: true,
});

export const sendTextMessage = (message) => ({
  promise: ({ client }) => client.post('/users/text-message', message),
  auth: true,
});

export const fetchUserAutoSplitPaymentMethod = () => ({
  types: getApiActionTypesValues('FETCH_USER_AUTO_SPLIT_PAYMENT_METHOD'),
  promise: ({ client }) => client.get('/users/payment-methods/auto-split'),
  auth: true,
});

export const updateUserAutoSplitPaymentMethod = (isEnabled) => async (dispatch) => {
  await dispatch({
    types: getApiActionTypesValues('UPDATE_USER_AUTO_SPLIT_PAYMENT_METHOD'),
    promise: ({ client }) =>
      client.put('/users/payment-methods/auto-split', {
        autoSplitEnabled: isEnabled,
      }),
    auth: true,
  });

  await Promise.all([dispatch(fetchUserAutoSplitPaymentMethod()), dispatch(fetchPaymentMethods())]);
};

export const fetchUserPaymentMethodConfigurations = () => ({
  types: getApiActionTypesValues('FETCH_USER_PAYMENT_METHOD_CONFIGURATIONS'),
  promise: ({ client }) => client.get('/users/payment-methods/payment-user'),
  auth: true,
});

export const getUserIncomeLink = () => ({
  types: getApiActionTypesValues('POST_USER_INCOME_LINK'),
  promise: ({ client }) => client.get('/platform/user-incomes'),
  auth: true,
});

export const createUserIncomeLink = (body) => ({
  types: getApiActionTypesValues('POST_USER_INCOME_LINK'),
  promise: ({ client }) => client.post('/platform/user-incomes', body),
  auth: true,
});

export const getAllowRetryIncome = (body) => ({
  types: getApiActionTypesValues('FETCH_ALLOW_RETRY_INCOME'),
  promise: ({ client }) => client.get('/platform/user-incomes/allow-retry-income', body),
  auth: true,
});

export const updateUserIncome = (userIncomeUniqueId, body) => ({
  types: getApiActionTypesValues('UPDATE_USER_INCOME'),
  promise: ({ client }) => client.put(`/platform/user-incomes/${userIncomeUniqueId}`, body),
  auth: true,
});

export const createPersonaInquiries = () => ({
  types: getApiActionTypesValues('CREATE_PERSONA_INQUIRY'),
  promise: ({ client }) => client.post('users/verifications/persona/inquiries'),
});

export const getPersonaInquiries = () => ({
  types: getApiActionTypesValues('CREATE_PERSONA_INQUIRY'),
  promise: ({ client }) => client.get('users/verifications/persona/inquiries'),
});

export const getPersonaSessionToken = (inquiryId) => ({
  types: getApiActionTypesValues('GET_PERSONA_TOKEN'),
  promise: ({ client }) => client.get(`users/verifications/persona/session-token/${inquiryId}`),
});
