import { ReactNode, useEffect } from 'react';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { useStepFormLayout } from 'accounts/hooks/homeowners/use-step-form-layout';
import { animationsCache } from 'animations/animationsCache';
import Form from 'components/Form/Form';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { UnitAddressTitle } from 'components/UnitAddressTitle/UnitAddressTitle';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { isEqual } from 'es-toolkit';
import arrayMutators from 'final-form-arrays';
import FormLayout from 'layouts/FormLayout/FormLayout';

type StepFormLayoutProps = {
  title: string;
  onSubmit: any;
  getForm?: any;
  preTitle?: ReactNode;
  subTitle?: string;
  initialValues?: Record<string, any>;
  mutators?: Record<string, any>;
  form?: ReactNode;
  customValidation?: any;
  ctaProps?: Record<string, any>;
  showUnitAddress?: boolean;
  bannerStep?: ReactNode;
  nextLabel?: string;
  doneLabel?: string;
  customError?: string;
  handleBackStep?: () => void;
  disableProgressBar?: boolean;
};

const { FormLayoutHeader } = FormLayout;

export function StepFormLayout({
  initialValues = {},
  mutators = {},
  subTitle = '',
  form = null,
  customValidation = null,
  ctaProps = {},
  preTitle,
  title,
  showUnitAddress = true,
  getForm,
  onSubmit,
  bannerStep,
  nextLabel = 'Next',
  doneLabel = 'Done',
  customError = null,
  disableProgressBar,
  handleBackStep,
}: StepFormLayoutProps) {
  const { isLastStep, units, selectedUnit, selectedProperty, loading, currentStep, propertyId, unitId, stepsByGate } =
    useSetupFlowStep();

  const { progressItems, loading: stepFormLayout } = useStepFormLayout({
    currentStep,
    propertyId,
    unitId,
    stepsByGate,
  });

  const unitAddress = `${selectedProperty?.address.streetAddress} Unit ${selectedUnit?.basicInfo.unitNumber}`;

  const items = progressItems?.map((progressItem) => ({
    ...progressItem,
    disabled: disableProgressBar,
  }));

  useEffect(() => {
    animationsCache.preload('lock_unlock');
  }, []);

  return (
    <FormLayout>
      <div className="mb-lg">
        {Boolean(!loading && !stepFormLayout && progressItems.length) && <ProgressBar items={items} />}
      </div>
      {bannerStep && <div className="mb-xl">{bannerStep}</div>}
      {showUnitAddress && units?.length > 1 && (
        <div className="mb-xs">
          <UnitAddressTitle address={unitAddress} />
        </div>
      )}
      <FormLayoutHeader title={title} subtitle={subTitle} pretitle={preTitle} />
      <FormLayout>
        {form || (
          <Form
            mutators={{
              ...arrayMutators,
              ...mutators,
            }}
            initialValuesEqual={isEqual}
            initialValues={initialValues}
            validate={customValidation}
            onSubmit={onSubmit}
            getForm={getForm}
            customError={customError}
            getFormBottomBar={(_formProps, nextButtonProps) => (
              <FormLayout.BottomBar
                ctaProps={{
                  label: isLastStep() ? doneLabel : nextLabel,
                  ...ctaProps,
                }}
                nextButtonWrapperProps={nextButtonProps}
                {...(handleBackStep && {
                  previousStepProps: {
                    onClick: handleBackStep,
                    to: null,
                  },
                })}
              />
            )}
          />
        )}
      </FormLayout>
      <Space value={SPACE_TYPES.XXXL} />
    </FormLayout>
  );
}
