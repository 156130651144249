import { useState } from 'react';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import { BUTTON_SIZES } from 'components/Button/buttonTypes';
import { FormContext } from 'components/Form/Form';
import Icon, { ICONS } from 'components/Icon/Icon';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import SelectorCard from 'components/v2/SelectorCard/SelectorCard';
import { startCase } from 'es-toolkit';
import { isEmpty } from 'es-toolkit/compat';
import { useModal } from 'hooks/useModal';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import SelectorCardLayout from 'layouts/SelectorCardLayout/SelectorCardLayout';
import PropTypes from 'prop-types';
import styles from './MultiStepFormSelector.module.css';

const cx = classNames.bind(styles);

const STATUS = {
  ERROR: 'error',
  COMPLETED: 'completed',
  ACTIVE: 'active',
};

const getIcon = (value) => ICONS[value?.toUpperCase()]?.DEFAULT;

const MultiStepFormSelector = ({ values, form, formComponent, formProps, showSkipButton, onSubmit }) => {
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const [targetStep, setTarget] = useState(0);

  const FormComponent = formComponent;
  const mappedValue = Object.keys(values).map((key) => ({
    ...values[key],
    name: key,
    status: isEmpty(values[key]) ? STATUS.ACTIVE : STATUS.COMPLETED,
  }));
  const currentTarget = mappedValue[targetStep] || {};

  const clearFormValues = () => {
    form.getState().values[currentTarget.name] = {};
  };

  const closeModal = () => {
    if (currentTarget.status !== STATUS.COMPLETED) clearFormValues();
    setModalClose();
  };

  const onCardSelect = (index) => {
    setTarget(index);
    setModalOpen();
  };

  const toNextStep = () => {
    onSubmit(form.getState().values);

    if (targetStep >= mappedValue.length - 1) setModalClose();
    else setTarget(targetStep + 1);
  };

  const skipStep = () => {
    clearFormValues();
    if (targetStep >= mappedValue.length - 1) {
      closeModal();
      return;
    }
    setTarget(targetStep + 1);
  };

  const getStatus = (value, showErrors) => {
    const { errors } = form.getState();
    const status = showErrors ? errors[value.name]?.error || value.status : value.status;
    return { [status]: true };
  };

  const bottomLeftNode = () =>
    showSkipButton && currentTarget.status !== STATUS.COMPLETED ? (
      <Button className={cx('mobile-cta')} buttonType={BUTTON_TYPES.TEXT} onClick={skipStep}>
        SKIP FOR NOW
      </Button>
    ) : (
      <div />
    );

  const nextButtonLabel = () => {
    const nextTarget = mappedValue[targetStep + 1];
    return nextTarget ? 'Save & Next' : 'Save';
  };

  return (
    <FormContext.Consumer>
      {(showErrors) => {
        return (
          <>
            <div className={cx('selector-row-container')}>
              {mappedValue.map((value, index) => {
                return (
                  <div className={cx('selector-card-container')} key={index}>
                    <SelectorCard
                      mobileOrientation="horizontal"
                      onClick={() => {
                        onCardSelect(index);
                      }}
                      ignoreBoxShadow
                      {...getStatus(value, showErrors)}
                    >
                      <SelectorCardLayout
                        icon={<Icon icon={getIcon(value.name)} />}
                        label={startCase(value.name)}
                        mobileOrientation="vertical"
                      />
                    </SelectorCard>
                  </div>
                );
              })}
            </div>
            <ModalV2 show={isModalOpen} onHide={closeModal} closeButton noPadding>
              <ModalLayout>
                <ModalLayout.Title>
                  <Icon icon={getIcon(currentTarget.name)} />
                  <div>{startCase(currentTarget.name ?? '')}</div>
                </ModalLayout.Title>
                <ModalLayout.Content>
                  <FormComponent
                    ignoreError
                    form={form}
                    {...formProps}
                    {...currentTarget}
                    toNextStep={toNextStep}
                    skipStep={skipStep}
                    bottomLeftNode={bottomLeftNode()}
                    CustomBottomBar={ModalLayout.BottomBar}
                    ctaProps={{
                      label: nextButtonLabel(),
                      size: BUTTON_SIZES.DEFAULT,
                      onClick: toNextStep,
                    }}
                  />
                </ModalLayout.Content>
              </ModalLayout>
            </ModalV2>
          </>
        );
      }}
    </FormContext.Consumer>
  );
};

MultiStepFormSelector.propTypes = {
  values: PropTypes.array.isRequired,
  formComponent: PropTypes.func.isRequired,
  form: PropTypes.node.isRequired,
  formProps: PropTypes.object,
  ctaProps: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  showSkipButton: PropTypes.bool,
};

MultiStepFormSelector.defaultProps = {
  formProps: {},
  ctaProps: null,
  showSkipButton: true,
};

export default MultiStepFormSelector;
