import { capitalize } from 'es-toolkit';
import { lowerCase } from 'es-toolkit/compat';
import Address from 'models/common/Address';
import OnboardingInfo from 'models/common/OnboardingInfo';
import UnitDetailBasicInfo from 'models/common/UnitDetailBasicInfo';
import { pluralize } from '../../utils/pluralize';

export const getBathRoomString = (basicInfo) => {
  let totalBathCount;
  if (basicInfo.halfBathroomCount) {
    totalBathCount = basicInfo.fullBathroomCount + 0.5 * basicInfo.halfBathroomCount;
  } else {
    totalBathCount = basicInfo.fullBathroomCount;
  }

  if (!totalBathCount) {
    return pluralize(0, 'Bath');
  }

  if (basicInfo.fullBathroomCount && basicInfo.halfBathroomCount === 1) {
    return `${basicInfo.fullBathroomCount}.5 Bath`;
  }

  const bathString = `${basicInfo.fullBathroomCount} ${
    basicInfo.halfBathroomCount ? `${basicInfo.fullBathroomCount ? ' + ' : ' '}${basicInfo.halfBathroomCount} ½` : ''
  } Bath`;

  return bathString;
};

export const getFormattedBeds = (unitBasic, showUnitLevel = false) => {
  const { bedroomCount, detailBasic } = unitBasic;
  const { unitType, unitLevel } = detailBasic || {};

  let formattedBeds = '';

  if (bedroomCount) {
    formattedBeds += `${unitBasic.bedroomCount} Bed`;
  }

  if (unitLevel && showUnitLevel && unitLevel !== 'None') {
    formattedBeds += formattedBeds ? ` ${lowerCase(unitLevel)}` : capitalize(unitLevel ?? '');
  }

  if (unitType && unitType !== 'None') {
    formattedBeds += formattedBeds ? ` ${lowerCase(unitType)}` : capitalize(unitType ?? '');
  } else if (!bedroomCount) {
    formattedBeds += formattedBeds ? ' studio' : 'Studio';
  }

  return formattedBeds;
};

export default class UnitBasicInfo {
  constructor(data = {}) {
    this.id = data.id;
    this.unitId = data.unitId;
    this.unitNumber = data.unitNumber;
    this.areaInSquareFeet = data.areaInSquareFeet;
    this.bedroomCount = data.bedroomCount;
    this.fullBathroomCount = data.fullBathroomCount;
    this.halfBathroomCount = data.halfBathroomCount || 0;
    this.onboardingInfo = new OnboardingInfo(data.onboardingInfo);
    this.status = data.status;
    this.managedSince = data.managedSince;
    this.address = new Address(data.address);
    this.bannerImageThumbnailUrl = data.bannerImageThumbnailUrl;
    this.bannerImageUrl = data.bannerImageUrl;
    this.mediaAvailable = data.mediaAvailable;
    this.isMediaReady = data.isMediaReady;
    this.isPublished = data.isPublished;
    this.detailBasic = new UnitDetailBasicInfo(data.detailBasic);
    this.zillowListingId = data.zillowListingId;
    this.includedUtilities = data.includedUtilities;
    this.isInspectionCompleted = data.isInspectionCompleted;
    this.isOnboardingComplete = data.isOnboardingComplete;
    this.currentMilestone = data.currentMilestone;
    this.onboardingCompletedOn = data.onboardingCompletedOn;
    this.publishedOn = data.publishedOn;
    this.timeZone = data.timeZone;

    /* Processed */
    this.totalBathCount = this.fullBathroomCount + 0.5 * this.halfBathroomCount;
    this.formattedBeds = getFormattedBeds(this);
  }
}
