import React from 'react';
import { Text } from '@belong/ui';
import { MaintenanceModel, MaintenancePaymentModel, MaintenancePriceRangeAggregateModel } from 'api/models';
import classNames from 'classnames/bind';
import Money from 'components/Money/Money';
import { MAINTENANCE_TASK_MOBILE_PROPS } from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceProps';
import { isNil } from 'es-toolkit';
import { isNumber } from 'es-toolkit/compat';
import styles from './MaintenanceTaskCost.module.css';

const cx = classNames.bind(styles);

type MaintenanceTaskCostProps = {
  estimatedCost: '' | 'Pricing TBD' | MaintenancePaymentModel['estimatedAdjustmentApplied'];
  actualCost: '' | 'Free' | MaintenancePaymentModel['actualAdjustmentApplied'];
  mobileDisplay?: string;
  maintenanceTaskStatus: string;
  priceRange?: MaintenancePriceRangeAggregateModel;
  numberOfOptions?: number;
  requestCategory?: MaintenanceModel['requestCategory'];
  requestType?: MaintenanceModel['requestType'];
};

const useMaintenanceCost = (
  estimatedCost: MaintenanceTaskCostProps['estimatedCost'],
  actualCost: MaintenanceTaskCostProps['actualCost'],
  maintenanceTaskStatus: string,
  requestCategory?: MaintenanceModel['requestCategory'],
  requestType?: MaintenanceModel['requestType'],
  priceRange?: MaintenancePriceRangeAggregateModel,
  numberOfOptions?: number
) => {
  const CostTBD = (
    <span>
      Pricing: <span className="font-semibold mr-xs">TBD</span>
    </span>
  );

  if (actualCost === 'Free') {
    return 'Free';
  }

  if (isNumber(actualCost) && actualCost > 0) {
    return (
      <div>
        Pricing: <Money cash={actualCost} />
      </div>
    );
  }

  if (requestCategory === 'InHomeRepairs' && requestType === 'Group' && priceRange) {
    let groupInHomeRepairsCost = CostTBD;
    if (priceRange.upperBoundTotal > 0) {
      if (priceRange.lowerBoundTotal < priceRange.upperBoundTotal) {
        groupInHomeRepairsCost = (
          <span>
            Est.{' '}
            <span className="font-semibold mr-xs">
              <Money cash={priceRange.lowerBoundTotal} /> - <Money cash={priceRange.upperBoundTotal} />
            </span>
          </span>
        );
      } else {
        groupInHomeRepairsCost = (
          <span>
            Est.{' '}
            <span className="font-semibold mr-xs">
              <Money cash={priceRange.lowerBoundTotal} />
            </span>
          </span>
        );
      }
    }

    return (
      <div>
        {groupInHomeRepairsCost}
        {numberOfOptions} Options Available
      </div>
    );
  }

  if (!isNil(estimatedCost)) {
    if (isNumber(estimatedCost) && estimatedCost > 0) {
      return (
        <div>
          Est.{' '}
          <span className="font-semibold mr-xs">
            <Money cash={estimatedCost} />
          </span>
        </div>
      );
    }

    if (maintenanceTaskStatus === 'Closed') return '';
  }

  return CostTBD;
};

const MaintenanceTaskCost = ({
  estimatedCost,
  actualCost = null,
  mobileDisplay = MAINTENANCE_TASK_MOBILE_PROPS.NO_MOBILE,
  maintenanceTaskStatus,
  priceRange,
  numberOfOptions,
  requestCategory,
  requestType,
}: MaintenanceTaskCostProps) => {
  const cost: React.ReactNode = useMaintenanceCost(
    estimatedCost,
    actualCost,
    maintenanceTaskStatus,
    requestCategory,
    requestType,
    priceRange,
    numberOfOptions
  );

  return (
    <div className={cx('maintenanceTaskCost', mobileDisplay)}>
      <Text variant="p1">{cost}</Text>
    </div>
  );
};

export default MaintenanceTaskCost;
