import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { DATE_FORMATS, formatDateTime } from '@belong/common';
import { CurrentResidentFields } from 'accounts/components/form-fields/current-resident';
import { NextUnitBannerStep } from 'accounts/components/next-unit-banner-step';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import {
  ONBOARDING_LEASE_FILES_OPTIONS,
  RENTER_OCCUPIED_MOVEOUT_OPTIONS,
  MOVEOUT_PLANS,
} from 'accounts/constants/home-occupancy';
import { HOME_OCCUPANCY_STRINGS } from 'accounts/constants/strings/home-occupancy.strings';
import { useHomeOccupancy } from 'accounts/hooks/homeowners/use-home-occupancy';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import { DateInputFinalFormAdapter } from 'components/DateInput/DateInput';
import Field from 'components/Field/Field';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskCurrency, maskMMDDYY, unmaskMMDDYY, unmaskNumber } from 'components/Input/masks';
import { MEDIA_LABEL, NewMediaUploadAdapter, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { Box, Text } from 'design-system';
import { isNil } from 'es-toolkit';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import StandardFieldArray from 'formcomponents/StandardFieldArray/StandardFieldArray';
import { InputCheckboxAdapter } from 'formcomponents/checkbox-input';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { IMAGE_FILE_TYPE } from 'models/enums/index';
import { uploadFiles } from 'store/redux/images/actions';
import { formatString } from 'strings';
import { parseDateTimeInputStringMMDDYY } from 'utils/dateTimeUtils';
import { required, composeValidators, dateValidationMMDDYY } from 'utils/validation';

export function HomeOccupancy() {
  const dispatch = useDispatch();
  const { currentStep, selectedProperty, selectedUnit, handleSaveAndNext, setLoading } = useSetupFlowStep();
  const { initialValues } = useHomeOccupancy();
  const { search } = useLocation();

  const { onboardingInfo } = currentStep?.unit?.basicInfo || {};
  const { currentLeaseEndOn, isLeaseMonthToMonth } = onboardingInfo || {};
  const renterOccupiedMoveoutSectionTitle = isLeaseMonthToMonth
    ? HOME_OCCUPANCY_STRINGS['last_time_we_checked.month_to_month']
    : formatString(HOME_OCCUPANCY_STRINGS['last_time_we_checked.specific_date'], {
        endsOrEnded: new Date(currentLeaseEndOn) < new Date() ? 'ended' : 'ends',
        date: formatDateTime({ dateTime: currentLeaseEndOn, format: DATE_FORMATS.DOTTED_MEDIUM }),
      });
  const name = 'unitOnboardingInfo';

  async function handleSubmit(values) {
    const valuesToModify = structuredClone(values);
    const { unitOnboardingInfo, onboardingLeaseFiles } = valuesToModify;

    setLoading(true);

    // needs to overwrite Lease instead
    try {
      const leaseFiles = await dispatch(uploadFiles(IMAGE_FILE_TYPE.OnboardingLease, onboardingLeaseFiles));
      valuesToModify.onboardingLeaseFiles = leaseFiles;
    } catch (err) {
      console.error(err);
    }

    const newItems = unitOnboardingInfo.existingResidents.map((resident) => {
      const newObj = { ...resident };

      newObj.email = resident?.email?.inputValue ? resident.email.inputValue : '';
      newObj.phone = resident?.phone?.inputValue ? resident.phone.inputValue : '';

      return newObj;
    });

    unitOnboardingInfo.existingResidents = newItems;
    if (valuesToModify.unitOnboardingInfo.currentLeaseEndOn?.inputValue) {
      valuesToModify.unitOnboardingInfo.currentLeaseEndOn =
        valuesToModify.unitOnboardingInfo.currentLeaseEndOn.inputValue;
    } else if (!isNil(valuesToModify.unitOnboardingInfo.currentLeaseEndOn?.checkboxValue)) {
      delete valuesToModify.unitOnboardingInfo.currentLeaseEndOn;
      valuesToModify.unitOnboardingInfo.isLeaseMonthToMonth = true;
    }

    if (valuesToModify.unitOnboardingInfo?.moveoutOn) {
      valuesToModify.unitOnboardingInfo.moveoutOn = parseDateTimeInputStringMMDDYY(
        valuesToModify.unitOnboardingInfo.moveoutOn
      );
    }

    await handleSaveAndNext(valuesToModify);
  }

  function getBannerStep() {
    if (search !== '') {
      const unitAddress = `${selectedProperty?.address.streetAddress} Unit ${selectedUnit?.basicInfo.unitNumber}`;

      return <NextUnitBannerStep unitAddress={unitAddress} />;
    }

    return undefined;
  }

  return (
    <StepFormLayout
      onSubmit={handleSubmit}
      title={HOME_OCCUPANCY_STRINGS.title}
      initialValues={initialValues}
      bannerStep={getBannerStep()}
      getForm={({ form }) => (
        <Fragment>
          <FormLayout.Section firstSection sectionTitle={renterOccupiedMoveoutSectionTitle}>
            <Field
              name={`${name}.renterOccupiedMoveoutOption`}
              component={SelectorFinalFormAdapter}
              validate={required}
              buttons={RENTER_OCCUPIED_MOVEOUT_OPTIONS}
              type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
            />
          </FormLayout.Section>
          <Condition when={`${name}.renterOccupiedMoveoutOption`} is={MOVEOUT_PLANS.Correct}>
            <FormLayout.Section sectionTitle={HOME_OCCUPANCY_STRINGS.subtitle}>
              <StandardFieldArray
                fieldArrayName={`${name}.existingResidents`}
                mutators={form.mutators}
                FieldsComponent={CurrentResidentFields}
                values={form.getState().values}
                form={form}
              />
            </FormLayout.Section>
            <FormLayout.Section
              sectionTitle={HOME_OCCUPANCY_STRINGS['lease_file.title']}
              sectionSubTitle={HOME_OCCUPANCY_STRINGS['lease_file.subtitle']}
            >
              <Field
                name="onboardingLeaseFiles.later"
                component={SelectorFinalFormAdapter}
                validate={required}
                buttons={ONBOARDING_LEASE_FILES_OPTIONS}
                type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
              />
            </FormLayout.Section>
            <Condition when="onboardingLeaseFiles.later" is={false}>
              <Field
                name="onboardingLeaseFiles"
                validate={validateMedia}
                component={NewMediaUploadAdapter}
                mediaLabel={MEDIA_LABEL.LEASE}
                disableWebcam
              />
            </Condition>
          </Condition>
          <Condition when={`${name}.renterOccupiedMoveoutOption`} is={MOVEOUT_PLANS.NotCorrect}>
            <FormLayout.Section sectionTitle={HOME_OCCUPANCY_STRINGS.plans_changed}>
              <Row>
                <Col xs={12} sm={6}>
                  <Field
                    component={DateInputFinalFormAdapter}
                    mask={maskMMDDYY}
                    name={`${name}.moveinOn`}
                    placeholder={HOME_OCCUPANCY_STRINGS.move_in_date}
                    unmask={unmaskMMDDYY}
                    validate={composeValidators(required, dateValidationMMDDYY)}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    component={InputFinalFormAdapter}
                    mask={maskCurrency}
                    name={`${name}.currentRentAmount`}
                    placeholder={HOME_OCCUPANCY_STRINGS['current_rent.placeholder']}
                    unmask={unmaskNumber}
                    validate={required}
                  />
                </Col>
                <Col xs={12}>
                  <Field
                    checkboxProps={{
                      label: HOME_OCCUPANCY_STRINGS.month_to_month,
                    }}
                    component={InputCheckboxAdapter}
                    form={form}
                    inputProps={{
                      component: DateInputFinalFormAdapter,
                      placeholder: HOME_OCCUPANCY_STRINGS['lease_ending_MMYY.placeholder'],
                      validate: composeValidators(required, dateValidationMMDDYY),
                    }}
                    name={`${name}.currentLeaseEndOn`}
                  />
                </Col>
              </Row>
            </FormLayout.Section>
            <FormLayout.Section sectionTitle={HOME_OCCUPANCY_STRINGS.subtitle}>
              <StandardFieldArray
                fieldArrayName={`${name}.existingResidents`}
                mutators={form.mutators}
                FieldsComponent={CurrentResidentFields}
                values={form.getState().values}
                form={form}
              />
            </FormLayout.Section>
            <FormLayout.Section
              sectionTitle={HOME_OCCUPANCY_STRINGS['lease_file.title']}
              sectionSubTitle={HOME_OCCUPANCY_STRINGS['lease_file.subtitle']}
              separatorType="normal"
              large="normal"
            >
              <Field
                name="onboardingLeaseFiles.later"
                component={SelectorFinalFormAdapter}
                validate={required}
                buttons={ONBOARDING_LEASE_FILES_OPTIONS}
                type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
              />
            </FormLayout.Section>
            <Condition when="onboardingLeaseFiles.later" is={false}>
              <Field
                name="onboardingLeaseFiles"
                validate={validateMedia}
                component={NewMediaUploadAdapter}
                mediaLabel={MEDIA_LABEL.LEASE}
                disableWebcam
              />
            </Condition>
          </Condition>
          <Condition when={`${name}.renterOccupiedMoveoutOption`} is={MOVEOUT_PLANS.NoticeGiven}>
            <FormLayout.Section sectionTitle={HOME_OCCUPANCY_STRINGS.gave_notice}>
              <Row>
                <Col xs={12} sm={6}>
                  <Field
                    component={DateInputFinalFormAdapter}
                    name={`${name}.moveoutOn`}
                    placeholder="Move-Out Date (MM/DD/YY)"
                    validate={composeValidators(required, dateValidationMMDDYY)}
                  />
                </Col>
              </Row>
            </FormLayout.Section>
          </Condition>
          <Condition when={`${name}.renterOccupiedMoveoutOption`} is={MOVEOUT_PLANS.AlreadyMovedOut}>
            <Box mb="xl">
              <GreenInfoBox>
                <Text>{HOME_OCCUPANCY_STRINGS['already_moved_out.title']}</Text>
              </GreenInfoBox>
            </Box>
            <FormLayout.Section firstSection sectionTitle={HOME_OCCUPANCY_STRINGS['already_moved_out.subtitle']}>
              <Row>
                <Col xs={12} sm={6}>
                  <Field
                    component={DateInputFinalFormAdapter}
                    name={`${name}.moveoutOn`}
                    placeholder="Move-Out Date (MM/DD/YY)"
                    validate={composeValidators(required, dateValidationMMDDYY)}
                  />
                </Col>
              </Row>
            </FormLayout.Section>
          </Condition>
        </Fragment>
      )}
    />
  );
}
