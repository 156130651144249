import { useState } from 'react';
import { connect } from 'react-redux';
import { IconSplitCard } from '@belong/icons';
import { ButtonBase } from '@belong/ui';
import cx from 'classnames';
import { find, filter } from 'es-toolkit/compat';
import PropTypes from 'prop-types';
import DottedMenu from '../../components/DottedMenu/DottedMenu';
import GeneralIcon, { GENERAL_ICONS, COLORS } from '../../components/GeneralIcon/GeneralIcon';
import PaymentMethodDisplay from '../../components/Payments/PaymentMethodDisplay/PaymentMethodDisplay';
import { MODALS } from '../../containercomponents/Modals/modal.consts';
import FullUser from '../../models/composed/FullUser';
import { PaymentMethod, PaymentPreference } from '../../models/enums';
import { showModal, hideModal } from '../../store/redux/modals/actions';
import { selectFullUser } from '../../store/redux/user/selectors';

const PAYMENT_MENU_ACTIONS = {
  VERIFY: 'verify',
  ADD_PAYMENT: 'add_payment',
  AUTO_SPLIT: 'auto_split',
  AUTO_SPLIT_MODAL: 'auto_split_modal',
};

function PaymentMenuSelectionContainer(props) {
  const [showMenu, setShowMenu] = useState(true);
  const { hasNoPaymentMethod, fullUser, forcePaymentMethod, onSelectPaymentMethod, ...rest } = props;

  const handleSetUserAutoSplit = () => {
    const { onSelectAutoSplitPaymentMethod } = props;
    onSelectAutoSplitPaymentMethod();
  };

  const handleAddPaymentMethod = () => {
    const { showModal: showModalAction, paymentModalProps, shouldShowAutoSplitPaymentMethod } = props;

    showModalAction(MODALS.PAYMENT_MODAL, {
      ...paymentModalProps,
      onPaymentSelection: onSelectPaymentMethod,
      fullUser,
      onAutoSplitPaymentMethodSelect: handleSetUserAutoSplit,
      shouldShowAutoSplitPaymentMethod,
    });
  };

  const getMenuActions = () => {
    const actions = [
      {
        name: 'ADD PAYMENT METHOD',
        key: PAYMENT_MENU_ACTIONS.ADD_PAYMENT,
        icon: <GeneralIcon icon={GENERAL_ICONS.ADD_CARD.DEFAULT} />,
        iconSelected: <GeneralIcon icon={GENERAL_ICONS.ADD_CARD.INVERSE} />,
      },
    ];
    return actions;
  };

  const handleVerifyPaymentMethod = () => {
    const { selectedPaymentMethodId, showModal: showModalAction } = props;
    const paymentMethod = find(fullUser.paymentMethods.paymentMethods, { accountId: selectedPaymentMethodId });

    showModalAction(MODALS.PAYMENT_VERIFY_MODAL, {
      paymentMethod,
    });
  };

  const handleInfoModal = () => {
    const { showModal: showModalAction } = props;
    setShowMenu(false);
    showModalAction(MODALS.SPLIT_IT_LEARN_MORE, {
      setShowMenu,
    });
  };

  const handleMenuActionClick = (key) => {
    if (key === PAYMENT_MENU_ACTIONS.AUTO_SPLIT_MODAL) {
      handleInfoModal();
    } else if (key === PAYMENT_MENU_ACTIONS.ADD_PAYMENT) {
      handleAddPaymentMethod();
    } else if (key === PAYMENT_MENU_ACTIONS.VERIFY) {
      handleVerifyPaymentMethod();
    } else if (key === PAYMENT_MENU_ACTIONS.AUTO_SPLIT) {
      handleSetUserAutoSplit();
    }
  };

  const getTopMenuActions = () => {
    const { shouldShowAutoSplitPaymentMethod } = props;
    if (shouldShowAutoSplitPaymentMethod) {
      return [
        {
          name: 'SPLIT IT',
          key: PAYMENT_MENU_ACTIONS.AUTO_SPLIT,
          text: 'Interest Applied • Deducted From Rent',
          icon: <IconSplitCard width={46} height={30} />,
          children: (
            <ButtonBase
              className={cx('mt-sm', 'text-green hover:opacity-75 z-10')}
              onClick={(e) => {
                handleMenuActionClick(PAYMENT_MENU_ACTIONS.AUTO_SPLIT_MODAL);
                e.stopPropagation();
              }}
            >
              HOW IT WORKS
            </ButtonBase>
          ),
        },
      ];
    }
    return [];
  };

  const getMenuItems = () => {
    const { selectedPaymentMethodId, ignorePaymentMethodId, showOnlyValidPaymentMethods, paymentType } = props || {};

    return filter(
      fullUser.getPaymentMethods(forcePaymentMethod, showOnlyValidPaymentMethods),
      (paymentMethod) => paymentMethod?.accountId !== ignorePaymentMethodId
    )?.map((paymentMethod) => {
      const isCurrentPaymentMethod = paymentMethod?.accountId === selectedPaymentMethodId;

      return {
        name: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: 'calc(100% - 2rem)' }}>
              <PaymentMethodDisplay
                hasFee={!(paymentType === PaymentPreference.Payout)}
                paymentMethod={paymentMethod}
                paymentMethodType={paymentMethod?.getAccountType()}
              />
            </div>
            {isCurrentPaymentMethod && <GeneralIcon icon={GENERAL_ICONS.CHECKMARK} color={COLORS.GREEN} />}
          </div>
        ),
        paymentMethod,
        key: paymentMethod?.accountId,
      };
    });
  };

  return (
    <DottedMenu
      hasNoPaymentMethod={hasNoPaymentMethod}
      menuItems={getMenuItems()}
      onMenuClick={onSelectPaymentMethod}
      menuActions={getMenuActions()}
      topMenuActions={getTopMenuActions()}
      onMenuActionClick={handleMenuActionClick}
      onTop
      manuallyShowMenu={showMenu}
      {...rest}
    />
  );
}

PaymentMenuSelectionContainer.propTypes = {
  fullUser: PropTypes.instanceOf(FullUser).isRequired,
  forcePaymentMethod: PropTypes.oneOf(Object.values(PaymentMethod)),
  selectedPaymentMethodId: PropTypes.string.isRequired,
  onSelectPaymentMethod: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hasNoPaymentMethod: PropTypes.bool,
  paymentType: PropTypes.oneOf(Object.values(PaymentPreference)).isRequired,
  showOnlyValidPaymentMethods: PropTypes.bool,
  paymentModalProps: PropTypes.object.isRequired,
  ignorePaymentMethodId: PropTypes.bool.isRequired,
  onSelectAutoSplitPaymentMethod: PropTypes.func.isRequired,
  shouldShowAutoSplitPaymentMethod: PropTypes.bool,
  isAutoSplitEnabled: PropTypes.bool,
  hideModal: PropTypes.func,
};

PaymentMenuSelectionContainer.defaultProps = {
  forcePaymentMethod: null,
  showOnlyValidPaymentMethods: false,
  hasNoPaymentMethod: false,
};

export default connect(
  (state) => ({
    fullUser: selectFullUser(state),
  }),
  {
    showModal,
    hideModal,
  }
)(PaymentMenuSelectionContainer);
