import { Checkbox } from '@belong/ui';
import { isNumber } from 'es-toolkit/compat';
import { useField } from 'formik';
import { maintenanceAccountResponsibilityStrings } from './maintenance-account-responsibility.constants';
import { ResponsibilityResult } from './maintenance-account-responsibility.form';

export const ResponsibilityField = ({
  name,
  validate,
  showSharedExpenseOption,
  onChange,
  taskOptionNumber,
}: {
  name: string;
  validate?: (value: string) => undefined | string | Promise<string>;
  showSharedExpenseOption?: boolean;
  onChange?: (value: ResponsibilityResult) => void;
  taskOptionNumber?: number;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField<ResponsibilityResult>({ name, validate });

  const handleChange = (value: ResponsibilityResult) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <div className="flex flex-col gap-sm md-xs">
      <Checkbox
        {...field}
        id="accepted"
        checked={field.value === 'approved'}
        variant="tertiary"
        label={
          isNumber(taskOptionNumber)
            ? `${maintenanceAccountResponsibilityStrings.consentAccept} WITH OPTION ${taskOptionNumber}`
            : `${maintenanceAccountResponsibilityStrings.consentAccept} WITH THE WORK.`
        }
        onChange={() => handleChange('approved')}
      />
      <Checkbox
        {...field}
        id="rejected"
        checked={field.value === 'rejected'}
        variant="tertiary"
        label={maintenanceAccountResponsibilityStrings.consentReject}
        onChange={() => handleChange('rejected')}
      />
      {showSharedExpenseOption && (
        <Checkbox
          {...field}
          id="shared"
          checked={field.value === 'shared'}
          variant="tertiary"
          label={maintenanceAccountResponsibilityStrings.consentShare}
          onChange={() => handleChange('shared')}
        />
      )}
    </div>
  );
};
