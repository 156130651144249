import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ModalLayoutErrorScreen from 'layouts/ModalLayout/ModalLayoutErrorScreen/ModalLayoutErrorScreen';
import ModalLayoutSuccessScreen from 'layouts/ModalLayout/ModalLayoutSuccessScreen/ModalLayoutSuccessScreen';
import FullUser from 'models/composed/FullUser';
import { PaymentMethod } from 'models/enums/index';
import PropTypes from 'prop-types';
import { fetchPaymentMethods, fetchUser, updatePaymentMethod } from 'store/redux/user/actions';
import { selectFullUser, selectIsUserLoggedIn } from 'store/redux/user/selectors';
import { FEEDBACK_STRINGS } from 'strings/errors.string';
import CreateAchAccount from '../../PaymentMethods/CreateAchAccount/CreateAchAccount';
import NewPaymentTypeSelector from '../../PaymentMethods/NewPaymentTypeSelector/NewPaymentTypeSelector';
import AchManualScreen from './AchManualScreen/AchManualScreen';
import AddCreditCardScreen from './AddCreditCardScreen/AddCreditCardScreen';

export const PAYMENT_SCREENS = {
  PAYMENT_SELECTION: 'PAYMENT_SELECTION',
  PAYMENT_SELECTION_FAIL: 'PAYMENT_SELECTION_FAIL',
  PAYMENT_SELECTION_SUCCESS: 'PAYMENT_SELECTION_SUCCESS',
  ADD_ACH_INSTANT: 'ADD_ACH_INSTANT',
  ADD_ACH_INSTANT_FAIL: 'ADD_ACH_INSTANT_FAIL',
  ADD_CREDIT_CARD: 'ADD_CREDIT_CARD',
  ADD_ACH_MANUAL: 'ADD_ACH_MANUAL',
};

class PaymentsModal extends Component {
  static propTypes = {
    fullUser: PropTypes.instanceOf(FullUser).isRequired,
    fetchPaymentMethods: PropTypes.func.isRequired,
    updatePaymentMethod: PropTypes.func.isRequired,
    onPaymentSelection: PropTypes.func,
    // Value of what?
    screen: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    forcePaymentMethod: PropTypes.string,
    ctaLabel: PropTypes.node,
    errorScreenProps: PropTypes.node,
    successScreenProps: PropTypes.node,
    requireConfirmation: PropTypes.bool,
    redirectToAccounts: PropTypes.bool,
    userLoggedIn: PropTypes.bool,
    showSuccessScreen: PropTypes.func,
    savePayTemp: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    shouldShowAutoSplitPaymentMethod: PropTypes.bool,
    onAutoSplitPaymentMethodSelect: PropTypes.func,
  };

  static defaultProps = {
    screen: null,
    onPaymentSelection: () => {},
    forcePaymentMethod: null,
    ctaLabel: null,
    errorScreenProps: null,
    successScreenProps: null,
    requireConfirmation: false,
    redirectToAccounts: false,
    showSuccessScreen: null,
    savePayTemp: false,
    shouldShowAutoSplitPaymentMethod: false,
    onAutoSplitPaymentMethodSelect: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      screen: props.screen,
      selectedPaymentMethod: null,
      showSpinner: false,
      showAchSpinner: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.screen && nextProps.screen) {
      return {
        ...prevState,
        screen: nextProps.screen,
      };
    }

    return {
      ...prevState,
    };
  }

  async componentDidMount() {
    const { userLoggedIn, fullUser, fetchPaymentMethods: fetchPaymentMethodsAction } = this.props;

    if (userLoggedIn && !fullUser.paymentMethods) {
      this.showSpinner();
      try {
        await fetchPaymentMethodsAction();
      } catch (err) {
        console.error(err);
      }
      this.hideSpinner();
    }
  }

  showSpinner = () => {
    this.setState({ showSpinner: true });
  };

  showAchSpinner = () => {
    this.setState({ showAchSpinner: true });
  };

  hideSpinner = () => {
    this.setState({ showSpinner: false });
  };

  hideAchSpinner = () => {
    this.setState({ showAchSpinner: false });
  };

  resetModal = () => {
    this.setState({
      screen: PAYMENT_SCREENS.PAYMENT_SELECTION,
      selectedPaymentMethod: null,
      showSpinner: false,
    });
  };

  closeModal = () => {
    const { onHide } = this.props;
    this.resetModal();
    onHide();
  };

  handleCloseSuccessModal = () => {
    const { onHide } = this.props;
    this.resetModal();
    onHide();
  };

  handleHide = () => {
    const { onHide } = this.props;
    const { screen } = this.state;

    if (
      screen === PAYMENT_SCREENS.ADD_CREDIT_CARD ||
      screen === PAYMENT_SCREENS.ADD_ACH_MANUAL ||
      screen === PAYMENT_SCREENS.ADD_ACH_INSTANT ||
      screen === PAYMENT_SCREENS.ADD_ACH_INSTANT_FAIL ||
      screen === PAYMENT_SCREENS.PAYMENT_SELECTION_FAIL
    ) {
      this.setState({
        screen: PAYMENT_SCREENS.PAYMENT_SELECTION,
      });
    } else {
      this.resetModal();
      onHide();
    }
  };

  handleNewAccount = async (accountId, paymentMethod) => {
    this.setState({
      screen: PAYMENT_SCREENS.PAYMENT_SELECTION,
    });
    try {
      await this.handlePaymentSelection(accountId, paymentMethod);
    } catch (err) {
      console.error(err);
    }
  };

  handleAddInstantAchExit = () => {
    this.setState({
      showSpinner: false,
      selectedPaymentMethod: null,
      screen: PAYMENT_SCREENS.PAYMENT_SELECTION,
    });
  };

  handleBankNotFound = () => {
    this.setState({
      showSpinner: false,
      screen: PAYMENT_SCREENS.ADD_ACH_MANUAL,
    });
  };

  handleAddCreditCardPayment = () => {
    this.setState({
      screen: PAYMENT_SCREENS.ADD_CREDIT_CARD,
    });
  };

  handleAddInstantAchPaymentFail = () => {
    this.setState({
      showSpinner: false,
      screen: PAYMENT_SCREENS.ADD_ACH_INSTANT_FAIL,
    });
  };

  handlePaymentSelectionFail = (accountId) => {
    this.setState({
      showSpinner: false,
      screen: PAYMENT_SCREENS.PAYMENT_SELECTION_FAIL,
      accountId,
    });
  };

  handlePaymentSelectionSuccess = () => {
    const { showSuccessScreen } = this.props;

    if (!showSuccessScreen) {
      return this.handleCloseSuccessModal();
    }

    this.setState({
      showSpinner: false,
      screen: PAYMENT_SCREENS.PAYMENT_SELECTION_SUCCESS,
    });
  };

  handlePaymentSelection = async (accountId, paymentMethod) => {
    const { onPaymentSelection } = this.props;
    const selectedAccountId = accountId || this.state.accountId;
    this.showSpinner();

    try {
      await onPaymentSelection(selectedAccountId, paymentMethod);
      this.handlePaymentSelectionSuccess();
    } catch (error) {
      this.handlePaymentSelectionFail(selectedAccountId);
    }
  };

  handleAddInstantAchPayment = () => {
    this.setState({
      screen: PAYMENT_SCREENS.ADD_ACH_INSTANT,
      selectedPaymentMethod: PaymentMethod.Ach,
    });
  };

  handleAddManualAchPayment = () => {
    this.setState({
      screen: PAYMENT_SCREENS.ADD_ACH_MANUAL,
      selectedPaymentMethod: PaymentMethod.Ach,
    });
  };

  handleCompleteAchPayment = () => {
    this.setState({ screen: PAYMENT_SCREENS.PAYMENT_SELECTION });
  };

  handleAutoSplitPaymentMethodSelection = async () => {
    const { onAutoSplitPaymentMethodSelect } = this.props;
    this.showSpinner();
    try {
      await onAutoSplitPaymentMethodSelect(true);
      this.hideSpinner();
      this.handleHide();
      return true;
    } catch (e) {
      console.log(e);
      this.hideSpinner();
      // this.handleHide();
      return e;
    }
  };

  renderScreen() {
    const { screen, selectedPaymentMethod, showAchSpinner } = this.state;
    const {
      forcePaymentMethod,
      errorScreenProps,
      successScreenProps,
      redirectToAccounts,
      savePayTemp,
      shouldShowAutoSplitPaymentMethod,
      fullUser,
    } = this.props;
    const screenProps = {
      showSpinner: this.showSpinner,
      hideSpinner: this.hideSpinner,
    };

    switch (screen) {
      case PAYMENT_SCREENS.ADD_ACH_MANUAL:
        return <AchManualScreen onSuccess={this.handleNewAccount} {...screenProps} />;

      case PAYMENT_SCREENS.ADD_ACH_INSTANT: {
        if (showAchSpinner) {
          return null;
        }

        return (
          <CreateAchAccount
            onSuccess={(_, accountId, paymentMethod) => this.handleNewAccount(accountId, paymentMethod)}
            onBankNotFound={this.handleBankNotFound}
            onFail={this.handleAddInstantAchPaymentFail}
            onExit={this.handleAddInstantAchExit}
            showSpinner={this.showAchSpinner}
            hideSpinner={this.hideAchSpinner}
          />
        );
      }

      case PAYMENT_SCREENS.ADD_ACH_INSTANT_FAIL:
        return (
          <ModalLayoutErrorScreen
            title={FEEDBACK_STRINGS.instant_ach_fail_title}
            subtitle={FEEDBACK_STRINGS.instant_ach_fail_description}
            ctaLabel="Try Again"
            onCtaClick={this.handleAddInstantAchPayment}
          />
        );
      case PAYMENT_SCREENS.ADD_CREDIT_CARD:
        return <AddCreditCardScreen onSuccess={this.handleNewAccount} savePayTemp={savePayTemp} {...screenProps} />;
      case PAYMENT_SCREENS.PAYMENT_SELECTION_FAIL:
        return <ModalLayoutErrorScreen onCtaClick={() => this.handlePaymentSelection()} {...errorScreenProps} />;
      case PAYMENT_SCREENS.PAYMENT_SELECTION_SUCCESS:
        return (
          <ModalLayoutSuccessScreen
            onCtaClick={() => this.handleCloseSuccessModal()}
            redirectToAccounts={redirectToAccounts}
            {...successScreenProps}
          />
        );

      case PAYMENT_SCREENS.PAYMENT_SELECTION:

      default: {
        if (forcePaymentMethod === PaymentMethod.CreditCard) {
          return <AddCreditCardScreen onSuccess={this.handleNewAccount} savePayTemp={savePayTemp} {...screenProps} />;
        }

        return (
          <NewPaymentTypeSelector
            selectedPaymentMethod={selectedPaymentMethod}
            onAddAchInstantPaymentMethod={this.handleAddInstantAchPayment}
            onAddAchManualPaymentMethod={this.handleAddManualAchPayment}
            onAddCreditCardPayment={this.handleAddCreditCardPayment}
            forcePaymentMethod={forcePaymentMethod}
            shouldShowAutoSplitPaymentMethod={shouldShowAutoSplitPaymentMethod}
            handleAutoSplitPaymentMethodSelection={this.handleAutoSplitPaymentMethodSelection}
            handleAvailablePaymentMethodSelection={this.handlePaymentSelection}
            fullUser={fullUser}
            {...screenProps}
          />
        );
      }
    }
  }

  render() {
    const { screen, showSpinner, showAchSpinner } = this.state;
    const { show } = this.props;

    return (
      <ModalV2
        show={show}
        onHide={this.handleHide}
        showSpinner={showSpinner || showAchSpinner}
        noBorder={screen === PAYMENT_SCREENS.ADD_ACH_INSTANT}
        noPadding
        closeButton={screen !== PAYMENT_SCREENS.ADD_ACH_INSTANT}
        backButton={screen !== PAYMENT_SCREENS.ADD_ACH_INSTANT}
        onBackButtonClick={this.handleHide}
      >
        {this.renderScreen()}
      </ModalV2>
    );
  }
}

const mapStateToProps = (state) => ({
  fullUser: selectFullUser(state),
  userLoggedIn: selectIsUserLoggedIn(state),
});

const mapDispatchToProps = {
  fetchUser,
  fetchPaymentMethods,
  updatePaymentMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsModal);
