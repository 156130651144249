import { useState } from 'react';
import Measure from 'react-measure';
import { IconInfo } from '@belong/icons';
import { MoneyRange, Tooltip, Money } from '@belong/ui';
import classNames from 'classnames/bind';
import IconButton from 'components/IconButton/IconButton';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Box, Flex, Text } from 'design-system';
import { startCase } from 'es-toolkit';
import { find, sumBy } from 'es-toolkit/compat';
import { MaintenanceRequester, MaintenanceResponsibility, MaintenanceAssessment, ConsentStatus } from 'models/enums';
import {
  COST_TYPES,
  AUTOCOST_TYPES,
  getCostLabel,
} from 'pages/PostInspectionFlow/steps/Improvements/Approval/Bundle/costs.utils';
import {
  BUNDLE_CONFIG,
  isTBD,
  getPayments,
  hasHomeownerApproved,
} from 'pages/PostInspectionFlow/steps/Improvements/utils';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { palette } from 'styles/palette';
import SetHeightAnimation from '../../../../../../animations/SetHeightAnimation/SetHeightAnimation';
import GeneralIcon, { GENERAL_ICONS } from '../../../../../../components/GeneralIcon/GeneralIcon';
import String from '../../../../../../components/String/String';
import { StrikedPrice } from '../Bundle/BundlePrice/BundlePrice';
import styles from './PriceTable.module.css';

const cx = classNames.bind(styles);

const propTypes = {
  sections: PropTypes.array.isRequired,
  nameHeader: PropTypes.string.isRequired,
  valueHeader: PropTypes.string.isRequired,
  toggle: PropTypes.bool,
  hideDividers: PropTypes.bool,
  isNewProjectManagementFee: PropTypes.bool,
};

const defaultProps = {
  toggle: false,
};

const Section = ({ section, hideDivider, isNewProjectManagementFee }) =>
  section.items?.length > 0 ? (
    <div className={cx('section')}>
      {!hideDivider && (
        <div className={cx('divider')}>
          <div className={cx('line')} />
        </div>
      )}
      {section.items?.map((item, idx) => {
        /**
         * Since we are using the same component for the price table in the Resident step subtotals and the approval modal
         * we need to obtain particular context out of the way it was build
         * if the item has a value, it means that it is a price value but this includes regular items and subtotals
         * if the item has a name, it means that it is a label
         * if item.name.bold is true, it means that it is a header
         * if item.name.gray is false, it means that it is a label for a price value for a regular item
         * TODO: refactor this component to make it more readable.
         */
        return (
          <div className={cx('item', item.name.bold && 'item-head')} key={idx}>
            <div className="flex flex-row items-center max-w-3xl">
              {item.name ? (
                <>
                  <span
                    className={cx(
                      'name',
                      {
                        bold: item.name.bold,
                        green: item.name.green,
                        gray: item.name.gray,
                        large: item.name.large,
                        indent: item.name.indent,
                      },
                      item.name.className
                    )}
                  >
                    {typeof item.name.text === 'string' ? <String string={item.name.text} /> : item.name.text}
                  </span>
                  {isNewProjectManagementFee &&
                    Object.keys(BUNDLE_CONFIG)
                      .filter((k) => k !== 'ProjectManagement')
                      .some((k) => BUNDLE_CONFIG[k].displayName === item.name.text) && (
                      <>
                        <p className="p1 md:text-body font-semibold mx-2xs md:mx-xs">•</p>
                        <p className="p1 md:text-body text-green font-semibold">0% Belong Markup</p>
                      </>
                    )}
                </>
              ) : (
                <span className={cx('name')} />
              )}
            </div>

            {item.value && (
              <span
                className={cx(
                  'value',
                  {
                    bold: item.value.bold,
                    green: item.value.green,
                    large: item.value.large,
                    gray: item.value.gray,
                  },
                  item.value.className
                )}
              >
                {item.value.text}
              </span>
            )}
          </div>
        );
      })}
    </div>
  ) : null;

Section.propTypes = {
  section: PropTypes.object.isRequired,
  hideDivider: PropTypes.bool,
  isNewProjectManagementFee: PropTypes.bool,
};

const HiddenSections = ({ sections }) => (
  <div>
    {sections.map((section, index) => (
      <Section section={section} key={index} />
    ))}
  </div>
);

HiddenSections.propTypes = {
  sections: PropTypes.array.isRequired,
};

const HiddenSectionsAnimated = SetHeightAnimation(HiddenSections);

const PriceTable = ({ nameHeader, valueHeader, sections, toggle, hideDividers, isNewProjectManagementFee }) => {
  const [open, setOpen] = useState(false);
  const topSection = sections[0];
  const hiddenSections = sections.slice(1);

  return (
    <div className={cx('price-table', { hide: !open && toggle })}>
      <div className={cx('header')}>
        <span>{nameHeader}</span>
        <span className={cx('value')}>{valueHeader}</span>
      </div>
      {toggle ? (
        <>
          <Section section={topSection} hideDivider={hideDividers} />
          <HiddenSectionsAnimated sections={hiddenSections} show={open} />
          <div className={cx('more-button-container')}>
            <ButtonBase className={cx('more-button', { open })} onClick={() => setOpen(!open)}>
              {open ? 'HIDE DETAILS' : 'SEE DETAILS'}
              <div className={cx('caret-icon')}>
                <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
              </div>
            </ButtonBase>
          </div>
        </>
      ) : (
        sections.map((section, index) => (
          <Section
            isNewProjectManagementFee={isNewProjectManagementFee}
            key={index}
            section={section}
            hideDivider={hideDividers}
          />
        ))
      )}
    </div>
  );
};

PriceTable.propTypes = propTypes;
PriceTable.defaultProps = defaultProps;

const insertIf = (condition, ...elements) => (condition ? elements : []);

export const getHomeownerBundlePriceSection = ({
  onChange,
  bundles,
  priceRange,
  isInvoiced = false,
  inspectionType,
  hideItemsCost = false,
}) => {
  const bundleSections = {
    items: [],
  };

  const costs = {
    cost: `${isInvoiced ? 'actual' : 'estimated'}Cost`,
    costBreakdown: `${isInvoiced ? 'actual' : 'estimated'}CostBreakdown`,
    displayCost: `${isInvoiced ? 'actual' : 'estimated'}DisplayCostForAllParties`,
    displayDiscount: `${isInvoiced ? 'actual' : 'estimated'}DisplayDiscount`,
    displayDiscountedCost: `${isInvoiced ? 'actual' : 'estimated'}DisplayDiscountedCost`,
  };

  const getCost = (item) => {
    if (hideItemsCost) return null;

    if (isInvoiced) {
      // Some items get invoiced individually, so we need to use the actual display cost
      const invoicedCost = item.actualDisplayCost || item.actualDisplayCostForAllParties;

      return invoicedCost || item[costs.displayCost];
    }

    return item[costs.displayCost];
  };

  const isEditable = !!onChange;

  const [regularBundles, recommendedBundles] = bundles.reduce(
    ([bundleA, bundleB], bundle) => {
      return [MaintenanceAssessment.Inspirational, MaintenanceAssessment.Recommended].includes(bundle.type)
        ? [bundleA, [...bundleB, bundle]]
        : [[...bundleA, bundle], bundleB];
    },
    [[], []]
  );

  const furtherInvestigationItems = [];

  const autocosts = AUTOCOST_TYPES.reduce((acc, x) => {
    acc[x] = 0;
    return acc;
  }, {});

  const addAutocost = (item) => {
    const isBelongFixingIt = item.maintenance.proServiceResponsibility === MaintenanceResponsibility.Belong;
    if (!isBelongFixingIt) return;

    for (const { cost, type } of item[costs.costBreakdown]) {
      if (type in autocosts) {
        autocosts[type] += cost || 0;
      }
    }
  };

  const isNotInvoiceable = (item) => {
    if (!isInvoiced) return false;

    const homeownerItemPayment = item.maintenancePayments.find((x) => x.paidBy === MaintenanceRequester.Homeowner);
    const residentItemPayment = item.maintenancePayments.find((x) => x.paidBy === MaintenanceRequester.Resident);

    return (
      homeownerItemPayment?.consentStatus !== ConsentStatus.Approved &&
      residentItemPayment?.consentStatus !== ConsentStatus.Approved
    );
  };

  const getResponsibilityText = (item) => {
    if (inspectionType?.isOnboarding) {
      return item.maintenance.proServiceResponsibility === MaintenanceResponsibility.Homeowner
        ? 'Doing\u00a0it\u00a0myself'
        : 'Belong\u00a0is\u00a0Fixing';
    }

    return '-';
  };

  const displayText = (text, item) => {
    const breakdown = getPayments(item.maintenancePayments);
    const hasHomeownerResponsibility = breakdown.homeowner?.percentage > 0;
    const isHomeownerApproved = breakdown.homeowner?.consentStatus === ConsentStatus.Approved;

    if (!hasHomeownerResponsibility || isHomeownerApproved || !isEditable) {
      return <p className="ml-sm md:ml-md">{text}</p>;
    }

    return (
      <Flex alignItems="center">
        <IconButton
          onClick={() => {
            onChange(true, [item], 'discount');
          }}
          icon={GENERAL_ICONS.PLUS_CIRCLE}
          generalIconProps={{ scale: 0.75 }}
          style={{ marginRight: 6 }}
        />
        {text}
      </Flex>
    );
  };

  // regular bundles
  for (const bundle of regularBundles) {
    const sortedRegularBundles = [...bundle.items].sort((itemA, itemB) => {
      // TBD items go at the very bottom
      if (itemA.isTBD) {
        return 1;
      }

      return itemA.estimatedDisplayCost > itemB.estimatedDisplayCost ? 1 : -1;
    });

    if (sortedRegularBundles.find((x) => (isInvoiced ? !isNotInvoiceable(x) : !isTBD(x)))) {
      bundleSections.items.push({
        name: {
          text: BUNDLE_CONFIG[bundle.type].displayName,
          bold: true,
        },
      });
    }

    for (const item of sortedRegularBundles) {
      // eslint-disable-next-line
      if (isNotInvoiceable(item)) continue;

      if (!isInvoiced && isTBD(item)) {
        furtherInvestigationItems.push(item);
        // eslint-disable-next-line
        continue;
      }

      addAutocost(item);

      bundleSections.items.push({
        name: {
          text: displayText(item.maintenance.summary, item),
          icon: true,
        },
        value: {
          text: (
            <div className="flex flex-col items-end">
              {!hideItemsCost && (
                <>
                  {item?.maintenance?.proServiceResponsibility === MaintenanceResponsibility.Homeowner ? (
                    <Money className="p1 md:body" format="DOLLARS_NO_CENTS" value={0} />
                  ) : item.displayAsPriceRange ? (
                    <MoneyRange
                      lower={item.priceRange.lowerBoundSubtotal}
                      upper={item.priceRange.upperBoundSubtotal}
                      format="DOLLARS_NO_CENTS"
                    />
                  ) : (
                    <Money className="p1 md:body" format="DOLLARS_NO_CENTS" value={getCost(item)} />
                  )}
                </>
              )}

              <p className="p1 text-dark-gray">{getResponsibilityText(item)}</p>
            </div>
          ),
        },
      });
    }
  }

  if (furtherInvestigationItems.length > 0) {
    bundleSections.items.push({
      name: {
        text: (
          <Flex alignItems="center">
            <Box mr="xs">Further Investigation Required</Box>
            <Tooltip
              content={
                <div className="p-2sm rounded bg-black max-w-md">
                  <p className="p1 font-regular text-left text-white">
                    You will not be charged for anything until we finalize the price for these items.
                  </p>
                </div>
              }
              arrowProps={{
                className: 'fill-bg-black',
              }}
            >
              <div className="flex flex-row justify-center gap-2xs cursor-pointer">
                <IconInfo width={20} />
              </div>
            </Tooltip>
          </Flex>
        ),
        bold: true,
      },
    });

    for (const item of furtherInvestigationItems) {
      addAutocost(item);
      bundleSections.items.push({
        name: {
          text: displayText(item.maintenance.summary, item),
        },
        value: {
          text: (
            <div className="flex flex-col items-end">
              <p>TBD</p>
              <p className="p1 text-dark-gray">{getResponsibilityText(item)}</p>
            </div>
          ),
        },
      });
    }
  }

  // recommended bundles
  if (recommendedBundles.length > 0) {
    bundleSections.items.push({
      name: {
        text: 'Recommended',
        bold: true,
      },
    });

    // In order to sort the items we need to create a new bundle merging all recommended/inspirational improvements
    const recommenedAndInspirationalBundles = recommendedBundles.reduce((accum, current) => {
      const allItems = accum.items || [];

      return {
        ...accum,
        items: [...allItems, ...current.items],
      };
    }, {});

    const sortedRecommendedItems = [...recommenedAndInspirationalBundles.items].sort((itemA, itemB) => {
      // TBD items go at the very bottom
      if (itemA.isTBD) {
        return 1;
      }

      return itemA.estimatedDisplayCost > itemB.estimatedDisplayCost ? 1 : -1;
    });

    for (const item of sortedRecommendedItems) {
      // eslint-disable-next-line
      if (isNotInvoiceable(item)) continue;
      addAutocost(item);

      bundleSections.items.push({
        name: {
          text: displayText(item.maintenance?.summary, item),
          gray: !hasHomeownerApproved(item.maintenancePayments),
        },
        value: {
          text: (
            <div className="flex flex-col items-end">
              {item.isTBD ? (
                <Text>TBD</Text>
              ) : item.displayAsPriceRange ? (
                <MoneyRange
                  lower={item.priceRange.lowerBoundSubtotal}
                  upper={item.priceRange.upperBoundSubtotal}
                  format="DOLLARS_NO_CENTS"
                />
              ) : (
                !hideItemsCost && <Money format="DOLLARS_NO_CENTS" value={item[costs.displayCost]} />
              )}
              <p className="p1 text-dark-gray">{getResponsibilityText(item)}</p>
            </div>
          ),
          gray: !hasHomeownerApproved(item.maintenancePayments),
        },
      });
    }
  }

  //If there are price ranges, means that the PM Fee is enabled
  if (priceRange) {
    const responsibilityRange = priceRange.priceRangeBreakdown.find((b) => b.paidBy === 'Homeowner');
    bundleSections.items.push({
      name: {
        text: getCostLabel(COST_TYPES.ProjectCoordination),
        bold: true,
      },
      value: {
        text: (
          <MoneyRange
            lower={responsibilityRange?.projectManagementFeeLowerBound || 0}
            upper={responsibilityRange?.projectManagementFeeUpperBound || 0}
            format="DOLLARS_NO_CENTS"
          />
        ),
      },
    });
  } else if (Object.values(autocosts).find((x) => x > 0)) {
    if (hideItemsCost) return bundleSections;

    const filteredAutoCosts = Object.fromEntries(Object.entries(autocosts).filter(([_, cost]) => cost !== 0));
    const isNewProjectManagementFee =
      Object.keys(filteredAutoCosts).length === 1 && filteredAutoCosts[COST_TYPES.ProjectCoordination];

    if (isNewProjectManagementFee) {
      bundleSections.items.push({
        name: {
          text: getCostLabel(COST_TYPES.ProjectCoordination),
          bold: true,
        },
        value: {
          text: <Money format="DOLLARS_NO_CENTS" value={autocosts[COST_TYPES.ProjectCoordination]} />,
        },
      });
    } else {
      bundleSections.items.push({
        name: {
          text: 'Additional Fees',
          bold: true,
        },
      });

      for (const type in autocosts) {
        if (!(autocosts[type] > 0)) continue;

        bundleSections.items.push({
          name: {
            text: getCostLabel(type),
            indent: true,
          },
          value: {
            text: <Money format="DOLLARS_NO_CENTS" value={autocosts[type]} />,
          },
        });
      }
    }
  }

  return bundleSections;
};

export const HomeownerReceiptPriceTable = ({
  groupPayment,
  bundles,
  homeownerPayment,
  maximumMonthlyPaymentPlan,
  onChange,
  isLegacyInvoicing,
  homeownerReportPayment,
  hideBundlesBreakdown,
  isNewProjectManagementFee,
  priceRange,
  showSubtotal = true,
  showEstTotal = true,
  inspectionType,
}) => {
  const sections = hideBundlesBreakdown
    ? []
    : [getHomeownerBundlePriceSection({ bundles, onChange, priceRange, inspectionType })];
  // payment section
  const { estimatedInstallmentBreakdown } = homeownerPayment || {};
  const installmentCostDetail = estimatedInstallmentBreakdown?.find(
    (b) => b.installmentCount === maximumMonthlyPaymentPlan
  );

  const residentPayment = find(groupPayment, { paidBy: MaintenanceResponsibility.Resident });
  const belongPayment = find(groupPayment, { paidBy: MaintenanceResponsibility.Belong });

  const homeownerRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Homeowner');
  const residentRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Resident');
  const belongRange = priceRange?.priceRangeBreakdown?.find((range) => range.paidBy === 'Belong');

  const showSubtotalSection =
    (showSubtotal && installmentCostDetail) ||
    homeownerPayment?.estimatedDiscountAmount ||
    homeownerRange ||
    residentPayment?.estimatedTotalAmount ||
    residentRange ||
    belongPayment?.estimatedTotalAmount ||
    belongRange;

  if (showSubtotalSection) {
    const subtotalsSection = {
      items: [],
    };

    const subTotal = sumBy(groupPayment, 'estimatedTotalAmount');
    const isSubtotalsVisible =
      !inspectionType?.isOnboarding ||
      (inspectionType?.isOnboarding && inspectionType?.isAdoptedLease) ||
      homeownerPayment?.estimatedDiscountAmount > 0;

    if (isSubtotalsVisible) {
      subtotalsSection.items.push({
        name: {
          text:
            inspectionType?.isMoveOut || (inspectionType?.isOnboarding && inspectionType?.isAdoptedLease) ? (
              <Tooltip
                contentProps={{ className: 'z-50' }}
                content={
                  <div className="p-2sm rounded bg-dark-navy max-w-md border-dark-navy">
                    <p className="p1 text-white font-regular text-left">
                      We guarantee you&apos;ll never pay more than this approved amount. If costs exceed it, Belong will
                      cover the difference.
                    </p>
                  </div>
                }
                arrowProps={{
                  className: 'fill-dark-navy border-dark-navy',
                }}
              >
                <button className="cursor-pointer fill-navy flex items-center" type="button">
                  <span className="mr-2xs">Never Exceed</span>
                  <IconInfo width={18} />
                </button>
              </Tooltip>
            ) : (
              'Subtotal'
            ),
        },
        value: {
          text: priceRange ? (
            <div className="flex gap-xs">
              <MoneyRange
                lower={priceRange.lowerBoundTotal}
                upper={priceRange.upperBoundTotal}
                className="p1 md:body font-semibold"
                format="DOLLARS_NO_CENTS"
              />
              <Tooltip
                contentProps={{ className: 'z-50' }}
                content={
                  <div className="p-2sm rounded bg-black max-w-md ">
                    <p className="p1 text-white">
                      We&apos;ve calculated a repair range factoring in potential scope changes that can affect the
                      final cost once a contractor takes a closer look. Belong will negotiate the lowest final price
                      with no mark-up. Greenlighting this range empowers our team to start work promptly.
                    </p>
                  </div>
                }
                arrowProps={{
                  className: 'fill-bg-black',
                }}
              >
                <div className="cursor-pointer" style={{ marginTop: '5px' }}>
                  <IconInfo width={20} />
                </div>
              </Tooltip>
            </div>
          ) : (
            <Money format="DOLLARS_NO_CENTS" className="p1 md:body" value={subTotal} />
          ),
        },
      });
    }

    if (residentPayment?.estimatedTotalAmount || residentRange?.upperBoundSubtotal > 0) {
      subtotalsSection.items.push({
        name: {
          text: 'Expected Resident Responsibility',
        },
        value: {
          text: priceRange ? (
            <div className="flex">
              <span>-&nbsp;</span>
              <MoneyRange
                lower={residentRange.lowerBoundTotal}
                upper={residentRange.upperBoundTotal}
                className="text-p1 md:text-body"
                format="DOLLARS_NO_CENTS"
              />
            </div>
          ) : (
            <span className="flex">
              -{' '}
              <Money
                format="DOLLARS_NO_CENTS"
                value={residentPayment?.estimatedTotalAmount}
                className="text-p1 md:text-body"
              />
            </span>
          ),
        },
      });
    }

    if (belongPayment?.estimatedTotalAmount || belongRange?.upperBoundSubtotal > 0) {
      subtotalsSection.items.push({
        name: {
          text: 'Belong Responsibility',
        },
        value: {
          text: priceRange ? (
            <div className="flex">
              <span>-&nbsp;</span>
              <MoneyRange
                lower={belongRange.lowerBoundTotal}
                upper={belongRange.upperBoundTotal}
                className="text-p1 md:text-body"
                format="DOLLARS_NO_CENTS"
              />
            </div>
          ) : (
            <span className="flex">
              -{' '}
              <Money
                format="DOLLARS_NO_CENTS"
                value={belongPayment?.estimatedTotalAmount}
                className="text-p1 md:text-body"
              />
            </span>
          ),
        },
      });
    }

    if (homeownerPayment?.estimatedDiscountAmount) {
      subtotalsSection.items.push({
        name: {
          text: 'Savings',
          bold: true,
        },
        value: {
          text: (
            <span className="font-semibold text-green">
              - <Money format="DOLLARS_NO_CENTS" value={homeownerPayment.estimatedDiscountAmount} />
            </span>
          ),
        },
      });
    }

    sections.push(subtotalsSection);
  }

  const getTotalSectionTitle = () => {
    if (!isLegacyInvoicing) {
      return POST_INSPECTION_FLOW_STRINGS['approvals.est.total'];
    }

    return homeownerPayment?.estimatedDiscountAmount ? 'Total After Savings' : 'Total';
  };

  const getTotalValue = () => {
    if (!isLegacyInvoicing) {
      return homeownerReportPayment.total;
    }

    return homeownerPayment?.estimatedDiscountApplied || 0;
  };

  if (showEstTotal) {
    const totalsSection = {
      items: [
        {
          name: {
            text: getTotalSectionTitle(),
            bold: true,
            large: !installmentCostDetail,
            className: hideBundlesBreakdown && 'mt-xs',
          },
          value: {
            text: priceRange ? (
              <div className="flex gap-xs">
                <MoneyRange
                  lower={homeownerRange.lowerBoundTotal}
                  upper={homeownerRange.upperBoundTotal}
                  className="font-semibold"
                  format="DOLLARS_WITH_CENTS"
                />
                <Tooltip
                  contentStyle={{ width: 250, textAlign: 'left', border: 'none' }}
                  position="bottom center"
                  content={
                    <div className="p-2sm rounded bg-black max-w-md ">
                      <p className="p1 text-white font-regular">
                        We&apos;ve calculated a repair range factoring in potential scope changes that can affect the
                        final cost once a contractor takes a closer look. Belong will negotiate the lowest final price
                        with no mark-up. Greenlighting this range empowers our team to start work promptly.
                      </p>
                    </div>
                  }
                  arrowProps={{
                    className: 'fill-bg-black',
                  }}
                >
                  <div className="cursor-pointer" style={{ marginTop: '5px' }}>
                    <IconInfo width={20} />
                  </div>
                </Tooltip>
              </div>
            ) : (
              <Money format="DOLLARS_WITH_CENTS" value={getTotalValue()} className="font-semibold" />
            ),
            large: !installmentCostDetail,
            bold: true,
            className: hideBundlesBreakdown && 'mt-xs',
          },
        },
      ],
    };

    sections.push(totalsSection);
  }

  return (
    <PriceTable
      nameHeader={hideBundlesBreakdown ? '' : 'Items'}
      valueHeader={hideBundlesBreakdown ? '' : 'Amount'}
      sections={sections}
      hideDividers={hideBundlesBreakdown}
      isNewProjectManagementFee={isNewProjectManagementFee}
    />
  );
};

HomeownerReceiptPriceTable.propTypes = {
  bundles: PropTypes.array,
  groupPayment: PropTypes.array,
  hideBundlesBreakdown: PropTypes.bool,
  homeownerPayment: PropTypes.object,
  homeownerReportPayment: PropTypes.object,
  inspectionType: PropTypes.object,
  isLegacyInvoicing: PropTypes.bool.isRequired,
  isNewProjectManagementFee: PropTypes.bool,
  maximumMonthlyPaymentPlan: PropTypes.number,
  onChange: PropTypes.func,
  priceRange: PropTypes.object,
  showEstTotal: PropTypes.bool,
  showSubtotal: PropTypes.bool,
};

// Shows price table for subtask without auto costs.

export const SubtaskPriceTable = ({
  item,
  isInvoiced = false,
  showResponsibilityPercentage = true,
  groupPayment = [],
  paidByFilter = MaintenanceRequester.Homeowner,
  showPriceBreakdown = false,
  displayDropdown = true,
  currentOptionId,
  subtaskDueNow,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { costOptions, maintenancePayments } = item;

  const breakdown = getPayments(maintenancePayments);

  const isSharedResponsibility = breakdown.resident?.percentage > 0 && breakdown.homeowner?.percentage > 0;

  const selectedCost = costOptions?.find((costOption) => costOption.costId === currentOptionId) || {};

  let displayCost = 0;
  let displayCostForAllParties = 0;
  let displayDiscountedCost = 0;
  let costBreakdown = [];
  let displayDiscount = 0;

  if (isInvoiced) {
    displayCost = item.actualDisplayCost;
    displayCostForAllParties = item.actualDisplayCostForAllParties;
    displayDiscountedCost = item.actualDisplayDiscountedCost;
    costBreakdown = item.actualCostBreakdown;
    displayDiscount = item.actualDisplayDiscount;
  } else {
    displayCost = selectedCost.optionDisplayCost;
    displayCostForAllParties = selectedCost.optionDisplayCostForAllParties;
    displayDiscountedCost = selectedCost.optionDisplayDiscountedCost;
    costBreakdown = selectedCost.optionCostsBreakdown || [];
    displayDiscount = selectedCost.optionDisplayDiscount;
  }

  const paymentForParty = maintenancePayments.find((x) => x.paidBy === paidByFilter);
  const groupPaymentForParty = groupPayment.find((x) => x.paidBy === paidByFilter);

  const hasDiscount =
    groupPaymentForParty &&
    groupPaymentForParty.discountType === 'Percentage' &&
    groupPaymentForParty.discountValue > 0;

  const partCost = costBreakdown.find((x) => x.type === COST_TYPES.Parts)?.cost;
  const laborCost = costBreakdown.find((x) => x.type === COST_TYPES.Labor)?.cost;
  const otherCost = costBreakdown.find((x) => x.type === COST_TYPES.Other)?.cost;

  const _isTBD = showPriceBreakdown && isTBD(item) && !isInvoiced;

  const sections = [];

  const costs = [
    ...insertIf(partCost > 0, {
      name: {
        text: 'Parts',
      },
      value: {
        text: <Money format="DOLLARS_NO_CENTS" value={partCost} />,
      },
    }),
    ...insertIf(laborCost > 0, {
      name: {
        text: 'Onsite Labor',
      },
      value: {
        text: <Money format="DOLLARS_NO_CENTS" value={laborCost} />,
      },
    }),
    ...insertIf(otherCost > 0, {
      name: {
        text: 'Other',
      },
      value: {
        text: <Money format="DOLLARS_NO_CENTS" value={otherCost} />,
      },
    }),
  ];

  if (costs.length === 0) {
    return null;
  }

  // total
  if (costs.length > 0) {
    sections.push({ items: costs });

    if (displayDiscount > 0) {
      sections.push({
        items: [
          {
            name: {
              text: 'Subtotal',
            },
            value: {
              text: <Money format="DOLLARS_NO_CENTS" value={displayCostForAllParties} />,
            },
          },
          ...insertIf(isSharedResponsibility, {
            name: {
              text:
                paymentForParty && paymentForParty?.percentage === 100
                  ? 'Your Portion'
                  : `Your Portion${showResponsibilityPercentage ? ` (${paymentForParty?.percentage || 0}%)` : ''}`,
              bold: true,
            },
            value: {
              text: <Money format="DOLLARS_NO_CENTS" value={displayCost} />,
              bold: true,
            },
          }),
          {
            name: {
              text: hasDiscount ? `${groupPaymentForParty.discountValue}% Bundled Savings` : 'Bundled Savings',
              bold: true,
            },
            value: {
              text: (
                <>
                  -<Money format="DOLLARS_NO_CENTS" value={displayDiscount} />
                </>
              ),
              bold: true,
              green: true,
            },
          },
        ],
      });

      sections.push({
        items: [
          {
            name: {
              text: 'Total',
              bold: true,
            },
            value: {
              text: <Money format="DOLLARS_NO_CENTS" value={displayDiscountedCost} />,
              bold: true,
            },
          },
        ],
      });
    } else {
      sections.push({
        items: [
          ...insertIf(displayCostForAllParties !== displayDiscountedCost && costs.length > 1, {
            name: {
              text: 'Subtotal',
            },
            value: {
              text: <Money format="DOLLARS_NO_CENTS" value={displayCostForAllParties} />,
            },
          }),
          {
            name: {
              text:
                paymentForParty && paymentForParty?.percentage === 100
                  ? 'Total'
                  : `Your Portion${showResponsibilityPercentage ? ` (${paymentForParty?.percentage || 0}%)` : ''}`,
              bold: true,
            },
            value: {
              text: <Money format="DOLLARS_NO_CENTS" value={displayDiscountedCost} />,
              bold: true,
            },
          },
          ...insertIf(Boolean(subtaskDueNow), {
            name: {
              text: 'Due Now',
              bold: true,
            },
            value: {
              text: <Money format="DOLLARS_NO_CENTS" value={subtaskDueNow} />,
              bold: true,
            },
          }),
        ],
      });
    }
  }

  const handleToggle = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const content = (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Text fontWeight="semibold">Pricing Details</Text>
      </Flex>
      <Flex alignItems="center">
        {!_isTBD ? (
          <>
            <StrikedPrice
              item={item}
              primaryTextProps={{ color: palette.navy }}
              secondaryTextProps={{ color: hasDiscount ? palette['dark-gray'] : palette.navy }}
              costOption={selectedCost}
              showFullPrice
            />
            {displayDropdown && (
              <div className={cx('caret-icon', { 'care-icon-rotated': isExpanded })}>
                <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
              </div>
            )}
          </>
        ) : (
          <Flex ml="xs">
            <Text fontWeight="semibold">TBD</Text>
            {item.maintenance.quotePendingReason && (
              <Box ml="xs">
                <Tooltip position="top right" contentStyle={{ textAlign: 'left', border: 'none' }}>
                  <Box>
                    <Text variant="body" fontWeight="semibold">
                      {startCase(item.maintenance.quotePendingReason)}
                    </Text>
                  </Box>
                  <Box mt="xs">
                    <Text variant="body">
                      <String
                        string={
                          POST_INSPECTION_FLOW_STRINGS[`improvements.TBD_reason.${item.maintenance.quotePendingReason}`]
                        }
                      />
                    </Text>
                  </Box>
                </Tooltip>
              </Box>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );

  return (
    <div className={cx('subtask-table-wrapper', displayDropdown && 'button-wrapper')}>
      {displayDropdown ? (
        <ButtonBase className={cx('subtask-table-header')} onClick={handleToggle}>
          {content}
        </ButtonBase>
      ) : (
        <div className={cx('subtask-table-header')}>{content}</div>
      )}

      {!_isTBD && (
        <Measure bounds>
          {({ measureRef }) => (
            <div
              className={cx('content', { overflowAuto: !isExpanded })}
              ref={measureRef}
              style={{ maxHeight: isExpanded ? measureRef.current?.scrollHeight : '0px' }}
            >
              <div className={cx('items-container')}>
                <PriceTable nameHeader="Items" sections={sections} valueHeader="Amount" />
              </div>
            </div>
          )}
        </Measure>
      )}
    </div>
  );
};

SubtaskPriceTable.propTypes = {
  currentOptionId: PropTypes.number,
  displayDropdown: PropTypes.bool,
  groupPayment: PropTypes.array,
  isInvoiced: PropTypes.bool,
  item: PropTypes.object,
  paidByFilter: PropTypes.oneOf(Object.values(MaintenanceRequester)),
  showPriceBreakdown: PropTypes.bool,
  showResponsibilityPercentage: PropTypes.bool,
  subtaskDueNow: PropTypes.number,
};

export default PriceTable;
