import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { downShiftItemProptypes } from 'components/DownshiftComponents/downShiftItemProptypes';
import DropdownComponent from 'components/Dropdown/DropdownComponent/DropdownComponent';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { findIndex, find } from 'es-toolkit/compat';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.css';

const cx = classNames.bind(styles);

export default class Dropdown extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    defaultSelectedItem: PropTypes.shape(downShiftItemProptypes),
    disabled: PropTypes.bool,
    itemClassname: PropTypes.string,
  };

  static defaultProps = {
    label: 'Select',
    placeholder: 'Press down or start typing',
    defaultSelectedItem: null,
    error: false,
    disabled: false,
  };

  // Important to define the defaultHighlightIndex outside of downshift to prevent a rerendering bug when input.change is called.
  // the form value changes after this renders, so the highlighted index doesn't change.
  getDefaultHighlightedIndex = () => {
    const { defaultSelectedItem, items } = this.props;

    const foundIndex = findIndex(items, (e) => {
      return e.key === defaultSelectedItem?.key;
    });
    if (foundIndex < 0) {
      return 0;
    }

    return foundIndex;
  };

  displayDropdownIcon = (isOpen) => {
    return (
      <div className={cx('format-icon', { 'rotate-icon': isOpen })}>
        <GeneralIcon icon={GENERAL_ICONS.DOWN_CARET} />
      </div>
    );
  };

  render() {
    const { defaultSelectedItem, disabled, items, ...rest } = this.props;

    return (
      <DropdownComponent
        {...rest}
        defaultHighlightedIndex={this.getDefaultHighlightedIndex()}
        defaultSelectedItem={defaultSelectedItem}
        items={items}
        disabled={disabled}
        displayIcon={this.displayDropdownIcon}
      />
    );
  }
}

export const DropdownFinalFormAdapter = ({ input, meta, onChangeCustom, ignoreError, newFieldComponent, ...rest }) => {
  const controlledProps = {};

  if (!ignoreError) {
    if (newFieldComponent) {
      controlledProps.error = !!meta.error;
    } else {
      controlledProps.error = meta.touched ? !!meta.error : false;
    }
  }

  // to normalize data from input.value into downshift
  let defaultSelectedItem = {};
  if (typeof input.value === 'object') {
    defaultSelectedItem = input.value;
  } else {
    defaultSelectedItem = find(rest.items, (e) => {
      return e.key === input.value?.toString();
    });
  }

  return (
    <Dropdown
      {...input}
      {...rest}
      onSelectedItemChange={(changes) => {
        input.onChange(changes.selectedItem?.key);

        if (onChangeCustom) {
          onChangeCustom(changes.selectedItem?.key);
        }
      }}
      defaultSelectedItem={defaultSelectedItem}
      {...controlledProps}
    />
  );
};

DropdownFinalFormAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func,
  selectedKey: PropTypes.string,
  allowUserInput: PropTypes.bool,
  ignoreError: PropTypes.bool,
  newFieldComponent: PropTypes.bool,
  itemClassname: PropTypes.string,
};

DropdownFinalFormAdapter.defaultProps = {
  onChangeCustom: () => {},
  selectedKey: null,
  allowUserInput: false,
  ignoreError: false,
  newFieldComponent: false,
};
const getNumberValue = (value, allowZero, showOrMoreIndicator) => {
  if (allowZero) {
    return `${value}`;
  }

  const showValue = value + 1;
  return `${showOrMoreIndicator ? `${showValue}+` : showValue}`;
};

export const NumberDropdownFinalFormAdapter = ({ max, allowZero, showOrMoreIndicator, ...rest }) => (
  <DropdownFinalFormAdapter
    {...rest}
    items={Array.from(Array(max).keys()).map((value) => ({
      key: getNumberValue(value, allowZero, showOrMoreIndicator),
      value: getNumberValue(value, allowZero, showOrMoreIndicator),
    }))}
  />
);

NumberDropdownFinalFormAdapter.propTypes = {
  max: PropTypes.number.isRequired,
  allowZero: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChangeCustom: PropTypes.func,
  selectedKey: PropTypes.string,
  infinityObject: PropTypes.object,
  showOrMoreIndicator: PropTypes.bool,
};

NumberDropdownFinalFormAdapter.defaultProps = {
  allowZero: false,
  onChangeCustom: () => {},
  selectedKey: null,
  infinityObject: null,
  showOrMoreIndicator: false,
};

export const NumberDropdownInfinityFinalFormAdapter = ({ selectedKey, max, allowZero, infinityObject, ...rest }) => (
  <DropdownFinalFormAdapter
    {...rest}
    items={(() => {
      let itemsArray = [];

      const itemsArrayTillMax = Array.from(Array(max).keys()).map((value) => ({
        key: `${allowZero ? value : value + 1}`,
        value: `${allowZero ? value : value + 1}`,
      }));

      if (infinityObject) {
        itemsArray = [infinityObject, ...itemsArrayTillMax];
      }

      return itemsArray;
    })()}
  />
);

NumberDropdownInfinityFinalFormAdapter.propTypes = {
  max: PropTypes.number.isRequired,
  selectedKey: PropTypes.string,
  allowZero: PropTypes.bool,
  infinityObject: PropTypes.object,
};
NumberDropdownInfinityFinalFormAdapter.defaultProps = {
  selectedKey: null,
  allowZero: false,
  infinityObject: null,
};
