import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import AccountTimeline from 'components/AccountTimeline/AccountTimeline';
import MultipleBelongEmployeeProfileView from 'components/MultipleBelongEmployeeProfileView/MultipleBelongEmployeeProfileView';
import TabBar from 'components/TabBar/TabBar';
import AccountUnitDetailContainer from 'containers/AccountUnitDetailContainer/AccountUnitDetailContainer';
import ResidentListContainer from 'containers/ResidentListContainer/ResidentListContainer';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Text } from 'design-system';
import { flatten, isNil } from 'es-toolkit';
import { EmployeeAssignmentTypes } from 'models/enums';
import AccountPageSection from 'pages/Accounts/Pages/AccountPage/AccountPageSection/AccountPageSection';
import PropTypes from 'prop-types';
import { selectAccountUnitExternalPublishings } from 'store/redux/homeowner-accounts/selectors';
import DisplayEvent from '../DisplayEvent/DisplayEvent';
// import CurrentLease from '../CurrentLease/CurrentLease';
import NextLease from '../NextLease/NextLease';
import NextRequiredEvents from '../NextRequiredEvents/NextRequiredEvents';
import styles from './Unit.module.css';

const milestoneMap = {
  InspectionScheduled: 'Scheduling Your Inspection',
  InspectionCompleted: 'Completing Your Inspection',
  PostInspectionProposalSent: 'Sending Your Report',
  PostInspectionProposalApproved: 'Approving Your Report',
  ShowReady: 'Readying Your Home To Be Shown',
  FullListingPublished: 'Publishing Your Listing',
  LeaseSigned: 'Finding Your Home Love',
  MoveInReady: 'Readying Your Home To Be Moved Into',
  ResidentMovedIn: 'Moving Your Residents In',
  ManagingAdoptedResidents: 'Starting To Manage Your Residents',
  ListingRepublished: 'Publishing Your Listing',
};

const nextRequiredEventsBlacklist = {
  ResidentGaveNotice: 'ResidentGaveNotice',
  PricingAdjusted: 'PricingAdjusted',
  SignageCompleted: 'SignageCompleted',
};

const cx = classNames.bind(styles);

const tabItems = [
  {
    key: 'timeline',
    text: 'My Home Timeline',
  },
  {
    key: 'residents',
    text: 'My Wonderful Residents',
  },
];

const Unit = ({ unit, employees, timeline, externalPublishings }) => {
  const { currentLease, nextLease } = unit || {};
  const [selecetedTab, setSelectedTab] = useState(tabItems[0]);

  if (!unit) return null;

  const unitExternalPublishing = externalPublishings?.find(
    (externalPublishing) => externalPublishing.unitId === unit.basicInfo.unitId
  );

  const { residents = [], kids = [], pets = [] } = currentLease || nextLease || {};
  const allResidents = {
    residents,
    kids,
    pets,
  };
  const allResidentsLen = [...residents, ...kids, ...pets].length;

  // We need the index of the first index that is not internal to show the pulse animation.
  const firstNonInternalIndex = timeline.timelineEvents.findIndex((event) => event.name);
  const timelineItems = [
    ...flatten(
      timeline.timelineEvents.map((event, index) => {
        if (event.timelineEventType === 'ThirdPartyListingsPublished') {
          return DisplayEvent(
            {
              ...event,
              metadata: {
                ...event.metadata,
                externalPublishing: unitExternalPublishing?.externalListingPublishings,
              },
            },
            index === firstNonInternalIndex
          );
        }

        return DisplayEvent(event, index === firstNonInternalIndex);
      })
    ),
  ].filter((item) => !isNil(item));

  const nextRequiredEventsArray = Object.values(timeline.requiredEventsForNextMilestone).filter(
    (event) => !nextRequiredEventsBlacklist[event.timelineEventType]
  );

  const employeesWithOrder = employees?.map((employee) => ({
    ...employee,
    order: [EmployeeAssignmentTypes.HomeownerGrowth, EmployeeAssignmentTypes.Concierge].includes(
      employee.assignmentType
    ),
  }));

  return (
    <div className="UnitWrapper">
      <AccountUnitDetailContainer accountUnitDetail={unit} />
      <Space value={SPACE_TYPES.XXXL} />

      {employees.length > 0 && (
        <>
          <AccountPageSection
            title="Meet Your Team"
            content={
              <div className={cx('multiple-employee-container')}>
                <MultipleBelongEmployeeProfileView employees={employeesWithOrder} />
              </div>
            }
          />
          <Space value={SPACE_TYPES.XL} />
        </>
      )}

      <div className={cx('tabs')}>
        <TabBar
          name="unitTabBar"
          // Show only first item if no lease.
          items={currentLease || nextLease ? tabItems : [tabItems[0]]}
          onSelect={setSelectedTab}
          selected={selecetedTab.key}
        />
      </div>

      {selecetedTab.key === 'timeline' && (
        <>
          {/* If there are no events in nextRequiredEventsArray, show next milestone */}
          {(nextRequiredEventsArray.length > 0 || milestoneMap[timeline.nextMilestone]) && (
            <>
              <Text fontWeight="semibold" color="dark-gray">
                What’s next?
              </Text>
              <Space value={SPACE_TYPES.XS} />
              <NextRequiredEvents
                nextRequiredEvents={
                  nextRequiredEventsArray.length > 0
                    ? nextRequiredEventsArray
                    : [{ name: milestoneMap[timeline.nextMilestone] }]
                }
              />
              <Space value={SPACE_TYPES.SM} />
            </>
          )}
          <AccountTimeline className={cx('padding-for-pulse')} items={timelineItems} includesEndTail={false} />
        </>
      )}
      {selecetedTab.key === 'residents' && (
        <>
          {/* We are hidding this section from consumer. This will be displayed again once Deposit Free Fee and Renter Insurance costs are corrected. */}
          {/* <CurrentLease currentLease={currentLease} /> */}
          <NextLease nextLease={nextLease} />
          {allResidentsLen > 0 && (
            <AccountPageSection title="Belong Members" content={<ResidentListContainer residents={allResidents} />} />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  externalPublishings: selectAccountUnitExternalPublishings(state),
});

Unit.propTypes = {
  unit: PropTypes.object.isRequired,
  employees: PropTypes.array.isRequired,
  timeline: PropTypes.object.isRequired,
  externalPublishings: PropTypes.object,
};

export default connect(mapStateToProps)(Unit);
