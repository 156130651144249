import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { HOAFormFields } from 'accounts/components/form-fields/hoa';
import { StepFormLayout } from 'accounts/components/step-form-layout';
import { HOA_DOCUMENTS_OPTIONS } from 'accounts/constants/property-hoa';
import { PROPERTY_HOA_STRINGS } from 'accounts/constants/strings/property-hoa.strings';
import { usePropertyHoa } from 'accounts/hooks/homeowners/use-property-hoa';
import { useSetupFlowStep } from 'accounts/hooks/homeowners/use-setup-flow-step-context';
import Field from 'components/Field/Field';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import GreenInfoBox from 'components/GreenInfoBox/GreenInfoBox';
import { MEDIA_LABEL, NewMediaUploadAdapter } from 'components/NewMediaUploader/NewMediaUploader';
import { SelectorFinalFormAdapter, SELECTOR_TYPES } from 'components/Selector/Selector';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import String from 'components/String/String';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import { findIndex } from 'es-toolkit/compat';
import Condition from 'formcomponents/Condition/Condition';
import { useWindowSize } from 'hooks/useWindowSize';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { IMAGE_FILE_TYPE } from 'models/enums/index';
import { uploadFiles } from 'store/redux/images/actions';
import { getString } from 'strings/index';
import { required } from 'utils/validation';

export function PropertyHOA() {
  const dispatch = useDispatch();
  const { selectedProperty, unitId, handleSaveAndNext, goPreviousStep, setLoading } = useSetupFlowStep();
  const { title, initialValues, isHoaLocked, propertyStreetAddress } = usePropertyHoa();
  const { width } = useWindowSize();

  const { units } = selectedProperty || {};
  const currentUnitIndex = findIndex(
    units as { basicInfo?: { unitId: string } }[],
    (unit) => unit?.basicInfo?.unitId === unitId
  );
  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;
  const doneLabel =
    units?.length > 1 && currentUnitIndex !== units?.length - 1 && !units?.[currentUnitIndex + 1]?.isFarOffMoveout
      ? PROPERTY_HOA_STRINGS['done_label.units']
      : PROPERTY_HOA_STRINGS.done_label;

  async function handleSubmit(values) {
    try {
      let valuesToModify = { ...values };

      if (valuesToModify?.propertyOwnerDetails) {
        const {
          propertyOwnerDetails: { hoaDocuments = {} },
        } = valuesToModify || {};

        setLoading(true);

        try {
          const hoaFiles = await dispatch(uploadFiles(IMAGE_FILE_TYPE.HoaAgreement, hoaDocuments));
          valuesToModify.propertyOwnerDetails.hoaAgreementFiles = hoaFiles;
        } catch (err) {
          console.error(err);
        }

        setLoading(false);
      }

      if (valuesToModify?.propertyDetails?.hasHoa === false) {
        valuesToModify = {
          propertyDetails: {
            hasHoa: false,
          },
        };
      }

      if (valuesToModify?.propertyOwnerDetails?.hoaDocuments) {
        delete valuesToModify?.propertyOwnerDetails.hoaDocuments;
      }

      await handleSaveAndNext(valuesToModify);
    } catch (e) {
      console.error(e);
    }
  }

  function getHasHoaSectionTitle() {
    if (!isHoaLocked) {
      return (
        <String
          string={getString(PROPERTY_HOA_STRINGS['has_hoa.title'], {
            propertyStreetAddress,
          })}
        />
      );
    }

    return (
      <div className="flex justify-between items-center w-full">
        <div>
          <String
            string={getString(PROPERTY_HOA_STRINGS['has_hoa.title'], {
              propertyStreetAddress,
            })}
          />
        </div>
        <div>
          <GeneralIcon icon={GENERAL_ICONS.LOCK} />
        </div>
      </div>
    );
  }

  return (
    <StepFormLayout
      handleBackStep={goPreviousStep}
      onSubmit={handleSubmit}
      title={title}
      doneLabel={doneLabel}
      initialValues={initialValues}
      getForm={({ form }) => (
        <Fragment>
          <FormLayout.Section
            firstSection
            sectionTitle={getHasHoaSectionTitle()}
            sectionSubTitle={PROPERTY_HOA_STRINGS['has_hoa.subtitle']}
          >
            <Field
              validate={required}
              component={YesNoFinalFormAdapter}
              disabled={isHoaLocked}
              name="propertyDetails.hasHoa"
            />
          </FormLayout.Section>
          <Condition when="propertyDetails.hasHoa" is>
            <HOAFormFields name="propertyDetails.hoaContact" form={form} isLocked={isHoaLocked} />

            <FormLayout.Section sectionTitle={PROPERTY_HOA_STRINGS['notified_hoa.title']}>
              <Field validate={required} component={YesNoFinalFormAdapter} name="propertyOwnerDetails.hoaIsNotified" />
              <Condition when="propertyOwnerDetails.hoaIsNotified" is={false}>
                <GreenInfoBox>{PROPERTY_HOA_STRINGS['notified_hoa.false_text']}</GreenInfoBox>
              </Condition>
            </FormLayout.Section>
            <FormLayout.Section
              sectionTitle={PROPERTY_HOA_STRINGS['upload_hoa.title']}
              sectionSubTitle={PROPERTY_HOA_STRINGS['upload_hoa.subtitle']}
            >
              <Field
                name="propertyOwnerDetails.hoaDocuments.later"
                component={SelectorFinalFormAdapter}
                validate={required}
                buttons={HOA_DOCUMENTS_OPTIONS}
                type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
              />
            </FormLayout.Section>
            <Condition when="propertyOwnerDetails.hoaDocuments.later" is={false}>
              <FormLayout.Section>
                <Field
                  name="propertyOwnerDetails.hoaDocuments"
                  component={NewMediaUploadAdapter}
                  mediaLabel={MEDIA_LABEL.HOA}
                  disableWebcam={!isMobile}
                />
              </FormLayout.Section>
            </Condition>
          </Condition>
        </Fragment>
      )}
    />
  );
}
