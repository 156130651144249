import { useState, useEffect } from 'react';
import Tabs from 'components/TabBar/TabBar';
import AccountPropertyBannerCard from 'containercomponents/AccountPropertyBannerCard/AccountPropertyBannerCard';
import AccountUnitDetailContainer from 'containers/AccountUnitDetailContainer/AccountUnitDetailContainer';
import { sortBy } from 'es-toolkit';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HomeownerAccountUnit from 'models/homeownerAccounts/HomeownerAccountUnit';

/* eslint-disable @typescript-eslint/no-explicit-any */
const TabBar = Tabs as any;

type HomeInfoProps = {
  accountProperty: HomeownerAccountProperty;
  units: HomeownerAccountUnit[];
};

export function HomeInfo({ accountProperty, units }: HomeInfoProps) {
  const [unit, setUnit] = useState<HomeownerAccountUnit>(null);

  useEffect(() => {
    if (units?.length) {
      setUnit(units[0]);
    }
  }, [units]);

  if (!unit || !accountProperty) {
    return null;
  }

  return (
    <>
      <div className="mt-2xl">
        <AccountPropertyBannerCard
          coordinates={accountProperty.address.coordinates}
          imageUrl={units[0]?.basicInfo.bannerImageUrl}
          staticMapImageUrl={accountProperty.staticMapImageUrl}
        />
      </div>

      {units.length > 1 && (
        <div className="mt-md flex">
          <TabBar
            items={sortBy(
              units.map((unitItem) => ({
                text: `Unit ${unitItem.basicInfo.unitNumber}`,
                key: unitItem.basicInfo.unitId,
              })),
              (item) => parseInt(item.text, 10) || item.text
            )}
            onSelect={(item) => {
              const selectedUnit = units.find((unitItem) => unitItem.basicInfo.unitId === item.key);
              setUnit(selectedUnit);
            }}
            selected={unit.basicInfo.unitId}
          />
        </div>
      )}

      <div className="mt-lg mb-3xl">
        <AccountUnitDetailContainer accountUnitDetail={unit} />
      </div>
    </>
  );
}
