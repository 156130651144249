import { isEmpty } from 'es-toolkit/compat';
import { createSelector } from 'reselect';
import Home from '../../../models/home/Home';
import { HOME_REDUCER } from './reducer';

const _selectHomesReducer = (state) => state[HOME_REDUCER];
const _selectHomes = (state) => _selectHomesReducer(state).homes;
const _selectHomeById = (state, id) => (id ? _selectHomes(state)[id.toLowerCase()] : {});

export const selectHomeById = createSelector(_selectHomeById, (home) => (!isEmpty(home) ? new Home(home) : null));
